import { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Header from "layouts/pages/profile/components/Header";
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { AuthContext } from "context";
import useStore from "services/store";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import UploadIcon from "@mui/icons-material/Upload";
// Ajoutez ces imports en haut du fichier
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";


function Conventions() {

  const { selectedPromotion } = useStore((state) => ({
    selectedPromotion: state.selectedPromotion,
  }));

  const { userId } = useStore();
  const { userUid, userIdentite, userEtab, userRights, role } = useContext(AuthContext);
  console.log("démarche : ", userId);

  // État du formulaire et de la popup
  const initialFormState = {
    nom_etudiant: "",
    prenom_etudiant: "",
    entreprise: "",
    nom_directeur: "",
    nom_tuteur: "",
    personne_convention: "",
    adresse_entreprise: "",
    courriel: "",
    telephone: "",
    fax: "",
    date_debut: "",
    date_fin: "", 
    taches: ""
  };
  const [formData, setFormData] = useState(initialFormState);
  const [openConventionDialog, setOpenConventionDialog] = useState(false);

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleOpenConventionDialog = () => {
    setOpenConventionDialog(true);
  };

  const handleCloseConventionDialog = () => {
    setOpenConventionDialog(false);
  };

  const handleGeneratePDF = async () => {
    try {
      console.log("Données du formulaire :", formData);
      await crudsServiceFAPI.generateConventionPDF({
        ...formData,
        // Inclure l'identifiant de l'élève
        identifiant_eleve: userId,
      });
      // Vous pouvez rafraîchir la liste des conventions ici si nécessaire
    } catch (error) {
      console.error("Erreur lors de la génération de la convention :", error);
    }
  };

  // État pour stocker les conventions récupérées
  const [conventions, setConventions] = useState([]);

  // Fonction pour récupérer les conventions depuis le backend
  const fetchConventions = async () => {
    try {
      if(userId){
      const response = await crudsServiceFAPI.getConventionsByEleveId(userId);
      console.log("Conventions récupérées eleve :", response, userId);
      setConventions(response);
      }else {
        const response = await crudsServiceFAPI.getConventionsByPromotion(selectedPromotion.identifiant_promotion);
        console.log("Conventions récupérées promotion :", response, userId);
        setConventions(response);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des conventions :", error);
    }
  };

  useEffect(() => {
    fetchConventions();
  }, [userId]);

  const handleConsultConventionDocument = async (convention) => {
    try {
      const fileUrl = await crudsServiceFAPI.downloadConventions(convention.titre_document);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erreur lors de la consultation de la convention :", error);
    }
  };

  const handleConsultConventionDocumentSigneeEntreprise = async (convention) => {
    try {
      const fileUrl = await crudsServiceFAPI.downloadConventionsSigneeEntreprise(convention.titre_document_signe_entreprise);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erreur lors de la consultation de la convention :", error);
    }
  };

    // 2. Ajoutez ces filtres avant le return du composant :
    const generatedConventions = conventions
    
const signedConventions = conventions.filter(
  (c) => c.signee_entreprise === 1
);
const acceptedConventions = conventions.filter(
  (c) => c.status === 1
);
const refusedConventions = conventions.filter(
  (c) => c.status === 0 && c.signee_entreprise === 1
);

const handleUploadConventionDocument = async (convention) => {
  try {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "*/*";
    input.onchange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        // Appeler l'API pour uploader le fichier et mettre à jour la convention
        await crudsServiceFAPI.uploadConventionFile(convention.identifiant_convention, file);
        fetchConventions();
      }
    };
    input.click();
  } catch (error) {
    console.error("Erreur lors de l'upload de la convention :", error);
  }
};

const handleRefuseConvention = async (conventionId) => {
  try {
    await crudsServiceFAPI.refuseConvention(conventionId);
    fetchConventions(); // Rafraîchir la liste
  } catch (error) {
    console.error("Erreur lors du refus de la convention :", error);
  }
};

const [refuseDialogOpen, setRefuseDialogOpen] = useState(false);
const [currentConventionToRefuse, setCurrentConventionToRefuse] = useState(null);
const [refusalReason, setRefusalReason] = useState("");

// Fonction pour ouvrir la popup de refus avec la convention sélectionnée
const handleOpenRefuseDialog = (convention) => {
  setCurrentConventionToRefuse(convention);
  setRefuseDialogOpen(true);
};

// Fonction pour fermer la popup de refus et réinitialiser le motif
const handleCloseRefuseDialog = () => {
  setRefuseDialogOpen(false);
  setRefusalReason("");
  setCurrentConventionToRefuse(null);
};

// Fonction pour valider le refus en appelant l'API avec l'ID de la convention et le motif
const handleValidateRefusal = async () => {
  try {
    await crudsServiceFAPI.refuseConventionWithReason({
      convention_id: currentConventionToRefuse.identifiant_convention,
      motif_refus: refusalReason,
    });
    fetchConventions();
    handleCloseRefuseDialog();
  } catch (error) {
    console.error("Erreur lors du refus de la convention :", error);
  }
};

const handleValidateConvention = async (conventionId) => {
  try {
    await crudsServiceFAPI.validateConvention(
      conventionId,
      selectedPromotion.identifiant_promotion
    );
    fetchConventions(); // Rafraîchir la liste après validation
  } catch (error) {
    console.error("Erreur lors de la validation de la convention :", error);
  }
};

const [showPendingOnly, setShowPendingOnly] = useState(false);

const filteredConventions = showPendingOnly 
  ? generatedConventions.filter(c => c.status === null && c.titre_document_signe_entreprise) 
  : generatedConventions;

// Ajoutez ce useEffect dans votre composant (par exemple après le useEffect existant qui récupère les conventions)
useEffect(() => {
  const fetchPromotionUserInfo = async () => {
    try {
      const response = await crudsServiceFAPI.getPromotionUserInfo(userId);
      console.log("PromotionUserInfo:", response);
      setFormData((prev) => ({
        ...prev,
        nom_etudiant: response.nom_utilisateur,
        prenom_etudiant: response.prenom_utilisateur,
        date_debut: response.date_debut_stage ? new Date(response.date_debut_stage) : null,
        date_fin: response.date_fin_stage ? new Date(response.date_fin_stage) : null,
        // Ajoutez d'autres champs si nécessaire (ex: email_gestionnaire_stages)
      }));
    } catch (error) {
      console.error("Erreur lors de la récupération des infos promotion :", error);
    }
  };

  if (userId) {
    fetchPromotionUserInfo();
  }
}, [userId]);

const [promotionDates, setPromotionDates] = useState({
  date_debut_stage: null,
  date_fin_stage: null,
  email_gestionnaire_stages: ""
});
const [isEditingDates, setIsEditingDates] = useState(false);

useEffect(() => {
  const fetchPromotionDates = async () => {
    try {
      const response = await crudsServiceFAPI.getPromotionDates(selectedPromotion.identifiant_promotion);
      setPromotionDates(response);
    } catch (error) {
      console.error("Erreur lors de la récupération des dates de promotion :", error);
    }
  };
  if (selectedPromotion && selectedPromotion.identifiant_promotion) {
    fetchPromotionDates();
  }
}, [selectedPromotion]);

const handleSaveDates = async () => {
  try {
    await crudsServiceFAPI.updatePromotionDates(selectedPromotion.identifiant_promotion, promotionDates);
    setIsEditingDates(false);
  } catch (error) {
    console.error("Erreur lors de la mise à jour des dates :", error);
  }
};

const handleDeleteDates = async () => {
  try {
    await crudsServiceFAPI.deletePromotionDates(selectedPromotion.identifiant_promotion);
    setPromotionDates({ date_debut_stage: null, date_fin_stage: null, email_gestionnaire_stages: "" });
  } catch (error) {
    console.error("Erreur lors de la suppression des dates :", error);
  }
};

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header>
 
          <MDTypography variant="h4" mb={2}>
            Documents de stage
          </MDTypography>
          <MDBox mb={3}>

    <MDBox p={3}>
      <MDTypography variant="h5" mb={2}>
        Informations de stage de la promotion
      </MDTypography>
      {!isEditingDates ? (
  <>
    <MDTypography>
      Date de début :{" "}
      {promotionDates.date_debut_stage
        ? new Date(promotionDates.date_debut_stage).toLocaleDateString("fr-FR")
        : "N/A"}
    </MDTypography>
    <MDTypography>
      Date de fin :{" "}
      {promotionDates.date_fin_stage
        ? new Date(promotionDates.date_fin_stage).toLocaleDateString("fr-FR")
        : "N/A"}
    </MDTypography>
    <MDTypography>
      Email gestionnaire : {promotionDates.email_gestionnaire_stages || "N/A"}
    </MDTypography>
    {role !== "eleve" && (
      <>
        <MDButton onClick={() => setIsEditingDates(true)} color="info" variant="contained">
          Modifier
        </MDButton>
        <MDButton onClick={handleDeleteDates} color="error" variant="outlined">
          Supprimer
        </MDButton>
      </>
    )}
  </>
) : (
  <>
    <TextField
      label="Date de début"
      type="date"
      value={promotionDates.date_debut_stage ? promotionDates.date_debut_stage.split("T")[0] : ""}
      onChange={(e) =>
        setPromotionDates({ ...promotionDates, date_debut_stage: e.target.value })
      }
      InputLabelProps={{ shrink: true }}
    />
    <TextField
      label="Date de fin"
      type="date"
      value={promotionDates.date_fin_stage ? promotionDates.date_fin_stage.split("T")[0] : ""}
      onChange={(e) =>
        setPromotionDates({ ...promotionDates, date_fin_stage: e.target.value })
      }
      InputLabelProps={{ shrink: true }}
    />
    <TextField
      label="Email gestionnaire"
      value={promotionDates.email_gestionnaire_stages || ""}
      onChange={(e) =>
        setPromotionDates({ ...promotionDates, email_gestionnaire_stages: e.target.value })
      }
    />
    <MDButton onClick={handleSaveDates} color="success" variant="contained">
      Sauvegarder
    </MDButton>
    <MDButton onClick={() => setIsEditingDates(false)} color="secondary" variant="outlined">
      Annuler
    </MDButton>
  </>
)}

    </MDBox>


        </MDBox>
      </Header>

      {/* Bouton pour ouvrir la popup de génération de convention */}
      <MDBox mb={3}>
        <Card>
          <MDBox p={3}>
            <MDButton
              variant="contained"
              color="primary"
              onClick={handleOpenConventionDialog}
            >
              Générer une demande de convention
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>

      {/* Popup contenant le formulaire de génération de convention */}
      <Dialog
        open={openConventionDialog}
        onClose={handleCloseConventionDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Demande de convention</DialogTitle>
        <DialogContent>
  <MDBox component="form" mt={2}>
  <TextField
  label="Nom de l'étudiant"
  name="nom_etudiant"
  value={formData.nom_etudiant}
  onChange={handleFormChange}
  fullWidth
  margin="normal"
  disabled
/>

<TextField
  label="Prénom de l'étudiant"
  name="prenom_etudiant"
  value={formData.prenom_etudiant}
  onChange={handleFormChange}
  fullWidth
  margin="normal"
  disabled
/>
    <TextField
      label="Entreprise"
      name="entreprise"
      value={formData.entreprise}
      onChange={handleFormChange}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Nom du directeur"
      name="nom_directeur"
      value={formData.nom_directeur}
      onChange={handleFormChange}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Nom du tuteur"
      name="nom_tuteur"
      value={formData.nom_tuteur}
      onChange={handleFormChange}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Personne pour la convention"
      name="personne_convention"
      value={formData.personne_convention}
      onChange={handleFormChange}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Adresse de l'entreprise"
      name="adresse_entreprise"
      value={formData.adresse_entreprise}
      onChange={handleFormChange}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Courriel"
      name="courriel"
      value={formData.courriel}
      onChange={handleFormChange}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Téléphone"
      name="telephone"
      value={formData.telephone}
      onChange={handleFormChange}
      fullWidth
      margin="normal"
    />
    <TextField
      label="Fax"
      name="fax"
      value={formData.fax}
      onChange={handleFormChange}
      fullWidth
      margin="normal"
    />
    <TextField
    label="Tâches"
    name="taches"
    value={formData.taches}
    onChange={handleFormChange}
    fullWidth
    margin="normal"
    multiline
    rows={4}
  />
    

<LocalizationProvider dateAdapter={AdapterDateFns}>
  <DatePicker
    label="Date de début"
    value={formData.date_debut || null}
    onChange={(newValue) =>
      setFormData({ ...formData, date_debut: newValue })
    }
    disabled
    renderInput={(params) => (
      <TextField {...params} fullWidth margin="normal" disabled />
    )}
  />
  <DatePicker
    label="Date de fin"
    value={formData.date_fin || null}
    onChange={(newValue) =>
      setFormData({ ...formData, date_fin: newValue })
    }
    disabled
    renderInput={(params) => (
      <TextField {...params} fullWidth margin="normal" disabled />
    )}
  />
</LocalizationProvider>
  </MDBox>
</DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseConventionDialog} color="secondary">
            Annuler
          </MDButton>
          <MDButton
            variant="contained"
            color="primary"
            onClick={async () => {
              await handleGeneratePDF();
              // Réinitialiser les champs du formulaire
              setFormData(initialFormState);
              handleCloseConventionDialog();
              fetchConventions();
            }}
          >
            Générer PDF
          </MDButton>
        </DialogActions>
      </Dialog>

      {/* Tableau des conventions */}
      <MDBox mb={3}>
        <Card>
          <MDBox p={3}>
            <MDTypography variant="h4" fontWeight="medium" color="info">
             Conventions de stage
            </MDTypography>
            <MDBox mb={2}>
  <MDButton
    variant="outlined"
    color="info"
    onClick={() => setShowPendingOnly(!showPendingOnly)}
  >
    {showPendingOnly ? "Afficher toutes" : "Montrer en attente"}
  </MDButton>
</MDBox>
            <DataTable
  table={{
    columns: [
      { Header: "Identifiant élève", accessor: "identifiant_eleve" },
      { Header: "Nom entreprise", accessor: "nom_entreprise" },
      {
        Header: "Date",
        accessor: "date_convention",
        Cell: ({ value }) => {
          const date = value ? new Date(value) : null;
          return date ? date.toLocaleDateString("fr-FR") : "";
        },
      },
      {
        Header: "Demande de convention",
        accessor: "titre_document",
        Cell: ({ row }) => (
          <MDButton
            variant="text"
            color="primary"
            onClick={() => handleConsultConventionDocument(row.original)}
            sx={{ minWidth: 0 }}
          >
            <Icon>description</Icon>
          </MDButton>
        ),
      },
      {
        Header: "Demande de convention signée",
        accessor: "titre_document_signe_entreprise",
        Cell: ({ row }) => (
          row.original.titre_document_signe_entreprise
            ? (
              <MDButton
                variant="text"
                color="primary"
                onClick={() => handleConsultConventionDocumentSigneeEntreprise(row.original)}
                sx={{ minWidth: 0 }}
              >
                <Icon>description</Icon>
              </MDButton>
            )
            : (
              <MDButton
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {
                  const input = document.createElement("input");
                  input.type = "file";
                  input.accept = "*/*";
                  input.onchange = async (event) => {
                    const file = event.target.files[0];
                    if (file) {
                      try {
                        await crudsServiceFAPI.uploadConventionFile(row.original.identifiant_convention, file);
                        fetchConventions(); // Rafraîchir la liste
                      } catch (error) {
                        console.error("Erreur lors de l'upload de la convention signée :", error);
                      }
                    }
                  };
                  input.click();
                }}
                sx={{ minWidth: 0 }}
              >
                <UploadIcon fontSize="small" />
              </MDButton>
            )
        ),
      },
      {
        Header: "Convention",
        accessor: "titre_document_convention", // Utilisez le champ approprié
        Cell: ({ row }) => {
          return row.original.titre_document_convention ? (
            <MDButton
              variant="text"
              color="primary"
              onClick={() => handleConsultConventionDocument(row.original)}
              sx={{ minWidth: 0 }}
            >
              <Icon>description</Icon>
            </MDButton>
          ) : (
            <MDButton
              variant="text"
              color="warning"
              sx={{ minWidth: 0 }}
              disabled
            >
              <Icon>hourglass_empty</Icon>
            </MDButton>
          );
        }
      },
      
      
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row, value }) => {
          // Si le document signé n'est pas fourni, afficher "fournir le document signé"
          if (!row.original.titre_document_signe_entreprise) {
            return "fournir le document signé";
          }
          if (value === 0) return "refusée";
          else if (value === 1) return "acceptée";
          else return "en attente";
        },
      },
      { Header: "Motif si refus", accessor: "motif_refus" },
      
      {
        Header: "Actions",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
           <MDButton
  variant="outlined"
  color="error"
  size="small"
  onClick={() => handleOpenRefuseDialog(row.original)}
  sx={{ mr: 1 }}
  disabled={role === "eleve" || row.original.status !== null || !row.original.titre_document_signe_entreprise}
>
  <Icon>cancel</Icon>
</MDButton>
<MDButton
  variant="outlined"
  color="success"
  size="small"
  onClick={() => handleValidateConvention(row.original.identifiant_convention)}
  sx={{ mr: 1 }}
  disabled={role === "eleve" || row.original.status !== null || !row.original.titre_document_signe_entreprise}
>
  <Icon>check</Icon>
</MDButton>
          </>
        )
      }
      
      
    ],
    rows: filteredConventions,
  }}
/>
          </MDBox>
        </Card>
      </MDBox>

    
      <Dialog
  open={refuseDialogOpen}
  onClose={handleCloseRefuseDialog}
  fullWidth
  maxWidth="sm"
>
  <DialogTitle>Motif du refus</DialogTitle>
  <DialogContent>
    <TextField
      label="Motif du refus"
      name="refusalReason"
      value={refusalReason}
      onChange={(e) => setRefusalReason(e.target.value)}
      fullWidth
      margin="normal"
    />
  </DialogContent>
  <DialogActions>
    <MDButton onClick={handleCloseRefuseDialog} color="secondary">
      Annuler
    </MDButton>
    <MDButton variant="contained" color="primary" onClick={handleValidateRefusal}>
      Valider
    </MDButton>
  </DialogActions>
</Dialog>

{/* Nouvelle section pour Certificat et Attestation de stage */}
<MDBox display="flex" gap={2} mb={3}>
  <Card sx={{ flex: 1 }}>
    <MDBox p={3}>
      <MDTypography variant="h4" fontWeight="medium" color="info">
        Certificat de stage
      </MDTypography>
      <DataTable
        table={{
          columns: [
            { Header: "Identifiant élève", accessor: "identifiant_eleve" },
            { Header: "Nom entreprise", accessor: "nom_entreprise" },
            
            {
              Header: "Certificat de stage",
              accessor: "titre_document_certificat",
              Cell: ({ row }) =>
                row.original.titre_document_certificat ? (
                  <MDButton
                    variant="text"
                    color="primary"
                    onClick={() => handleConsultConventionDocument(row.original)}
                    sx={{ minWidth: 0 }}
                  >
                    <Icon>description</Icon>
                  </MDButton>
                ) : (
                  <MDButton variant="text" color="warning" sx={{ minWidth: 0 }} disabled>
                    <Icon>hourglass_empty</Icon>
                  </MDButton>
                ),
            },
            {
              Header: "Actions",
              accessor: "actions",
              Cell: ({ row }) => (
                <MDButton
  variant="outlined"
  color="primary"
  size="small"
  onClick={() =>
    crudsServiceFAPI.generateCertificate(row.original.identifiant_convention)
  }
  disabled={role === "eleve"}
>
  Générer certificat
</MDButton>
              ),
            },
          ],
          rows: conventions.filter(c => c.status === 1),
        }}
      />
    </MDBox>
  </Card>
  <Card sx={{ flex: 1 }}>
    <MDBox p={3}>
      <MDTypography variant="h4" fontWeight="medium" color="info">
        Attestation de stage
      </MDTypography>
      <DataTable
        table={{
          columns: [
            { Header: "Identifiant élève", accessor: "identifiant_eleve" },
            { Header: "Nom entreprise", accessor: "nom_entreprise" },
            
            {
              Header: "Attestation de stage",
              accessor: "titre_document_attestation",
              Cell: ({ row }) =>
                row.original.titre_document_attestation ? (
                  <MDButton
                    variant="text"
                    color="primary"
                    onClick={() => handleConsultConventionDocument(row.original)}
                    sx={{ minWidth: 0 }}
                  >
                    <Icon>description</Icon>
                  </MDButton>
                ) : (
                  <MDButton variant="text" color="warning" sx={{ minWidth: 0 }} disabled>
                    <Icon>hourglass_empty</Icon>
                  </MDButton>
                ),
            },
            {
              Header: "Actions",
              accessor: "actions",
              Cell: ({ row }) => (
                <MDButton
                variant="outlined"
                color="primary"
                size="small"
                onClick={() =>
                  crudsServiceFAPI.generateAttestation(row.original.identifiant_convention)
                }
                disabled={role === "eleve"}
              >
                Générer attestation
              </MDButton>
              
              ),
            },
          ],
          rows: conventions.filter(c => c.status === 1),
        }}
      />
    </MDBox>
  </Card>
</MDBox>



      <Footer />
    </DashboardLayout>
  );
}

export default Conventions;
