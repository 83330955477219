import { useContext, useState, useEffect } from "react";
import { AuthContext } from "context";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import { useAbility } from "@casl/react";
import { AbilityContext } from "Can";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add"; // Import de l'icône Add pour le bouton ajouter des prérequis
import Checkbox from "@mui/material/Checkbox"; // Import de Checkbox
import useStore from "services/store";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';

function ActiviteCard({
  image,
  title,
  description,
  price,
  identifiant_activite,
  onExpand,
  expanded,
  details,
  evaluation,
  livrables,
  todo,
  identifiant_parcours,
  idMatiere,
  padlet_url, 
  faq
}) {
  const { userIdentite, userEtab, userRights, role } = useContext(AuthContext);
  const { userId } = useStore((state) => state);
  const selectedMatiere = useStore((state) => state.selectedMatiere);
  const [userUid] = useState(userId);
  const ability = useAbility(AbilityContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [resourceList, setResourceList] = useState([]);
  const [PrerequisList, setPrerequisList] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [validationLevels, setValidationLevels] = useState([]);
  const [competences, setCompetences] = useState([]);
  const [validations, setValidations] = useState([]);

  const canEditActivity = ability.can("edit", "activite", idMatiere);
  
  // États pour la gestion de l'image
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  const [githubLink, setGithubLink] = useState("");

  // États pour le dialogue d'ajout des prérequis
  const [openPrerequisDialog, setOpenPrerequisDialog] = useState(false);
  const [activitesParcours, setActivitesParcours] = useState([]);
  const [selectedPrerequis, setSelectedPrerequis] = useState([]);

  useEffect(() => {
    const fetchCompetencesAndValidationLevels = async () => {
      if (identifiant_activite) {
        try {
          const competencesResponse = await crudsServiceFAPI.getCompetencesByActivite(identifiant_activite);
          const competencesData = await Promise.all(
            competencesResponse.map(async (comp) => {
              const competenceDetails = await crudsServiceFAPI.getCompetenceById(comp.identifiant_competence);
              return {
                numero_competence: competenceDetails.numero_competence,
                identifiant_competence: competenceDetails.identifiant_competence,
                denomination_competence: competenceDetails.denomination_competence,
                niveau_competence_visee_referentiel: competenceDetails.niveau_competence_visee_referentiel,
                niveau_vise_referentiel: competenceDetails.niveau_competence_visee_referentiel,
                niveau_vise_activite: comp.niveau_vise_activite,
                points_competence_vises: comp.points_competence_vises,
                numero_bloc: competenceDetails.numero_bloc,
                denomination_bloc: competenceDetails.denomination_bloc,
                denomination_unite: competenceDetails.denomination_unite
              };
            })
          );
          setCompetences(competencesData);
        } catch (error) {
          console.error("Erreur lors de la récupération des données :", error);
        }
      }
    };

    fetchCompetencesAndValidationLevels();
  }, [identifiant_activite]);

  useEffect(() => {
    const fetchLatestValidationForActivity = async () => {
      if (identifiant_activite) {
        try {
          const validationPayload = {
            identifiant_eleve: userUid,
            identifiant_activite: identifiant_activite,
          };
          const latestValidation = await crudsServiceFAPI.getLatestValidationForActivity(validationPayload);
          if (latestValidation && latestValidation.length > 0) {
            setValidations(latestValidation);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération de la dernière validation :", error);
        }
      }
    };
    fetchLatestValidationForActivity();
  }, [identifiant_activite, userUid]);

  const [discordProf, setDiscordProf] = useState([]);

  useEffect(() => {
    const fetchDiscordProf = async () => {
      try {
        const discordIds = await crudsServiceFAPI.getDiscordIdsOfProfessors(selectedMatiere.identifiant_matiere);
        setDiscordProf(discordIds);
      } catch (error) {
        console.error("Erreur lors de la récupération des Discord IDs :", error);
      }
    };
    fetchDiscordProf();
  }, []);

  const handleValidateGithubLink = async () => {
    if (githubLink) {
      try {
        const validationPayload = {
          identifiant_eleve: userUid,
          identifiant_activite: identifiant_activite,
          lien_validation: githubLink,
          competences: competences.map((comp) => ({
            identifiant_competence: comp.identifiant_competence,
          })),
        };
        await crudsServiceFAPI.createValidation(validationPayload);
        alert("Validation créée avec succès !");
      } catch (error) {
        console.error("Erreur lors de la création de la validation :", error);
        alert("Erreur lors de la soumission de la validation.");
      }
      try {
        await crudsServiceFAPI.sendValidationDM(discordProf, userUid, title, "parcours ...");
      } catch (error) {
        console.error("Erreur lors de l'envoi du DM :", error);
      } finally {
        window.location.reload();
      }
    } else {
      alert("Veuillez entrer un lien GitHub.");
    }
  };

  useEffect(() => {
    if (identifiant_activite) {
      const timestamp = new Date().getTime();
      const imagePath = `${process.env.REACT_APP_FAPI_URL}/files/activites/${identifiant_activite}.jpg?t=${timestamp}`;
      fetch(imagePath)
        .then((response) => {
          if (response.ok) {
            setImageUrl(imagePath);
          } else {
            setImageUrl(`${process.env.REACT_APP_FAPI_URL}/files/activites/defaut.jpg?t=${timestamp}`);
          }
          setIsReady(true);
        })
        .catch(() => {
          setImageUrl(`${process.env.REACT_APP_FAPI_URL}/files/activites/defaut.jpg?t=${timestamp}`);
          setIsReady(true);
        });
    }
  }, [identifiant_activite]);

  useEffect(() => {
    const fetchResources = async () => {
      if (expanded && isReady) {
        try {
          const response = await crudsServiceFAPI.getResourcesByActivite(identifiant_activite);
          setResourceList(response);
        } catch (error) {
          console.error("Erreur lors de la récupération des ressources :", error);
        }
      }
    };
    fetchResources();
  }, [expanded, isReady, identifiant_activite]);

  useEffect(() => {
    const fetchPrerequis = async () => {
      try {
        const response = await crudsServiceFAPI.getPrerequisValidation(identifiant_activite, userUid);
        setPrerequisList(response);
      } catch (error) {
        console.error("Erreur lors de la récupération des prerequis :", error);
      }
    };
    fetchPrerequis();
  }, [identifiant_activite, userUid]);

  // Détermine si tous les prérequis sont validés
  const prerequisitesValidated = PrerequisList?.prerequis?.every(pr => pr.validation_status === 1);

  // Gestion de la navigation
  const handleEdit = () => {
    navigate(
      `/parcours/parcours-gestion/activite-nouveau?identifiant_activite=${identifiant_activite}&identifiant_parcours=${identifiant_parcours}`
    );
  };

  const handleDelete = async () => {
    try {
      await crudsServiceFAPI.deleteActivite(identifiant_activite);
      setOpen(false);
      window.location.reload();
    } catch (error) {
      console.error("Erreur lors de la suppression de l'activité :", error);
      alert("Erreur lors de la suppression de l'activité");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Gestion de l'upload d'image
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setImageUrl(URL.createObjectURL(e.target.files[0]));
  };

  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      const newFileName = `${identifiant_activite}.jpg`;
      formData.append("file", new File([selectedFile], newFileName));
      try {
        const path = `activites`;
        await crudsServiceFAPI.uploadFile(`${path}/`, formData);
        const timestamp = new Date().getTime();
        setImageUrl(`${process.env.REACT_APP_FAPI_URL}/files/activites/${identifiant_activite}.jpg?t=${timestamp}`);
        window.location.reload();
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  // Fonctions pour la modification/suppression des compétences (inchangées)
  const handleDeleteCompetence = async (denomination_competence, identifiant_competence, identifiant_activite, niveau_vise_activite, competences, setCompetences) => {
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer la compétence ${denomination_competence} de l'activité ${identifiant_activite} ?`)) {
      try {
        await crudsServiceFAPI.deleteActiviteCompetence(identifiant_activite, identifiant_competence, niveau_vise_activite);
        alert('Compétence supprimée avec succès');
        setCompetences(competences.filter(c => !(c.identifiant_competence === identifiant_competence && c.niveau_vise_activite === niveau_vise_activite)));
      } catch (error) {
        console.error("Erreur lors de la suppression de la compétence :", error);
        alert("Erreur lors de la suppression de la compétence.");
      }
    }
  };

  const handleEditCompetence = async (competenceId, competences, setCompetences, identifiant_activite) => {
    const updatedPoints = prompt("Entrez le nouveau nombre de points pour la compétence :");
    const updatedLevel = prompt("Entrez le nouveau niveau visé pour la compétence :");
    if (!isNaN(updatedPoints) && !isNaN(updatedLevel) && updatedPoints !== '' && updatedLevel !== '') {
      try {
        const payload = {
          points_competence_vises: parseInt(updatedPoints, 10),
          niveau_vise_activite: parseInt(updatedLevel, 10)
        };
        await crudsServiceFAPI.updateActiviteCompetence(identifiant_activite, competenceId, payload);
        alert('Compétence modifiée avec succès');
        setCompetences(competences.map(c => 
          c.numero_competence === competenceId 
            ? { ...c, points_competence_vises: updatedPoints, niveau_vise_activite: updatedLevel } 
            : c
        ));
      } catch (error) {
        console.error("Erreur lors de la modification de la compétence :", error);
        alert("Erreur lors de la modification de la compétence.");
      }
    } else {
      alert("Les valeurs doivent être des nombres valides.");
    }
  };

  // --- Nouvelles fonctions pour gérer l'ajout des prérequis ---
  const handleOpenPrerequisDialog = async () => {
    // Si des prérequis existent déjà, on extrait leurs identifiants pour pré-sélection
    if (PrerequisList && PrerequisList.prerequis) {
      const alreadySelected = PrerequisList.prerequis.map(pr => pr.activite_prerequis);
      setSelectedPrerequis(alreadySelected);
    } else {
      setSelectedPrerequis([]);
    }
    setOpenPrerequisDialog(true);
    try {
      const response = await crudsServiceFAPI.getActivitesByParcoursId(identifiant_parcours);
      setActivitesParcours(response);
    } catch (error) {
      console.error("Erreur lors de la récupération des activités du parcours :", error);
    }
  };

  const handleClosePrerequisDialog = () => {
    setOpenPrerequisDialog(false);
  };

  const handleTogglePrerequis = (activityId) => {
    if (selectedPrerequis.includes(activityId)) {
      setSelectedPrerequis(selectedPrerequis.filter(id => id !== activityId));
    } else {
      setSelectedPrerequis([...selectedPrerequis, activityId]);
    }
  };

  const handleSubmitPrerequis = async () => {
    try {
      // Préparez le payload en créant un tableau d'objets
      // Chaque objet contient l'identifiant de l'activité prérequis et éventuellement un taux_minimum
      const payload = {
        prerequis: selectedPrerequis.map(prerequisId => ({
          activite_prerequis: prerequisId,
          taux_minimum: 80.00  // Vous pouvez ajuster cette valeur ou la rendre dynamique
        }))
      };
      await crudsServiceFAPI.setPrerequisActivites(identifiant_activite, payload);
      alert("Prérequis mis à jour avec succès");
      setOpenPrerequisDialog(false);
      window.location.reload(); // Recharge la page après la mise à jour
      // Vous pouvez également rafraîchir la liste des prérequis si nécessaire
    } catch (error) {
      console.error("Erreur lors de la mise à jour des prérequis :", error);
      alert("Erreur lors de la mise à jour des prérequis");
    }
  };

  
  // --- Fin des fonctions prérequis ---

  if (!isReady) {
    return null;
  }

  return (
    <>
      <Card
        sx={{
          transition: "all 0.3s ease",
          maxWidth: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          boxShadow: expanded ? "0px 4px 20px rgba(0, 0, 0, 0.12)" : "0px 2px 10px rgba(0, 0, 0, 0.1)",
          marginBottom: expanded ? "20px" : "10px",
          "&:hover .card-header": {
            transform: expanded ? "none" : "translate3d(0, -10px, 0)",
          },
          "&:hover .blur-background": {
            opacity: expanded ? 1 : 0,
          },
        }}
      >
        <MDBox
          position="relative"
          borderRadius="lg"
          mt={2}
          mx={2}
          className="card-header"
          sx={{
            transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <MDBox
            component="img"
            src={imageUrl}
            alt={title}
            borderRadius="lg"
            shadow="md"
            width={expanded ? "150px" : "100%"}
            height="auto"
            position="relative"
            zIndex={1}
          />
          <MDBox
            className="blur-background"
            borderRadius="lg"
            shadow="md"
            width={expanded ? "150px" : "100%"}
            height="100%"
            position="absolute"
            left={0}
            top="0"
            sx={{
              backgroundImage: `url(${imageUrl})`,
              transform: "scale(0.94)",
              filter: "blur(12px)",
              backgroundSize: "cover",
              transition: "opacity 300ms ease-in-out",
              display: expanded ? "none" : "block",
            }}
          />
          {ability.can("edit", "activite", idMatiere) && (
            <>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                component="label"
                htmlFor={`edit-activite-image-${identifiant_activite}`}
                sx={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: 2,
                }}
              >
                <Icon>edit</Icon>
              </MDButton>
              <input
                type="file"
                id={`edit-activite-image-${identifiant_activite}`}
                name="attachment"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={handleUpload}
                sx={{
                  position: "absolute",
                  top: "50px",
                  right: "10px",
                  zIndex: 2,
                }}
              >
                Save
              </MDButton>
            </>
          )}
          {/* Bouton pour ajouter des prérequis (visible si l'utilisateur peut éditer) */}
         
        </MDBox>
        <MDBox textAlign="center" pt={3} px={3} flexGrow={1}>
          <MDTypography variant="h5" fontWeight="regular" sx={{ mt: 0.5 }}>
            {title}
          </MDTypography>
          <MDTypography
            variant="body2"
            color="text"
            sx={{ mt: 1, mb: 1 }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {PrerequisList && PrerequisList.prerequis && PrerequisList.prerequis.length > 0 && (
            <MDBox mt={2}>
             
              {PrerequisList.prerequis.map((pr, index) => {
                const ratio =
                  pr.total_points_vises > 0
                    ? (pr.total_points_remportes / pr.total_points_vises) * 100
                    : 0;
                let couleur = "red";
                if (ratio >= 80) {
                  couleur = "green";
                } else if (ratio >= 50 && ratio < 80) {
                  couleur = "orange";
                }
                return (
                  <MDTypography
                    key={index}
                    variant="body2"
                    sx={{ color: couleur, ml: 2, mt: 1 }}
                  >
                    {pr.titre_prerequis} : {ratio.toFixed(0)}%
                  </MDTypography>
                );
              })}
            </MDBox>
          )}
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt={1}
          pb={3}
          px={3}
          lineHeight={1}
        >
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pt={1}
            pb={3}
            px={3}
            lineHeight={1}
            sx={{
              position: "relative",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <MDBox color="text" display="flex" alignItems="center" flexWrap="wrap">
              <MDButton variant="text" color="info" onClick={onExpand}>
                <VisibilityIcon />
                {expanded ? "Réduire" : ""}
              </MDButton>
              {ability.can("edit", "activite", idMatiere) && (
                <MDButton
                  variant="text"
                  color="warning"
                  onClick={handleEdit}
                  sx={{ marginRight: "8px" }}
                >
                  <EditIcon />
                </MDButton>
              )}
              {ability.can("delete", "activite", idMatiere) && (
                <>
                  <MDButton variant="text" color="error" onClick={handleClickOpen}>
                    <DeleteIcon />
                  </MDButton>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Confirmer la suppression"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Êtes-vous sûr de vouloir supprimer cette activité ? Cette action est irréversible.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <MDButton onClick={handleClose} color="info">
                        Annuler
                      </MDButton>
                      <MDButton onClick={handleDelete} color="error" autoFocus>
                        Supprimer
                      </MDButton>
                    </DialogActions>
                  </Dialog>
                </>
              )}
              {validations[0]?.status_validation === "Vérifiée" ? (
                <CheckCircleIcon />
              ) : validations[0]?.status_validation === "En cours de vérification" ? (
                <WorkHistoryIcon />
              ) : (
                <DangerousIcon />
              )}
               {ability.can("edit", "activite", idMatiere) && (
            <MDButton
              variant="text"
              color="info"
              onClick={handleOpenPrerequisDialog}
              sx={{ position: "absolute", top: "90px", right: "10px", zIndex: 2 }}
            >
              <AddIcon />
            </MDButton>
          )}
            </MDBox>
          </MDBox>
        </MDBox>
        {expanded && (
          <MDBox p={3}>
            <Grid container spacing={2}>
              {/* Bloc des Compétences */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Compétences Associées
                  </MDTypography>
                  {competences.length > 0 ? (
                    <MDBox>
                      {competences.map((competence, index) => (
                        <Card key={index} sx={{ p: 2, mb: 2, boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}>
                          <MDBox
                            sx={{ marginBottom: "8px", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                          >
                            <MDTypography variant="body2" sx={{ fontSize: "0.875rem" }}>
                              La compétence <span style={{ fontWeight: "bold", color: "blue" }}>{competence.numero_competence}</span> - 
                              <span style={{ fontWeight: "bold", color: "blue" }}> {competence.denomination_competence} </span>  
                              du bloc <span style={{ fontWeight: "bold", color: "blue" }}>{competence.numero_bloc}</span> -
                              <span style={{ fontWeight: "bold", color: "blue" }}> {competence.denomination_bloc}</span> est attendue dans le référentiel au niveau 
                              <span style={{ fontWeight: "bold", color: "blue" }}> {competence.niveau_vise_referentiel}</span>. 
                              Dans cette activité, vous la travaillerez au niveau <span style={{ fontWeight: "bold", color: "blue" }}>{competence.niveau_vise_activite} </span> 
                              qui vous permettra de remporter <span style={{ fontWeight: "bold", color: "blue" }}>{competence.points_competence_vises}</span> points dans ce niveau.
                            </MDTypography>
                            {canEditActivity && (
                              <MDBox>
                                <IconButton
                                  onClick={() => handleEditCompetence(competence.numero_competence, competences, setCompetences, identifiant_activite)}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleDeleteCompetence(competence.denomination_competence, competence.identifiant_competence, identifiant_activite, competence.niveau_vise_activite, competences, setCompetences)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </MDBox>
                            )}
                          </MDBox>
                        </Card>
                      ))}
                    </MDBox>
                  ) : (
                    <MDTypography variant="body2" color="text">
                      Aucune compétence associée trouvée pour cette activité.
                    </MDTypography>
                  )}
                </Card>
              </Grid>
              {/* Bloc 6 - Niveaux de validation */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Niveaux de Validation des Compétences
                  </MDTypography>
                  {validations.length > 0 ? (
                    <MDBox>
                      {validations.map((validation, index) => (
                        <MDBox key={index} sx={{ marginBottom: "16px" }}>
                          <MDTypography variant="body2" sx={{ fontSize: "0.875rem" }}>
                            <strong>Date de validation :</strong> {validation.date_validation} <br />
                            <strong>Lien de validation :</strong>{" "}
                            <a
                              href={validation.lien_validation}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {validation.lien_validation}
                            </a>{" "}
                            <br />
                            <strong>Status :</strong> {validation.status_validation} <br />
                            <strong>Message :</strong>{" "}
                            <span dangerouslySetInnerHTML={{ __html: validation.message_validation }} /> <br />
                            <strong>Compétences :</strong>
                          </MDTypography>
                          {(validation?.competences?.length || 0) > 0 ? (
                            <MDBox>
                              {validation.competences.map((competence, compIndex) => (
                                <MDBox key={compIndex} sx={{ marginLeft: "16px", marginBottom: "8px" }}>
                                  <MDTypography variant="body2" sx={{ fontSize: "0.875rem" }}>
                                    <strong>Numéro de compétence :</strong> {competence.numero_competence} <br />
                                    <strong>Dénomination :</strong> {competence.denomination_competence} <br />
                                    <strong>Niveau de validation :</strong> {competence.niveau_validation_competence} <br />
                                    <strong>Points remportés :</strong> {competence.points_remportes} <br />
                                  </MDTypography>
                                </MDBox>
                              ))}
                            </MDBox>
                          ) : (
                            <MDTypography variant="body2" color="text">
                              Aucune compétence trouvée pour cette validation.
                            </MDTypography>
                          )}
                          {index < validations.length - 1 && <Divider sx={{ mt: 2, mb: 2 }} />}
                        </MDBox>
                      ))}
                    </MDBox>
                  ) : (
                    <MDTypography variant="body2" color="text">
                      Aucun niveau de validation trouvé pour cette activité.
                    </MDTypography>
                  )}
                </Card>
              </Grid>
              {/* Bloc 1 - Détails */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Détails de l'activité
                  </MDTypography>
                  <MDTypography
                    variant="body2"
                    color="text"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: details }}
                  />
                </Card>
              </Grid>
              {/* Bloc 2 - Évaluation */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Évaluation
                  </MDTypography>
                  <MDTypography
                    variant="body2"
                    color="text"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: evaluation }}
                  />
                </Card>
              </Grid>
              {/* Bloc 3 - Livrables */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Livrables
                  </MDTypography>
                  <MDTypography
                    variant="body2"
                    color="text"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: livrables }}
                  />
                </Card>
              </Grid>
              {/* Bloc 4 - To-do */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                  <MDTypography variant="h6" fontWeight="bold">
                    À faire (To-do)
                  </MDTypography>
                  <MDTypography
                    variant="body2"
                    color="text"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: todo }}
                    sx={{ ml: 2 }}
                  />
                </Card>
              </Grid>
              {/* Bloc 5 - Ressources */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Ressources
                  </MDTypography>
                  {resourceList.length > 0 ? (
                    <MDBox>
                      {resourceList.map((resource, index) => (
                        <MDBox key={index} sx={{ marginBottom: "8px" }}>
                          <MDTypography variant="body2" sx={{ fontSize: "0.875rem" }}>
                            <a
                              href={`${process.env.REACT_APP_FAPI_URL}/files/ressources/${resource.nom_ressource}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {resource.nom_ressource}
                            </a>
                          </MDTypography>
                        </MDBox>
                      ))}
                    </MDBox>
                  ) : (
                    <MDTypography variant="body2" color="text">
                      Aucune ressource associée.
                    </MDTypography>
                  )}
                </Card>
              </Grid>
              {/* Bloc FAQ */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                  <MDTypography variant="h6" fontWeight="bold">
                    FAQ
                  </MDTypography>
                  <MDTypography
                    variant="body2"
                    color="text"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: faq }}
                    sx={{ ml: 2 }}
                  />
                </Card>
              </Grid>
              {/* Bloc Répertoire GitHub */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Répertoire GitHub
                  </MDTypography>
                  {!prerequisitesValidated && (
                    <MDTypography variant="body2" color="text">
                      Vous devez avoir validé tous les prérequis pour soumettre votre lien GitHub.
                    </MDTypography>
                  )}
                  <MDBox display="flex" alignItems="center" mt={2}>
                    <MDBox flexGrow={1} mr={2}>
                      <input
                        type="text"
                        value={githubLink}
                        onChange={(e) => setGithubLink(e.target.value)}
                        placeholder={
                          prerequisitesValidated
                            ? "Entrez votre lien GitHub ici"
                            : "Les prérequis ne sont pas satisfaits pour cette activité"
                        }
                        style={{
                          width: "100%",
                          padding: "8px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                          backgroundColor: prerequisitesValidated ? "white" : "#f0f0f0",
                          color: prerequisitesValidated ? "inherit" : "grey",
                        }}
                        disabled={!prerequisitesValidated}
                      />
                    </MDBox>
                    <MDButton
                      variant="gradient"
                      color="success"
                      onClick={handleValidateGithubLink}
                      disabled={!prerequisitesValidated}
                    >
                      Valider
                    </MDButton>
                  </MDBox>
                </Card>
              </Grid>
              {/* Bloc Iframe Padlet */}
              {padlet_url && (
                <Grid item xs={12}>
                  <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                    <MDTypography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
                      Padlet
                    </MDTypography>
                    <MDBox
                      sx={{
                        position: "relative",
                        paddingBottom: "56.25%",
                        paddingTop: "25px",
                        height: 0,
                      }}
                    >
                      <iframe
                        src={padlet_url}
                        title="Padlet Embedded"
                        frameBorder="0"
                        allow="camera;microphone;geolocation"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          border: "none",
                        }}
                      ></iframe>
                    </MDBox>
                  </Card>
                </Grid>
              )}
            </Grid>
          </MDBox>
        )}
      </Card>

      {/* Dialog pour ajouter des prérequis */}
      <Dialog
  open={openPrerequisDialog}
  onClose={handleClosePrerequisDialog}
  fullWidth
  maxWidth="sm"
>
  <DialogTitle>Ajouter des prérequis</DialogTitle>
  <DialogContent>
    <MDTypography variant="body2">
      Sélectionnez une ou plusieurs activités à ajouter comme prérequis :
    </MDTypography>
    {activitesParcours.length > 0 ? (
      activitesParcours.map((activity) => (
        <MDBox key={activity.identifiant_activite} display="flex" alignItems="center">
          <Checkbox
            checked={selectedPrerequis.includes(activity.identifiant_activite)}
            onChange={() => handleTogglePrerequis(activity.identifiant_activite)}
          />
          <MDTypography variant="body2">
            {activity.titre_activite}
          </MDTypography>
        </MDBox>
      ))
    ) : (
      <MDTypography variant="body2">
        Aucune activité trouvée pour ce parcours.
      </MDTypography>
    )}
  </DialogContent>
  <DialogActions>
    <MDButton onClick={handleClosePrerequisDialog} color="info">
      Annuler
    </MDButton>
    <MDButton onClick={handleSubmitPrerequis} color="success">
      Valider
    </MDButton>
  </DialogActions>
</Dialog>

    </>
  );
}

ActiviteCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  identifiant_activite: PropTypes.number,
  onExpand: PropTypes.func,
  expanded: PropTypes.bool,
  details: PropTypes.string,
  evaluation: PropTypes.string,
  livrables: PropTypes.string,
  todo: PropTypes.string,
  identifiant_parcours: PropTypes.string,
  is_checked: PropTypes.string,
  points_remportes: PropTypes.number,
  niveau_competence: PropTypes.number,
  date_validation: PropTypes.string,
  idMatiere: PropTypes.string,
  padlet_url: PropTypes.string, 
  faq: PropTypes.string
};

export default ActiviteCard;
