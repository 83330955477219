import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import useStore from "services/store";
import { MenuItem, FormControl, Select, Typography, Box, CircularProgress } from "@mui/material";
import { AuthContext } from "context";

const ApexChartCrux = () => {
  const { role } = useContext(AuthContext);
  const { userId, selectedPromotion } = useStore((state) => ({
    userId: state.userId,
    selectedPromotion: state.selectedPromotion,
  }));

  console.log("Role de l'utilisateur : ", role);
  console.log("userId : ", userId);
  console.log("selectedPromotion : ", selectedPromotion);

  const [selectedOption, setSelectedOption] = useState("competences");
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const capitalizeFirstLetter = (string) => string ? string.charAt(0).toUpperCase() + string.slice(1) : "";

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "heatmap",
      toolbar: { show: true },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.3,
        radius: 10,
        colorScale: {
          ranges: [
            { from: 750, to: 2000, name: "Excellence", color: "#128FD9" },
            { from: 500, to: 750, name: "Bonne maîtrise", color: "#00A100" },
            { from: 150, to: 500, name: "Maîtrise partielle", color: "#FFB200" },
            { from: 0, to: 150, name: "Non maîtrisé", color: "#FF0000" },
          ],
        },
      },
    },
    dataLabels: { enabled: true, style: { colors: ["#fff"], fontSize: "12px", fontWeight: "bold" } },
    stroke: { width: 3, colors: ["#ffffff"] },
    xaxis: { type: "category", categories: categories },
    yaxis: { title: { text: "Niveaux" }, labels: { style: { fontSize: "12px" } } },
    tooltip: { y: { formatter: (val) => val ? val.toString() : "" } },
  });

  // Fonction pour mapper la réponse API aux séries et catégories d'ApexCharts
  const mapSeries = (response) => {
    if (response.series && Array.isArray(response.series)) {
      const isIndividual = response.series.length > 0 && response.series[0].label;

      const newCategories = isIndividual
        ? response.series.flatMap((item) => item.data.map((point) => point.x)).filter((v, i, a) => a.indexOf(v) === i)
        : response.series.map((item) => item.x);
      
      const newSeries = isIndividual
        ? response.series.map((item) => ({ name: item.label, data: item.data }))
        : [{ name: "Total", data: response.series.map((item) => ({ x: item.x, y: item.y })) }];

      return { newSeries, newCategories };
    } else {
      console.error("Structure de réponse incorrecte");
      return { newSeries: [], newCategories: [] };
    }
  };

  // Définir les fonctions de récupération des données avec useMemo
  const fetchDataFunctions = useMemo(() => ({
    competences: crudsServiceFAPI.getPromotionPointsCompetences, // Toujours utiliser getPromotionPointsCompetences
    parcours: userId ? crudsServiceFAPI.getElevePointsParcours : crudsServiceFAPI.getPromotionPointsParcours,
    // activites: userId ? crudsServiceFAPI.getElevePointsActivites : crudsServiceFAPI.getPromotionPointsActivites,
    blocs: userId ? crudsServiceFAPI.getElevePointsBlocs : crudsServiceFAPI.getPromotionPointsBlocs,
  }), [userId]);

  // Utiliser useCallback pour mémoriser fetchPoints et éviter des re-créations inutiles
  const fetchPoints = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      // Sélectionner la fonction de récupération basée sur l'option sélectionnée
      const fetchFunction = fetchDataFunctions[selectedOption];
      
      // Déterminer le paramètre à passer
      const isCompetences = selectedOption === "competences";
      const isAdminRole = ["superadministrateur", "administrateur", "gestionnaire", "professeur"].includes(role);
      
      // Pour "competences", toujours utiliser selectedPromotion.identifiant_promotion
      // Pour les autres options, utiliser userId si présent, sinon selectedPromotion.identifiant_promotion
      const param = isCompetences 
        ? selectedPromotion?.identifiant_promotion 
        : (userId || selectedPromotion?.identifiant_promotion);

      console.log("Fetching points for:", selectedOption, param);
      
      if (isCompetences && !selectedPromotion?.identifiant_promotion) {
        throw new Error("selectedPromotion.identifiant_promotion est null ou undefined.");
      }
      
      const response = await fetchFunction(param);
      console.log("Fetched points:", response);

      let processedResponse = response;

      // Si l'option est "compétences" et que l'utilisateur est un élève, filtrer les données
      if (isCompetences && role === "eleve") {
        // Supposons que userId correspond à un label dans la série
        processedResponse = {
          ...response,
          series: response.series.filter((item) => item.label === userId),
        };
        console.log("Processed response for 'eleve':", processedResponse);
      }

      const { newSeries, newCategories } = mapSeries(processedResponse);
      
      // Vérifier si les nouvelles séries sont différentes des anciennes pour éviter des re-rendus inutiles
      setSeries((prevSeries) => {
        if (JSON.stringify(prevSeries) !== JSON.stringify(newSeries)) {
          return newSeries;
        }
        return prevSeries;
      });

      setCategories((prevCategories) => {
        if (JSON.stringify(prevCategories) !== JSON.stringify(newCategories)) {
          return newCategories;
        }
        return prevCategories;
      });
    } catch (error) {
      console.error("Erreur lors de la récupération des points :", error);
      setError("Erreur lors de la récupération des points.");
    } finally {
      setLoading(false);
    }
  }, [fetchDataFunctions, selectedOption, selectedPromotion, userId, role]);

  // Utiliser useEffect pour déclencher fetchPoints lorsque les dépendances changent
  useEffect(() => {
    // Définir promotionId en toute sécurité
    const promotionId = selectedPromotion?.identifiant_promotion;
    
    // Vérifier si promotionId ou userId est présent et que selectedOption est défini
    if ((userId || promotionId) && selectedOption) {
      fetchPoints();
    }
  }, [fetchPoints, selectedOption, userId, selectedPromotion]);

  // Mettre à jour les options du graphique lorsque les catégories changent
  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: { ...prevOptions.xaxis, categories: categories },
    }));
  }, [categories]);

  // Gérer le changement d'option
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setError(null);
  };

  return (
    <div>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Points {capitalizeFirstLetter(selectedOption)}
        </Typography>
        <FormControl variant="outlined" size="small">
          <Select value={selectedOption} onChange={handleOptionChange} displayEmpty>
            <MenuItem value="competences">Compétences</MenuItem>
            <MenuItem value="parcours">Parcours</MenuItem>
            {/* <MenuItem value="activites">Activités</MenuItem> */}
            <MenuItem value="blocs">Blocs</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="350px">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      ) : (
        <ReactApexChart options={options} series={series} type="heatmap" height={700} />
      )}
    </div>
  );
};

export default ApexChartCrux;
