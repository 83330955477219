import { useContext, useState, useEffect } from "react";
import { AuthContext } from "context";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import { useAbility } from "@casl/react";
import { AbilityContext } from "Can";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import PropTypes from "prop-types";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton"; // Ajoutez cet import
import DeleteIcon from "@mui/icons-material/Delete"; // Ajout de l'import manquant
import EditIcon from "@mui/icons-material/Edit"; // Icone Modifier
import VisibilityIcon from "@mui/icons-material/Visibility";
import useStore from "services/store";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';

function ActiviteCard({
  image,
  title,
  description,
  price,
  identifiant_activite,
  onExpand,
  expanded,
  details,
  evaluation,
  livrables,
  todo,
  identifiant_parcours,
  idMatiere,
  padlet_url
}) {
  const { userIdentite, userEtab, userRights, role } = useContext(AuthContext);
  const { userId } = useStore((state) => state);
  const [userUid] = useState(userId); // Initialize `userUid` using `userId` from store
  console.log("yvan 00 : ", userUid, identifiant_activite)
  const ability = useAbility(AbilityContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [resourceList, setResourceList] = useState([]); // Liste des ressources associées
  const [isReady, setIsReady] = useState(false); // État pour indiquer si identifiant_activite est prêt
  const [validationLevels, setValidationLevels] = useState([]); // Pour stocker les niveaux de validation
  const [competences, setCompetences] = useState([]);
  const [validations, setValidations] = useState([]);

  const canEditActivity = ability.can("edit", "activite", idMatiere);
  
  // États pour la gestion de l'image
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  const [githubLink, setGithubLink] = useState(""); // Stocker le lien GitHub

  console.log("star : ", padlet_url)

  useEffect(() => {
    const fetchCompetencesAndValidationLevels = async () => {
      if (identifiant_activite) {
        try {
          // Appel à l'API pour récupérer les compétences associées à l'activité
          const competencesResponse = await crudsServiceFAPI.getCompetencesByActivite(identifiant_activite);
          console.log("Monk aime : ", padlet_url)
          const competencesData = await Promise.all(
            competencesResponse.map(async (comp) => {
              // Récupérer les détails de chaque compétence par son identifiant
              const competenceDetails = await crudsServiceFAPI.getCompetenceById(comp.identifiant_competence);
              console.log("vemines : competenceDetails ", competenceDetails, "comp", comp);
              return {
                numero_competence: competenceDetails.numero_competence,
                identifiant_competence: competenceDetails.identifiant_competence,
                denomination_competence: competenceDetails.denomination_competence,
                niveau_competence_visee_referentiel: competenceDetails.niveau_competence_visee_referentiel,
                niveau_vise_referentiel: competenceDetails.niveau_competence_visee_referentiel,
                niveau_vise_activite: comp.niveau_vise_activite,
                points_competence_vises: comp.points_competence_vises,
                numero_bloc: competenceDetails.numero_bloc,
                denomination_bloc: competenceDetails.denomination_bloc,
                denomination_unite: competenceDetails.denomination_unite
              };
            })
          );
          console.log("kiwi : ", competencesData)
          setCompetences(competencesData);
        } catch (error) {
          console.error("Erreur lors de la récupération des données :", error);
        }
      }
    };

    fetchCompetencesAndValidationLevels();
  }, [identifiant_activite]);

  useEffect(() => {
    const fetchLatestValidationForActivity = async () => {
      console.log("yvan : ", identifiant_activite, userUid);
      if (identifiant_activite) {
        try {
          const validationPayload = {
            identifiant_eleve: userUid,
            identifiant_activite: identifiant_activite,
          };
          console.log("yvan1 : ", validationPayload);
  
          const latestValidation = await crudsServiceFAPI.getLatestValidationForActivity(validationPayload);
          console.log("yvan2 : ", latestValidation);
  
          // Vérifier que latestValidation n'est pas vide avant de mettre à jour l'état
          if (latestValidation && latestValidation.length > 0) {
            console.log("yvan3 : ", latestValidation);
            setValidations(latestValidation);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération de la dernière validation :", error);
        }
      }
    };
  
    fetchLatestValidationForActivity();
  }, [identifiant_activite, userUid]);
  
  const handleValidateGithubLink = async () => {
    console.log("dino : ")
    if (githubLink) {
      try {
        // Créer un payload qui contient toutes les compétences associées à l'activité
        const validationPayload = {
          identifiant_eleve: userUid,
          identifiant_activite: identifiant_activite,
          lien_validation: githubLink, // Le lien GitHub soumis
          competences: competences.map((comp) => ({
            identifiant_competence: comp.identifiant_competence,
          })),
        };

        console.log("dino : ", validationPayload)

        // Appel à l'API avec toutes les compétences en un seul payload
        await crudsServiceFAPI.createValidation(validationPayload);

        alert("Validation créée avec succès !");
       window.location.reload();
      } catch (error) {
        console.error("Erreur lors de la création de la validation :", error);
        alert("Erreur lors de la soumission de la validation.");
      }
    } else {
      alert("Veuillez entrer un lien GitHub.");
    }
  };

  
  // Charger l'image et appliquer le cache busting
  useEffect(() => {
    if (identifiant_activite) {
      const timestamp = new Date().getTime(); // Cache busting en utilisant un timestamp unique
      const imagePath = `${process.env.REACT_APP_FAPI_URL}/files/activites/${identifiant_activite}.jpg?t=${timestamp}`;

      // Vérifier si l'image existe
      fetch(imagePath)
        .then((response) => {
          if (response.ok) {
            setImageUrl(imagePath);
          } else {
            // Si l'image n'existe pas, utiliser une image par défaut
            setImageUrl(`${process.env.REACT_APP_FAPI_URL}/files/activites/defaut.jpg?t=${timestamp}`);
          }
          setIsReady(true);
        })
        .catch(() => {
          // En cas d'erreur, utiliser une image par défaut
          setImageUrl(`${process.env.REACT_APP_FAPI_URL}/files/activites/defaut.jpg?t=${timestamp}`);
          setIsReady(true);
        });
    }
  }, [identifiant_activite]);

  useEffect(() => {
    const fetchResources = async () => {
      if (expanded && isReady) {
        try {
          const response = await crudsServiceFAPI.getResourcesByActivite(identifiant_activite);
          setResourceList(response);
        } catch (error) {
          console.error("Erreur lors de la récupération des ressources :", error);
        }
      }
    };

    fetchResources();
  }, [expanded, isReady, identifiant_activite]);

  const handleEdit = () => {
    navigate(
      `/parcours/parcours-gestion/activite-nouveau?identifiant_activite=${identifiant_activite}&identifiant_parcours=${identifiant_parcours}`
    );
  };

  const handleDelete = async () => {
    try {
      await crudsServiceFAPI.deleteActivite(identifiant_activite);
      setOpen(false);
      window.location.reload();
    } catch (error) {
      console.error("Erreur lors de la suppression de l'activité :", error);
      alert("Erreur lors de la suppression de l'activité");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setImageUrl(URL.createObjectURL(e.target.files[0]));
  };

  // Upload d'image et mise à jour de l'URL avec cache busting
  const handleUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      const newFileName = `${identifiant_activite}.jpg`;
      formData.append("file", new File([selectedFile], newFileName));

      try {
        const path = `activites`;
        await crudsServiceFAPI.uploadFile(`${path}/`, formData);

        // Ajouter un timestamp pour forcer le rechargement de l'image après l'upload
        const timestamp = new Date().getTime();
        setImageUrl(`${process.env.REACT_APP_FAPI_URL}/files/activites/${identifiant_activite}.jpg?t=${timestamp}`);

        // Rafraîchir la page après le téléchargement réussi
        window.location.reload();
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  // Fonction pour supprimer une compétence liée à une activité
  const handleDeleteCompetence = async (denomination_competence, identifiant_competence, identifiant_activite, niveau_vise_activite, competences, setCompetences) => {
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer la compétence ${denomination_competence} de l'activité ${identifiant_activite} ?`)) {
      try {
        console.log("merci : ", identifiant_competence)
        // Appel à la méthode qui utilise le nouvel endpoint avec le niveau_vise_activite
        await crudsServiceFAPI.deleteActiviteCompetence(identifiant_activite, identifiant_competence, niveau_vise_activite);
        
        alert('Compétence supprimée avec succès');
        
        // Mettre à jour la liste des compétences après suppression
        setCompetences(competences.filter(c => !(c.identifiant_competence === identifiant_competence && c.niveau_vise_activite === niveau_vise_activite)));
      } catch (error) {
        console.error("Erreur lors de la suppression de la compétence :", error);
        alert("Erreur lors de la suppression de la compétence.");
      }
    }
  };

  // Fonction pour modifier une compétence
  const handleEditCompetence = async (competenceId, competences, setCompetences, identifiant_activite) => {
    const updatedPoints = prompt("Entrez le nouveau nombre de points pour la compétence :");
    const updatedLevel = prompt("Entrez le nouveau niveau visé pour la compétence :");

    // Validation des entrées pour s'assurer qu'elles sont des nombres
    if (!isNaN(updatedPoints) && !isNaN(updatedLevel) && updatedPoints !== '' && updatedLevel !== '') {
      try {
        const payload = {
          points_competence_vises: parseInt(updatedPoints, 10),
          niveau_vise_activite: parseInt(updatedLevel, 10)
        };

        await crudsServiceFAPI.updateActiviteCompetence(identifiant_activite, competenceId, payload);
        alert('Compétence modifiée avec succès');

        // Mettre à jour la liste des compétences avec les nouvelles valeurs
        setCompetences(competences.map(c => 
          c.numero_competence === competenceId 
            ? { ...c, points_competence_vises: updatedPoints, niveau_vise_activite: updatedLevel } 
            : c
        ));
      } catch (error) {
        console.error("Erreur lors de la modification de la compétence :", error);
        alert("Erreur lors de la modification de la compétence.");
      }
    } else {
      alert("Les valeurs doivent être des nombres valides.");
    }
  };

  if (!isReady) {
    return null; // ou un spinner/loader si nécessaire
  }

  return (
    <>
      <Card
        sx={{
          transition: "all 0.3s ease",
          maxWidth: expanded ? "100%" : "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          boxShadow: expanded ? "0px 4px 20px rgba(0, 0, 0, 0.12)" : "0px 2px 10px rgba(0, 0, 0, 0.1)", // Accentuer l'ombre
          marginBottom: expanded ? "20px" : "10px", // Ajouter une marge en bas pour séparer les blocs
          "&:hover .card-header": {
            transform: expanded ? "none" : "translate3d(0, -10px, 0)",
          },
          "&:hover .blur-background": {
            opacity: expanded ? 1 : 0,
          },
        }}
      >
        <MDBox
          position="relative"
          borderRadius="lg"
          mt={2}
          mx={2}
          className="card-header"
          sx={{
            transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <MDBox
            component="img"
            src={imageUrl}
            alt={title}
            borderRadius="lg"
            shadow="md"
            width={expanded ? "150px" : "100%"}
            height="auto"
            position="relative"
            zIndex={1}
          />
          <MDBox
            className="blur-background"
            borderRadius="lg"
            shadow="md"
            width={expanded ? "150px" : "100%"}
            height="100%"
            position="absolute"
            left={0}
            top="0"
            sx={{
              backgroundImage: `url(${imageUrl})`,
              transform: "scale(0.94)",
              filter: "blur(12px)",
              backgroundSize: "cover",
              transition: "opacity 300ms ease-in-out",
              display: expanded ? "none" : "block",
            }}
          />

          {ability.can("edit", "activite", idMatiere) && (
            <>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                component="label"
                htmlFor={`edit-activite-image-${identifiant_activite}`}
                sx={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: 2,
                }}
              >
                <Icon>edit</Icon>
              </MDButton>
              <input
                type="file"
                id={`edit-activite-image-${identifiant_activite}`}
                name="attachment"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={handleUpload}
                sx={{
                  position: "absolute",
                  top: "50px",
                  right: "10px",
                  zIndex: 2,
                }}
              >
                Save
              </MDButton>
            </>
          )}
        </MDBox>
        <MDBox textAlign="center" pt={3} px={3} flexGrow={1}>
          <MDTypography variant="h5" fontWeight="regular" sx={{ mt: 0.5 }}>
            {title}
          </MDTypography>
          <MDTypography
            variant="body2"
            color="text"
            sx={{ mt: 1, mb: 1 }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
         
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt={1}
          pb={3}
          px={3}
          lineHeight={1}
        >
          {/* Actions */}
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pt={1}
            pb={3}
            px={3}
            lineHeight={1}
            sx={{
              position: "relative",  // Ajouté pour éviter que les boutons sortent de la Card
              width: "100%",         // Assurez-vous que la largeur est bien respectée
              flexWrap: "wrap",      // Ajouté pour gérer les boutons qui peuvent déborder sur plusieurs lignes
            }}
          >
            <MDBox color="text" display="flex" alignItems="center" flexWrap="wrap">
              <MDButton variant="text" color="info" onClick={onExpand}>
                <VisibilityIcon />
                {expanded ? "Réduire" : ""}
              </MDButton>
              
             
              
              

{
  validations[0]?.status_validation === "Vérifiée" ? (
    <CheckCircleIcon />
  ) : validations[0]?.status_validation === "En cours de vérification" ? (
    <WorkHistoryIcon />
  ) : (
    <DangerousIcon />
  )
}

          
              
              
             
            </MDBox>
          </MDBox>
        </MDBox>
        {expanded && (
          <MDBox p={3}>
            <Grid container spacing={2}>
              {/* Bloc des Compétences */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Compétences Associées
                  </MDTypography>
                  {competences.length > 0 ? (
                    <MDBox>
                      {competences.map((competence, index) => (
                        <Card key={index} sx={{ p: 2, mb: 2, boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}>
                          <MDBox
                            sx={{ marginBottom: "8px", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                          >
                            <MDTypography variant="body2" sx={{ fontSize: "0.875rem" }}>
                              La compétence <span style={{ fontWeight: "bold", color: "blue" }}>{competence.numero_competence}</span> - 
                              <span style={{ fontWeight: "bold", color: "blue" }}> {competence.denomination_competence} </span>  
                              du bloc <span style={{ fontWeight: "bold", color: "blue" }}>{competence.numero_bloc}</span> -
                              <span style={{ fontWeight: "bold", color: "blue" }}> {competence.denomination_bloc}</span> est attendue dans le référentiel au niveau 
                              <span style={{ fontWeight: "bold", color: "blue" }}> {competence.niveau_vise_referentiel}</span>. 
                              Dans cette activité, vous la travaillerez au niveau <span style={{ fontWeight: "bold", color: "blue" }}>{competence.niveau_vise_activite} </span> 
                              qui vous permettra de remporter <span style={{ fontWeight: "bold", color: "blue" }}>{competence.points_competence_vises}</span> points dans ce niveau.
                            </MDTypography>
                            {canEditActivity && (
                              <MDBox>
                                {/* Icône Modifier */}
                                <IconButton
                                  onClick={() => handleEditCompetence(competence.numero_competence, competences, setCompetences, identifiant_activite)}
                                >
                                  <EditIcon />
                                </IconButton>
                                {/* Icône Supprimer */}
                                <IconButton
                                  onClick={() => handleDeleteCompetence(competence.denomination_competence, competence.identifiant_competence, identifiant_activite, competence.niveau_vise_activite, competences, setCompetences)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </MDBox>
                            )}
                          </MDBox>
                        </Card>
                      ))}
                    </MDBox>
                  ) : (
                    <MDTypography variant="body2" color="text">
                      Aucune compétence associée trouvée pour cette activité.
                    </MDTypography>
                  )}
                </Card>
              </Grid>

              {/* Bloc 6 - Niveaux de validation */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Niveaux de Validation des Compétencess
                  </MDTypography>
                  {validations.length > 0 ? (
                    <MDBox>
                      {validations.map((validation, index) => (
                        <MDBox key={index} sx={{ marginBottom: "16px" }}>
                          <MDTypography variant="body2" sx={{ fontSize: "0.875rem" }}>
                            <strong>Date de validation :</strong> {validation.date_validation} <br />
                            <strong>Lien de validation :</strong> <a href={validation.lien_validation} target="_blank" rel="noopener noreferrer">{validation.lien_validation}</a> <br />
                            <strong>Status :</strong> {validation.status_validation} <br />
                            <strong>Message :</strong> <span dangerouslySetInnerHTML={{ __html: validation.message_validation }} /> <br />
                            <strong>Compétences :</strong>
                          </MDTypography>
  
                          {(validation?.competences?.length || 0) > 0 ? (
                            <MDBox>
                              {validation.competences.map((competence, compIndex) => (
                                <MDBox key={compIndex} sx={{ marginLeft: "16px", marginBottom: "8px" }}>
                                  <MDTypography variant="body2" sx={{ fontSize: "0.875rem" }}>
                                    <strong>Numéro de compétence :</strong> {competence.numero_competence} <br />
                                    <strong>Dénomination :</strong> {competence.denomination_competence} <br />
                                    <strong>Niveau de validation :</strong> {competence.niveau_validation_competence} <br />
                                    <strong>Points remportés :</strong> {competence.points_remportes} <br />
                                  </MDTypography>
                                </MDBox>
                              ))}
                            </MDBox>
                          ) : (
                            <MDTypography variant="body2" color="text">
                              Aucune compétence trouvée pour cette validation.
                            </MDTypography>
                          )}
  
                          {/* Divider entre chaque validation */}
                          {index < validations.length - 1 && <Divider sx={{ mt: 2, mb: 2 }} />}
                        </MDBox>
                      ))}
                    </MDBox>
                  ) : (
                    <MDTypography variant="body2" color="text">
                      Aucun niveau de validation trouvé pour cette activité.
                    </MDTypography>
                  )}
                </Card>
              </Grid>
  
              {/* Bloc 1 - Détails */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Détails de l'activité
                  </MDTypography>
                  <MDTypography
                    variant="body2"
                    color="text"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: details }}
                  />
                </Card>
              </Grid>
  
              {/* Bloc 2 - Évaluation */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Évaluation
                  </MDTypography>
                  <MDTypography
                    variant="body2"
                    color="text"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: evaluation }}
                  />
                </Card>
              </Grid>
  
              {/* Bloc 3 - Livrables */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Livrables
                  </MDTypography>
                  <MDTypography
                    variant="body2"
                    color="text"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: livrables }}
                  />
                </Card>
              </Grid>
  
              {/* Bloc 4 - To-do */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                  <MDTypography variant="h6" fontWeight="bold">
                    À faire (To-do)
                  </MDTypography>
                  <MDTypography
                    variant="body2"
                    color="text"
                    component="div"
                    dangerouslySetInnerHTML={{ __html: todo }}
                  />
                </Card>
              </Grid>
  
              {/* Bloc 5 - Ressources */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Ressources
                  </MDTypography>
                  {resourceList.length > 0 ? (
                    <MDBox>
                      {resourceList.map((resource, index) => (
                        <MDBox key={index} sx={{ marginBottom: "8px" }}>
                          <MDTypography
                            variant="body2"
                            sx={{ fontSize: "0.875rem" }} // Taille du texte réduite
                          >
                            <a
                              href={`${process.env.REACT_APP_FAPI_URL}/files/ressources/${resource.nom_ressource}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {resource.nom_ressource}
                            </a>
                          </MDTypography>
                        </MDBox>
                      ))}
                    </MDBox>
                  ) : (
                    <MDTypography variant="body2" color="text">
                      Aucune ressource associée.
                    </MDTypography>
                  )}
                </Card>
              </Grid>
  
              {/* Bloc 7 - Répertoire GitHub */}
              <Grid item xs={12}>
                <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Répertoire GitHub
                  </MDTypography>
                  {/* Vérification du statut de validation */}
                  {validations.length > 0 ? (
                    <>
                      {validations[0].status_validation === "Jamais soumis à validation" ? (
                        <>
                          <MDTypography variant="body2" color="text">
                            Vous n'avez encore jamais soumis une demande de validation pour cette activité.
                          </MDTypography>
                          {/* <div>
                            <p>User ID: {userUid}</p>
                            <p>User Role: {role}</p>
                          </div> */}
                          <MDTypography variant="body2" color="text">
                            Soumettez le lien vers votre répertoire GitHub pour cette activité.
                          </MDTypography>
                          <MDBox display="flex" alignItems="center" mt={2}>
                            <MDBox flexGrow={1} mr={2}>
                              <input
                                type="text"
                                value={githubLink}
                                onChange={(e) => setGithubLink(e.target.value)}
                                placeholder="Entrez votre lien GitHub ici"
                                style={{
                                  width: "100%",
                                  padding: "8px",
                                  borderRadius: "4px",
                                  border: "1px solid #ccc",
                                }}
                              />
                            </MDBox>
                            <MDButton variant="gradient" color="success" onClick={handleValidateGithubLink}>
                              Valider
                            </MDButton>
                          </MDBox>
                        </>
                      ) : validations[0].status_validation === "Vérifiée" ? (
                        <>
                          <MDTypography variant="body2" color="text">
                            Votre demande de validation pour cette activité a été vérifiée le {validations[0].date_validation} avec le message suivant : {validations[0].message_validation} vous pouvez soumettre à nouveau une demande de validation.
                          </MDTypography>
                          {/* <div>
                            <p>User ID: {userUid}</p>
                            <p>User Role: {role}</p>
                          </div> */}
                          <MDTypography variant="body2" color="text">
                            Soumettez le lien vers votre répertoire GitHub pour cette activité.
                          </MDTypography>
                          <MDBox display="flex" alignItems="center" mt={2}>
                            <MDBox flexGrow={1} mr={2}>
                              <input
                                type="text"
                                value={githubLink}
                                onChange={(e) => setGithubLink(e.target.value)}
                                placeholder="Entrez votre lien GitHub ici"
                                style={{
                                  width: "100%",
                                  padding: "8px",
                                  borderRadius: "4px",
                                  border: "1px solid #ccc",
                                }}
                              />
                            </MDBox>
                            <MDButton variant="gradient" color="success" onClick={handleValidateGithubLink}>
                              Valider
                            </MDButton>
                          </MDBox>
                        </>
                      ) : (
                        <MDTypography variant="body2" color="text">
                          En cours de validation...
                        </MDTypography>
                      )}
                    </>
                  ) : (
                    <>
                      {/* Si aucune validation trouvée, afficher l'input et le bouton */}
                      <MDTypography variant="body2" color="text">
                        Soumettez le lien vers votre répertoire GitHub pour cette activité.
                      </MDTypography>
                      <MDBox display="flex" alignItems="center" mt={2}>
                        <MDBox flexGrow={1} mr={2}>
                          <input
                            type="text"
                            value={githubLink}
                            onChange={(e) => setGithubLink(e.target.value)}
                            placeholder="Entrez votre lien GitHub ici"
                            style={{
                              width: "100%",
                              padding: "8px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                            }}
                          />
                        </MDBox>
                        <MDButton variant="gradient" color="success" onClick={handleValidateGithubLink}>
                          Valider
                        </MDButton>
                      </MDBox>
                    </>
                  )}
                </Card>
              </Grid>

              {/* Bloc 8 - Iframe Padlet */}
              {padlet_url && (
                <Grid item xs={12}>
                  <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                    <MDTypography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
                      Padlet
                    </MDTypography>
                    <MDBox
                      sx={{
                        position: "relative",
                        paddingBottom: "56.25%", // 16:9 aspect ratio
                        paddingTop: "25px",
                        height: 0,
                      }}
                    >
                      <iframe
                        src={padlet_url}
                        title="Padlet Embedded"
                        frameBorder="0"
                        allow="camera;microphone;geolocation"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          border: "none",
                        }}
                      ></iframe>
                    </MDBox>
                  </Card>
                </Grid>
              )}
            </Grid>
          </MDBox>
        )}
        {/* Optionally, you can add the Padlet iframe outside the expanded section if you want it always visible */}
        {/* {padlet_url && (
          <MDBox p={3}>
            <Card sx={{ p: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
              <MDTypography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
                Padlet
              </MDTypography>
              <MDBox
                sx={{
                  position: "relative",
                  paddingBottom: "56.25%", // 16:9 aspect ratio
                  paddingTop: "25px",
                  height: 0,
                }}
              >
                <iframe
                  src={padlet_url}
                  title="Padlet Embedded"
                  frameBorder="0"
                  allow="camera;microphone;geolocation"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    border: "none",
                  }}
                ></iframe>
              </MDBox>
            </Card>
          </MDBox>
        )} */}
      </Card>
    </>
  );
}

ActiviteCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  identifiant_activite: PropTypes.number,
  onExpand: PropTypes.func,
  expanded: PropTypes.bool,
  details: PropTypes.string,
  evaluation: PropTypes.string,
  livrables: PropTypes.string,
  todo: PropTypes.string,
  identifiant_parcours: PropTypes.string,
  is_checked: PropTypes.string,
  points_remportes: PropTypes.number,
  niveau_competence: PropTypes.number,
  date_validation: PropTypes.string,
  idMatiere: PropTypes.string,
  padlet_url: PropTypes.string
};

export default ActiviteCard;
