import { useState, useEffect, useContext, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CrudServiceFAPI from "services/cruds-serviceFAPI";
import { AbilityContext } from "Can";
import { useAbility } from "@casl/react";
import { AuthContext } from "context";
import SettingsIcon from "@mui/icons-material/Settings";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import ContentCutIcon from '@mui/icons-material/ContentCut';
import useStore from "services/store";
import RestartAltIcon from '@mui/icons-material/RestartAlt';


// FormDialog pour gérer la liste déroulante des sections et promotions
// Mise à jour de FormDialog pour inclure le champ Rôle
function FormDialog({
  open,
  onClose,
  onSubmit,
  formData,
  handleInputChange,
  fields,
  title,
  sections,
  promotions,
  selectedRole,
  handleSectionChange,
  professors,
  handleProfessorSelect,
  roles = ["administrateur", "professeur", "eleve", "gestionnaire"], // Rôles disponibles
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {fields.map((field) => (
          <TextField
            key={field.name}
            label={field.label}
            name={field.name}
            type={field.type || "text"}
            value={formData[field.name] || ""}
            onChange={handleInputChange}
            fullWidth
            margin="dense"
            disabled={field.disabled}
          />
        ))}

  

        {/* Section et Promotion pour le rôle 'eleve' */}
        {formData.role === "eleve" && (
          <>
            {/* Liste déroulante Section */}
            <FormControl fullWidth margin="dense">
              <InputLabel id="section-label">Section</InputLabel>
              <Select
                labelId="section-label"
                name="identifiant_section"
                value={formData.identifiant_section || ""}
                onChange={handleSectionChange}
              >
                {sections.map((section) => (
                  <MenuItem key={section.identifiant_section} value={section.identifiant_section}>
                    {section.detail_section}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Liste déroulante Promotion */}
            <FormControl fullWidth margin="dense">
              <InputLabel id="promotion-label">Promotion</InputLabel>
              <Select
                labelId="promotion-label"
                name="identifiant_promotion"
                value={formData.identifiant_promotion || ""}
                onChange={handleInputChange}
              >
                {promotions.map((promotion) => (
                  <MenuItem key={promotion.identifiant_promotion} value={promotion.identifiant_promotion}>
                    {promotion.denomination_promotion}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}

        {selectedRole === "professeur" && (
          <>
            {/* Liste déroulante pour sélectionner un professeur */}
            <FormControl fullWidth margin="dense">
              <InputLabel id="professor-select-label">Sélectionner un professeur</InputLabel>
              <Select
                labelId="professor-select-label"
                value={formData.identifiant_utilisateur || ""}
                onChange={handleProfessorSelect}
                name="identifiant_utilisateur"
              >
                {professors.map((professor) => (
                  <MenuItem
                    key={professor.identifiant_utilisateur}
                    value={professor.identifiant_utilisateur}
                  >
                    {professor.nom_utilisateur} {professor.prenom_utilisateur}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <MDButton onClick={onClose} color="secondary">
          Annuler
        </MDButton>
        <MDButton onClick={onSubmit} color="primary">
          {title.includes("Modifier") ? "Mettre à jour" : "Ajouter"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}





const EtabManagement = () => {
  const {
    setSelectedEtabManagement,
  } = useStore((state) => ({
    setSelectedEtabManagement: state.setSelectedEtabManagement, // Nouvelle fonction ajoutée
  }));
  const selectedEtab = useStore((state) => state.selectedEtab);
  console.log("fumier0", selectedEtab)
  const sectionRef = useRef(null);  // Référence pour la section "sections-section"
  const userSectionRef = useRef(null);  // Référence pour la section "users-section"


  const { userUid, userIdentite, userEtab, userRights, role } = useContext(AuthContext);
  console.log("maddoff ", role)
  const ability = useContext(AbilityContext);
  const [gestion, setGestion] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const canViewGestion = ability.can("manage", "all");
    setGestion(canViewGestion);
    console.log("canManage mis à jour :", canViewGestion);
  }, [ability, token, userUid]); // Réévalue dès que `ability` change


  
  const droit_create_etablissement = ability.can("create", "etablissement")
  const droit_edit_etablissement = ability.can("edit", "etablissement")
  const droit_delete_etablissement = ability.can("delete", "etablissement")

  console.log("droit_view_etablissement", droit_create_etablissement, droit_edit_etablissement, droit_delete_etablissement);
  console.log("chalzeules", role);
  const [itemsEtab, setItemsEtab] = useState([]);
  const [tableDataEtab, setTableDataEtab] = useState([]);
  const [selectedEtabDetails, setSelectedEtabDetails] = useState(null);
  const [showEtabDetails, setShowEtabDetails] = useState(false);



  const [sections, setSections] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [notification, setNotification] = useState({ value: false, text: "" });
  const [openSettingsDialogProfesseur, setOpenSettingsDialogProfesseur] = useState(false);
  const [openSettingsDialogGestionnaire, setOpenSettingsDialogGestionnaire] = useState(false);
  const [matieres, setMatieres] = useState([]);
  const [selectedMatiere, setSelectedMatiere] = useState("");
  const [droitsProf, setDroitsProf] = useState(null);
  const [checkboxValues, setCheckboxValues] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);

  const [tableDataSections, setTableDataSections] = useState([]);
const [selectedSection, setSelectedSection] = useState(null);
const [tableDataPromotions, setTableDataPromotions] = useState([]);
const [selectedPromotion, setSelectedPromotion] = useState(null);
const [tableDataMatieres, setTableDataMatieres] = useState([]);

const [openSectionDialog, setOpenSectionDialog] = useState(false);
const [newSection, setNewSection] = useState({ denomination_section: "", detail_section: "" });

const [openPromotionDialog, setOpenPromotionDialog] = useState(false);
const [newPromotion, setNewPromotion] = useState({ denomination_promotion: "", identifiant_section: selectedSection });

const [openMatiereDialog, setOpenMatiereDialog] = useState(false);
const [newMatiere, setNewMatiere] = useState({ denomination_matiere: "", detail_matiere: "" });

const [editingSection, setEditingSection] = useState(null); // Stocker l'ID de la section à éditer
const [editingMatiere, setEditingMatiere] = useState(null); // Pour stocker l'ID de la discipline à éditer

const [tableDataReferentiels, setTableDataReferentiels] = useState([]);
const [allReferentiels, setAllReferentiels] = useState([]);
const [selectedReferentiel, setSelectedReferentiel] = useState("");
const [openReferentielDialog, setOpenReferentielDialog] = useState(false);


const [openEtabForm, setOpenEtabForm] = useState(false);
const [editingEtab, setEditingEtab] = useState(null);
const [newEtab, setNewEtab] = useState({
  identifiant_etablissement: "",
  denomination_etablissement: "",
});

const [editingPromotion, setEditingPromotion] = useState(null); // Stocker l'ID de la promotion à éditer
// Ajouter une nouvelle variable d'état pour stocker les utilisateurs d'un établissement spécifique
const [etablissementUtilisateurs, setEtablissementUtilisateurs] = useState([]);


const handleAddSection = () => {
  setOpenSectionDialog(true);
};

const handleAddPromotion = () => {
  setNewPromotion({ denomination_promotion: "", identifiant_section: selectedSection });
  setOpenPromotionDialog(true);
};

const handleAddMatiere = () => {
  setOpenMatiereDialog(true);
};

const resetSectionForm = () => {
  setNewSection({ denomination_section: "", detail_section: "" });
};

const resetPromotionForm = () => {
  setNewPromotion({ denomination_promotion: "", identifiant_section: selectedSection });
};

const resetMatiereForm = () => {
  setNewMatiere({ denomination_matiere: "", detail_matiere: "" });
};

const fetchReferentielsByPromotion = async (promotionId) => {
  try {
    const response = await CrudServiceFAPI.getReferentielsParPromotion(promotionId); // Utilise désormais getReferentielByPromotion au lieu de getReferentielsParPromotion
    if (response.referentiel) { 
      setTableDataReferentiels([response.referentiel]); // Convertit en tableau pour garder le format attendu
    } else {
      setTableDataReferentiels([]); // Vide si aucun 
    }
  } catch (error) {
    console.error("Erreur lors de la récupération du  :", error);
  }
};

const fetchAllReferentiels = async () => {
  try {
    const referentielsData = await CrudServiceFAPI.getAllReferentiels();
    console.log("Referentiels récupérés :", referentielsData);  
    setAllReferentiels(referentielsData);
  } catch (error) {
    console.error("Erreur lors de la récupération de tous les s :", error);
  }
};

useEffect(() => {
  fetchAllReferentiels();
}, []);


const handleSubmitReferentiel = async () => {
  if (!selectedReferentiel) {
    showNotification("Veuillez sélectionner un .");
    return;
  }
  console.log("leo : ",selectedPromotion, selectedReferentiel )

  try {
    
    await CrudServiceFAPI.associateReferentielToPromotion(selectedPromotion, selectedReferentiel);
    showNotification(" associé avec succès.");
    fetchReferentielsByPromotion(selectedPromotion); // Recharge la liste avec le nouveau 
    setOpenReferentielDialog(false); // Ferme la boîte de dialogue
  } catch (error) {
    console.error("Erreur lors de l'association du  :", error);
    showNotification("Erreur lors de l'association du .");
  }
};



const handleSubmitSection = async () => {
  try {
    const payload = {
      denomination_section: newSection.denomination_section,
      detail_section: newSection.detail_section,
      identifiant_etablissement: selectedEtabDetails.identifiant_etablissement,
    };

    if (editingSection) {
      // Mettre à jour une section existante
      await CrudServiceFAPI.updateSection(editingSection, payload);
      showNotification("Section mise à jour avec succès");
    } else {
      // Ajouter une nouvelle section
      await CrudServiceFAPI.createSection(payload);
      showNotification("Section créée avec succès");
    }

    fetchSectionsByEtab(selectedEtabDetails.identifiant_etablissement); // Recharger les sections
    setOpenSectionDialog(false);
    setEditingSection(null); // Réinitialiser l'édition
  } catch (error) {
    console.error("Erreur lors de l'ajout ou de la mise à jour de la section :", error);
  }
};

const handleEditMatiere = (matiere) => {
  setNewMatiere({
    denomination_matiere: matiere.denomination_matiere,
    detail_matiere: matiere.detail_matiere,
  });
  setEditingMatiere(matiere.identifiant_matiere); // Stocker l'ID de la discipline à modifier
  setOpenMatiereDialog(true); // Ouvrir le formulaire pour éditer la discipline
};

const handleDeleteMatiere = async (matiereId) => {
  try {
    await CrudServiceFAPI.deleteMatiere(matiereId);
    showNotification("Discipline supprimée avec succès");
    handlePromotionClick(selectedPromotion); // Recharger les disciplines après suppression
  } catch (error) {
    console.error("Erreur lors de la suppression de la discipline :", error);
  }
};


const handleSubmitPromotion = async () => {
  if (!newPromotion.denomination_promotion || !selectedReferentiel) {
    showNotification("Veuillez remplir tous les champs obligatoires.");
    return;
  }

  try {
    const payload = {
      denomination_promotion: newPromotion.denomination_promotion,
      identifiant_section: selectedSection, // Utilisation de la section déjà sélectionnée
      identifiant_referentiel: selectedReferentiel, // Inclure le  sélectionné
    };

    if (editingPromotion) {
      // Mettre à jour une promotion existante
      await CrudServiceFAPI.updatePromotion(editingPromotion, payload);
      showNotification("Promotion mise à jour avec succès");
    } else {
      // Ajouter une nouvelle promotion
      const response = await CrudServiceFAPI.createPromotion(payload);
      console.log("gugule Promotion créée :", response);
      showNotification("Promotion créée avec succès");
      try {
        await CrudServiceFAPI.associateReferentielToPromotion(response.identifiant_promotion, selectedReferentiel);
        showNotification(" associé avec succès.");
      } catch (error) {
        console.error("Erreur lors de l'association du  :", error);
        showNotification("Erreur lors de l'association du .");
      }
    }

    // Recharger les promotions
    handleSectionClick(selectedSection);
    setOpenPromotionDialog(false);
    setEditingPromotion(null); // Réinitialiser l'état d'édition
  } catch (error) {
    console.error("Erreur lors de l'ajout ou de la mise à jour de la promotion :", error);
  }
};




const handleSubmitMatiere = async () => {
  try {
    if (selectedUser && selectedMatiere) {
      const payload = {
        identifiant_utilisateur: selectedUser.identifiant_utilisateur,
        identifiant_matiere: selectedMatiere,
        view_activite: false,
        create_activite: false,
        delete_activite: false,
        edit_activite: false,
        view_parcours: false,
        create_parcours: false,
        delete_parcours: false,
        edit_parcours: false,
        view_validation: false,
        create_validation: false,
        edit_validation: false,
        delete_validation: false,
      };

      console.log("Payload pour ajout de droits de prof : ", payload);

      // Add the rights
      await CrudServiceFAPI.addDroitsProfMatiere(payload);
      showNotification(`${selectedUser.role} a été associé à la discipline avec succès.`);

      // Fetch and display the new rights
      const droitsProf = await CrudServiceFAPI.getDroitsProfsMatieres(
        selectedUser.identifiant_utilisateur,
        selectedMatiere
      );
      setDroitsProf(droitsProf);
      setCheckboxValues(droitsProf);
    } else {
      console.error("Erreur : Aucun utilisateur ou discipline sélectionné.");
      showNotification("Erreur : Aucun utilisateur ou discipline sélectionné.");
    }
  } catch (error) {
    console.error("Erreur lors de l'association du professeur à la discipline :", error);
    showNotification(`Erreur lors de l'association : ${error.message}`);
  }
};






  const showNotification = (message) => {
    setNotification({ value: true, text: message });
    setTimeout(() => setNotification({ value: false, text: "" }), 3000);
  };

  // Fonction pour récupérer les établissements
  const fetchEtabData = async () => {
    console.log("fumier00");
    try {
      const response = await CrudServiceFAPI.getEtablissementsParUser(userUid);
      console.log("epouser fumier :", response);
      
      // Vérifiez que `response.etablissements` existe et est un tableau
      const etablissements = response.etablissements || [];
      console.log("epouser fumier1 :", etablissements, selectedEtab);
  
      let selectedEtabArray;
  
      // Si le rôle est superadministrateur, on ne filtre pas
      if (role === "superadministrateur") {
        selectedEtabArray = etablissements;
      } else {
        // Sinon, on filtre en fonction de selectedEtab
        selectedEtabArray = etablissements.filter(
          (etab) => etab.identifiant_etablissement === selectedEtab.identifiant_etablissement
        );
      }
  
      console.log("fumier2", selectedEtabArray);
  
      const newItems = selectedEtabArray.map((etab) => ({
        identifiant_etablissement: etab.identifiant_etablissement || "",
        denomination_etablissement: etab.denomination_etablissement || "",
        date_creation_etablissement: etab.timestamp || null,  // Ajout de la date de création
        detail_etablissement: etab.detail_etablissement || "", // Ajout du détail de l'établissement
      }));
  
      setItemsEtab(newItems);
      setTableDataEtab(newItems);
    } catch (error) {
      console.error("Erreur lors de la récupération des établissements :", error);
    }
  };
  

  useEffect(() => {
    fetchEtabData();
  }, [selectedEtab]);


// Colonnes pour la table des établissements
const etabTableColumns = () => [
  { Header: "Identifiant établissement", accessor: "identifiant_etablissement", width: "20%" },
  {
    Header: "Dénomination établissement",
    accessor: "denomination_etablissement",
    width: "20%",
    Cell: ({ cell: { value }, row }) => (
      <MDTypography
        variant="button"
        color="info"
        onClick={() => handleEtabClick(row.original.identifiant_etablissement)}
        style={{ cursor: "pointer" }}
      >
        {value}
      </MDTypography>
    ),
  },
  { Header: "Créé le", accessor: "date_creation_etablissement", width: "20%" }, // Ajout de la date de création
  { Header: "Détail établissement", accessor: "detail_etablissement", width: "20%" }, // Ajout du détail
  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ row }) => (
      <MDBox display="flex" alignItems="center">
        {/* Bouton Edit */}
        { ability.can("edit", "etablissement") && (
          <IconButton onClick={() => handleEditEtab(row.original)}>
            <EditIcon />
          </IconButton>
        )}
        {/* Bouton Delete */}
        { ability.can("delete", "etablissement") && (
          <IconButton onClick={() => handleDeleteEtab(row.original.identifiant_etablissement)}>
            <DeleteIcon />
          </IconButton>
        )}
      </MDBox>
    ),
  },
];




// Gérer le clic sur un établissement
const handleEtabClick = async (identifiant_etablissement) => {
  setSelectedEtabManagement(identifiant_etablissement);
  setSections([]); 
  setPromotions([]); 
  setMatieres([]); 
  setSelectedSection(null); 
  setSelectedPromotion(null); 
  setSelectedEtabDetails(null);  // Réinitialiser les détails de l'établissement
  setTableDataSections([]);  // Réinitialiser la table des sections

  try {
    // Récupérer les utilisateurs associés à cet établissement
    const utilisateursData = await CrudServiceFAPI.getUtilisateursByEtablissement(identifiant_etablissement);
    console.log("Utilisateurs récupérés :", utilisateursData)
    const utilisateursWithRoles = (utilisateursData || []).map((user) => ({
      ...user,
      roles: Array.isArray(user.roles) ? user.roles : [],
    }));
    setSelectedEtabDetails((prevDetails) => ({
      ...prevDetails,
      identifiant_etablissement,
      utilisateurs: utilisateursWithRoles 
    }));
   console.log("utilisateursWithRoles", utilisateursWithRoles)
  } catch (err) {
    console.error("Erreur lors de la récupération des utilisateurs de l'établissement :", err);
    setSelectedEtabDetails((prevDetails) => ({
      ...prevDetails,
      identifiant_etablissement,
      utilisateurs: [], // Définit comme un tableau vide si aucun utilisateur
    }));
  }
  
  try {
    // Charger les sections associées à cet établissement
    const sectionsData = await CrudServiceFAPI.getSectionsByEtabId(identifiant_etablissement);
    setSections(sectionsData); // Mettre à jour les sections avec les données ou un tableau vide
    setTableDataSections(sectionsData); // Mettre à jour les données pour la table des sections
  } catch (err) {
    console.error("Erreur lors de la récupération des sections de l'établissement :", err);
    setSections([]); // Définit comme un tableau vide en cas d'erreur
    setTableDataSections([]); // Définit comme un tableau vide en cas d'erreur
  }
  
  // Afficher les détails de l'établissement même si les données sont vides ou en cas d'erreur
  setShowEtabDetails(true);
  
};

const handleSubmitEtab = async () => {
  if (!newEtab.identifiant_etablissement || !newEtab.denomination_etablissement) {
    showNotification("Veuillez remplir tous les champs obligatoires.");
    return;
  }

  try {
    const payload = {
      identifiant_etablissement: newEtab.identifiant_etablissement,
      denomination_etablissement: newEtab.denomination_etablissement,
    };

    if (editingEtab) {
      // Mettre à jour un établissement existant (seulement la dénomination)
      await CrudServiceFAPI.updateEtab(editingEtab, {
        denomination_etablissement: payload.denomination_etablissement,
      });
      showNotification("Établissement mis à jour avec succès");
    } else {
      // Ajouter un nouvel établissement
      await CrudServiceFAPI.createEtab(payload);
      showNotification("Établissement créé avec succès");
    }

    fetchEtabData(); // Recharger les établissements
    setOpenEtabForm(false);
    setEditingEtab(null); // Réinitialiser l'état d'édition
    setNewEtab({ identifiant_etablissement: "", denomination_etablissement: "" }); // Réinitialiser le formulaire
  } catch (error) {
    console.error(
      "Erreur lors de l'ajout ou de la mise à jour de l'établissement :",
      error
    );
  }
};


const handleEditEtab = (etab) => {
  setNewEtab({
    identifiant_etablissement: etab.identifiant_etablissement,
    denomination_etablissement: etab.denomination_etablissement,
  });
  setEditingEtab(etab.identifiant_etablissement); // Stocker l'ID de l'établissement à modifier
  setOpenEtabForm(true); // Ouvrir le formulaire pour éditer l'établissement
};


// Gérer la suppression d'un établissement
const handleDeleteEtab = async (etabId) => {
  try {
    await CrudServiceFAPI.deleteEtab(etabId);
    showNotification("Etablissement supprimé avec succès");
    fetchEtabData(); // Recharger les établissements après suppression
  } catch (error) {
    console.error("Erreur lors de la suppression de l'établissement :", error);
  }
};


// Gérer la suppression d'un utilisateur
const handleDissociateUser = async (userId) => {
  try {
    console.log("imagine : ", userId, selectedEtabDetails.identifiant_etablissement)
    await CrudServiceFAPI.dissociateUserFromEstablishment(userId, selectedEtabDetails.identifiant_etablissement);
    // Rafraîchir la liste des utilisateursé
    const utilisateursData = await CrudServiceFAPI.getUtilisateursByEtablissement(
      selectedEtabDetails.identifiant_etablissement
    );
    setSelectedEtabDetails((prevDetails) => ({
      ...prevDetails,
      utilisateurs: utilisateursData,
    }));
    showNotification("Utilisateur supprimé avec succès.");
  } catch (error) {
    console.error("Erreur lors de la suppression de l'utilisateur :", error);
  }
};

const handleProfMatiereChange = async (event) => {
  const selectedMatiereId = event.target.value;
  console.log("handleProfMatiereChange called", selectedMatiereId);
  setSelectedMatiere(selectedMatiereId);

  // Reset droitsProf and checkboxValues while fetching new rights
  setDroitsProf(null);
  setCheckboxValues({});

  if (selectedUser && selectedMatiereId) {
    try {
      const droitsProf = await CrudServiceFAPI.getDroitsProfsMatieres(
        selectedUser.identifiant_utilisateur,
        selectedMatiereId
      );
      console.log("Droits récupérés pour le professeur :", droitsProf);

      setDroitsProf(droitsProf);
      setCheckboxValues(droitsProf);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // No rights found for this subject
        console.log("Aucun droit trouvé pour cette discipline");
        setDroitsProf(null); // Ensure droitsProf is null
        setCheckboxValues({});
      } else {
        console.error("Erreur lors de la récupération des droits pour la discipline :", error);
        // Handle other errors if necessary
        setDroitsProf(null);
        setCheckboxValues({});
        showNotification("Erreur lors de la récupération des droits pour la discipline");
      }
    }
  }
};


const roleLabels = {
  administrateur: "Administrateur",
  professeur: "Enseignant",
  eleve: "Apprenant",
  gestionnaire: "Coordonateur", // Remplace "gestionnaire" par "Coordonateur" pour l'affichage
  superadministrateur: "Superadministrateur",
};

const roles = [
  { key: "administrateur", label: roleLabels["administrateur"] },
  { key: "professeur", label: roleLabels["professeur"] },
  { key: "eleve", label: roleLabels["eleve"] },
  { key: "gestionnaire", label: roleLabels["gestionnaire"] }, // Utilise "gestionnaire" comme clé mais affiche "Coordonateur"
];


  // Fonction pour gérer la sélection de discipline
  const handleMatiereChange = async (event) => {
    const selectedMatiere = event.target.value;
    console.log("handleMatiereChange called", selectedMatiere); // Ajout d'un log pour vérifier si la fonction est bien appelée
    setSelectedMatiere(selectedMatiere);
    
    console.log("discipline sélectionnée (après setState):", selectedMatiere); // Log après la mise à jour de l'état
  
    if (selectedUser && selectedMatiere) {
      try {
        const droitsProf = await CrudServiceFAPI.getDroitsProfsMatieres(
          selectedUser.identifiant_utilisateur,
          selectedMatiere
        );
        console.log("Droits récupérés pour le professeur :", droitsProf);
  
        setDroitsProf(droitsProf);
        setCheckboxValues(droitsProf);
      } catch (error) {
        console.error("Erreur lors de la récupération des droits pour la discipline :", error);
      }
    }
  };
  
  
  

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxValues({ ...checkboxValues, [name]: checked ? 1 : 0 });
  };

 

  const handleSettingsClickGestionnaire = async (user) => {
    console.log("handleSettingsClick called with user:", user)
    setSelectedUser(user);
    setSelectedMatiere(""); // Reset selected matiere
    setDroitsProf(null); // Reset droitsProf
    setCheckboxValues({}); // Reset checkbox values
  
   
      try {
        const droitsUtilisateur = await CrudServiceFAPI.getUtilisateurEtablissement(
          user.identifiant_utilisateur,
          selectedEtabDetails.identifiant_etablissement
        );
        console.log("Droits récupérés pour le coordonateur :", selectedEtabDetails);
  
        // Initialize rights for the coordonateur
        setDroitsProf(droitsUtilisateur);
        setCheckboxValues(droitsUtilisateur);
        setOpenSettingsDialogGestionnaire(true); // Open the settings dialog
      } catch (error) {
        console.error("Erreur lors de la récupération des droits utilisateur_etablissement :", error);
      }
  
  
  };

  const handleSettingsClickProfesseur = async (user) => {
    console.log("handleSettingsClick called with user:", user)
    setSelectedUser(user);
    setSelectedMatiere(""); // Reset selected matiere
    setDroitsProf(null); // Reset droitsProf
    setCheckboxValues({}); // Reset checkbox values
  
    
      try {
        // Retrieve all subjects (disciplines) associated with the establishment
        const matieresData = await CrudServiceFAPI.getMatieresByEtab(
          selectedEtabDetails.identifiant_etablissement
        );
        console.log("Disciplines récupérées pour l'établissement :", matieresData);
  
        setMatieres(matieresData);
        setOpenSettingsDialogProfesseur(true); // Open the settings dialog
  
      } catch (error) {
        console.error("Erreur lors de la récupération des disciplines de l'établissement :", error);
      }
    
  };
  
   
  const handleResetPassword = async (email) => {
    try {
      // Appel à la méthode resetPassword dans votre service.
      await CrudServiceFAPI.resetPassword({ email });
      showNotification("Mot de passe réinitialisé et envoyé par email.");
    } catch (error) {
      console.error("Erreur lors de la réinitialisation du mot de passe :", error);
      showNotification("Erreur lors de la réinitialisation du mot de passe.");
    }
  };
  
 

  const handleSubmitRightsProfesseur = async () => {
    try {
    
      
   
        // Récupérer l'identifiant de la discipline sélectionnée
        const utilisateurData = {
          identifiant_utilisateur: selectedUser.identifiant_utilisateur,
          identifiant_matiere: selectedMatiere,
          ...checkboxValues
        };
        console.log("generation : ", utilisateurData)
        // Appeler la méthode pour mettre à jour les droits du professeur
        await CrudServiceFAPI.updateDroitsProfMatiere(selectedUser.identifiant_utilisateur, selectedMatiere, utilisateurData);
        console.log("Droits du professeur mis à jour :", utilisateurData);
      
      
      setOpenSettingsDialogProfesseur(false); // Fermer la popup après soumission
    } catch (error) {
      console.error("Erreur lors de la mise à jour des droits :", error);
    }
  };

  const handleSubmitRightsGestionnaire = async () => {
    try {
     
        // Construire l'objet avec les nouvelles valeurs des droits
        const utilisateurData = {
          identifiant_utilisateur: selectedUser.identifiant_utilisateur,
          identifiant_etablissement: selectedEtabDetails.identifiant_etablissement,
          ...checkboxValues // Utiliser les valeurs actuelles des cases à cocher
        };
      
        try {
          // Appeler la méthode pour mettre à jour les droits du coordonateur
          console.log("generation : ", utilisateurData)
          const response = await CrudServiceFAPI.updateUtilisateurEtablissement(selectedUser.identifiant_utilisateur, selectedEtabDetails.identifiant_etablissement, utilisateurData);
          console.log("response generation: ", response)  
          // Vérifier la réponse du serveur et afficher un message si nécessaire
          if (response) {
            console.log("Droits du coordonateur mis à jour :", utilisateurData);
            showNotification("Droits mis à jour avec succès pour le coordonateur.");
          } else {
            throw new Error("Erreur lors de la mise à jour des droits.");
          }
      
          // Fermer la popup après la mise à jour réussie
          setOpenSettingsDialogGestionnaire(false);
      
        } catch (error) {
          console.error("Erreur lors de la mise à jour des droits du coordonateur :", error);
          showNotification("Erreur lors de la mise à jour des droits.");
        }
      
      
      setOpenSettingsDialogGestionnaire(false); // Fermer la popup après soumission
    } catch (error) {
      console.error("Erreur lors de la mise à jour des droits :", error);
    }
  };
  
  console.log("yvan dort : ", matieres)


  
  const renderSettingsDialogProfesseur = () => (
    <Dialog open={openSettingsDialogProfesseur} onClose={() => setOpenSettingsDialogProfesseur(false)}>
      <DialogTitle>Modifier les droits de l'utilisateur</DialogTitle>
      <DialogContent>
        {selectedUser?.roles.includes("professeur") && (
          <>
            {matieres.length > 0 ? (
              <FormControl fullWidth margin="dense">
                <InputLabel id="matiere-label">Discipline</InputLabel>
                <Select
                  labelId="matiere-label"
                  value={selectedMatiere}
                  onChange={handleProfMatiereChange}
                >
                  {matieres.map((matiere) => (
                    <MenuItem key={matiere.identifiant_matiere} value={matiere.identifiant_matiere}>
                      {matiere.denomination_matiere}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <MDTypography variant="body2" color="error">
                Aucune discipline disponible pour cet établissement.
              </MDTypography>
            )}
          </>
        )}
  
        {/* Afficher les cases à cocher si des droits existent */}
        {droitsProf ? (
          <FormGroup>
            {Object.keys(droitsProf).map((key) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    checked={checkboxValues[key] === 1}
                    onChange={handleCheckboxChange}
                    name={key}
                  />
                }
                label={key.replace('_', ' ')}
              />
            ))}
          </FormGroup>
        ) : (
          // Proposer d'associer le professeur à la discipline si aucun droit n'existe
          selectedUser?.roles.includes("professeur") && selectedMatiere && (
            <MDButton onClick={handleSubmitMatiere} color="primary">
              Associer ce professeur à cette discipline
            </MDButton>
          )
        )}
      </DialogContent>
      <DialogActions>
        <MDButton onClick={() => setOpenSettingsDialogProfesseur(false)} color="secondary">
          Annuler
        </MDButton>
        {droitsProf && (
          <MDButton onClick={handleSubmitRightsProfesseur} color="primary">
            Valider
          </MDButton>
        )}
      </DialogActions>
    </Dialog>
  );

  const renderSettingsDialogGestionnaire = () => (
    <Dialog open={openSettingsDialogGestionnaire} onClose={() => setOpenSettingsDialogGestionnaire(false)}>
      <DialogTitle>Modifier les droits de l'utilisateur</DialogTitle>
      <DialogContent>
        {selectedUser?.roles.includes("professeur") && (
          <>
            {matieres.length > 0 ? (
              <FormControl fullWidth margin="dense">
                <InputLabel id="matiere-label">Discipline</InputLabel>
                <Select
                  labelId="matiere-label"
                  value={selectedMatiere}
                  onChange={handleProfMatiereChange}
                >
                  {matieres.map((matiere) => (
                    <MenuItem key={matiere.identifiant_matiere} value={matiere.identifiant_matiere}>
                      {matiere.denomination_matiere}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <MDTypography variant="body2" color="error">
                Aucune discipline disponible pour cet établissement.
              </MDTypography>
            )}
          </>
        )}
  
        {/* Afficher les cases à cocher si des droits existent */}
        {droitsProf ? (
          <FormGroup>
            {Object.keys(droitsProf).map((key) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    checked={checkboxValues[key] === 1}
                    onChange={handleCheckboxChange}
                    name={key}
                  />
                }
                label={key.replace('_', ' ')}
              />
            ))}
          </FormGroup>
        ) : (
          // Proposer d'associer le professeur à la discipline si aucun droit n'existe
          selectedUser?.roles.includes("professeur") && selectedMatiere && (
            <MDButton onClick={handleSubmitMatiere} color="primary">
              Associer ce professeur à cette discipline
            </MDButton>
          )
        )}
      </DialogContent>
      <DialogActions>
        <MDButton onClick={() => setOpenSettingsDialogGestionnaire(false)} color="secondary">
          Annuler
        </MDButton>
        {droitsProf && (
          <MDButton onClick={handleSubmitRightsGestionnaire} color="primary">
            Valider
          </MDButton>
        )}
      </DialogActions>
    </Dialog>
  );
  
  
  
  // Récupérer les sections par identifiant d'établissement
const fetchSectionsByEtab = async (etabId) => {
  try {
    const sectionsData = await CrudServiceFAPI.getSectionsByEtabId(etabId);
    setTableDataSections(sectionsData);
  } catch (error) {
    console.error("Erreur lors de la récupération des sections :", error);
  }
};

// Récupérer les promotions par identifiant de section
const handleSectionClick = async (identifiant_section) => {
  setSelectedSection(identifiant_section);  // Mémoriser la section sélectionnée
  try {
    const promotionsData = await CrudServiceFAPI.getPromotionsBySection(identifiant_section);
    console.log("Promotions chargées : ", promotionsData)
    setTableDataPromotions(promotionsData);
  } catch (error) {
    console.error("Erreur lors de la récupération des promotions :", error);
  }
};

// Récupérer les disciplines par identifiant de promotion
const handlePromotionClick = async (identifiant_promotion) => {
  setSelectedPromotion(identifiant_promotion);  // Mémoriser la promotion sélectionnée
  try {
    const matieresData = await CrudServiceFAPI.getMatieresByPromotion(identifiant_promotion);
    setTableDataMatieres(matieresData);
  } catch (error) {
    console.error("Erreur lors de la récupération des disciplines :", error);
  }
};


useEffect(() => {
  if (selectedPromotion) {
    fetchReferentielsByPromotion(selectedPromotion);
  }
}, [selectedPromotion]);
// Gérer le changement de section et charger les promotions associées
const handleSectionChange = async (event) => {
  const { value } = event.target;
  setNewUser({ ...newUser, identifiant_section: value, identifiant_promotion: "" });

  // Charger les promotions associées à la section sélectionnée
  try {
    const promotionsData = await CrudServiceFAPI.getPromotionsBySection(value);
    console.log("Promotions chargées : ", promotionsData)
    setPromotions(promotionsData);

  } catch (error) {
    console.error("Erreur lors de la récupération des promotions :", error);
  }
};

const handleEditSection = (section) => {
  setNewSection({
    denomination_section: section.denomination_section,
    detail_section: section.detail_section,
    identifiant_etablissement: selectedEtabDetails.identifiant_etablissement,
  });
  setEditingSection(section.identifiant_section); // Stocker l'ID de la section à modifier
  setOpenSectionDialog(true); // Ouvrir le formulaire pour éditer la section
};


const handleDeleteSection = async (sectionId) => {
  try {
    await CrudServiceFAPI.deleteSection(sectionId);
    showNotification("Section supprimée avec succès");
    fetchSectionsByEtab(selectedEtabDetails.identifiant_etablissement); // Recharger les sections après suppression
  } catch (error) {
    console.error("Erreur lors de la suppression de la section :", error);
  }
};

const handleEditPromotion = (promotion) => {
  setNewPromotion({
    denomination_promotion: promotion.denomination_promotion,
    identifiant_section: promotion.identifiant_section,
  });
  setSelectedReferentiel(promotion.identifiant_referentiel || ""); // Charger le  associé
  setEditingPromotion(promotion.identifiant_promotion); // Stocker l'ID de la promotion à modifier
  setOpenPromotionDialog(true); // Ouvrir le formulaire pour éditer la promotion
};


const handleDeletePromotion = async (promotionId) => {
  try {
    await CrudServiceFAPI.deletePromotion(promotionId);
    showNotification("Promotion supprimée avec succès");
    handleSectionClick(selectedSection); // Recharger les promotions après suppression
  } catch (error) {
    console.error("Erreur lors de la suppression de la promotion :", error);
  }
};

const promotionTableColumns = () => [
  { Header: "Identifiant Promotion", accessor: "identifiant_promotion" },
  {
    Header: "Dénomination Promotion",
    accessor: "denomination_promotion",
    Cell: ({ cell: { value }, row }) => (
      <MDTypography
        variant="button"
        color="info"
        onClick={() => handlePromotionClick(row.original.identifiant_promotion)}
        style={{ cursor: "pointer" }}
      >
        {value}
      </MDTypography>
    ),
  },
  {
    Header: "Identifiant ",
    accessor: "identifiant_referentiel",
    Cell: ({ cell: { value } }) => (
      <MDTypography variant="button" color="text">
        {value || "Aucun"}
      </MDTypography>
    ),
  },
  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ row }) => (
      <MDBox display="flex" alignItems="center">
        { ability.can("edit", "promotion") && (
          <IconButton onClick={() => handleEditPromotion(row.original)}>
            <EditIcon />
          </IconButton>
        )}
        {/* Bouton Edit */}
       { ability.can("delete", "promotion") && (
          <IconButton onClick={() => handleDeletePromotion(row.original.identifiant_promotion)}>
            <DeleteIcon />
          </IconButton>
        )}
        
      </MDBox>
    ),
  },
];



// Fonction pour soumettre une nouvelle discipline associée à une promotion
const handleSubmitMatierePromotion = async () => {
  if (!newMatiere.denomination_matiere || !newMatiere.detail_matiere || !selectedPromotion) {
    showNotification("Veuillez remplir tous les champs obligatoires.");
    return;
  }

  try {
    const payload = {
      denomination_matiere: newMatiere.denomination_matiere,
      detail_matiere: newMatiere.detail_matiere,
      identifiant_promotion: selectedPromotion, // Associer à la promotion sélectionnée
    };

    if (editingMatiere) {
      // Mettre à jour une discipline existante
      await CrudServiceFAPI.updateMatiere(editingMatiere, payload);
      showNotification("Discipline mise à jour avec succès");
    } else {
      // Ajouter une nouvelle discipline
      await CrudServiceFAPI.createMatiere(payload);
      showNotification("Discipline créée avec succès");
    }

    // Recharger les disciplines après la création ou mise à jour
    handlePromotionClick(selectedPromotion);
    setOpenMatiereDialog(false); // Fermer la boîte de dialogue
    resetMatiereForm(); // Réinitialiser le formulaire
    setEditingMatiere(null); // Réinitialiser l'édition
  } catch (error) {
    console.error("Erreur lors de l'ajout ou de la mise à jour de la discipline :", error);
    showNotification("Erreur lors de l'ajout ou de la mise à jour de la discipline.");
  }
};

const sectionTableColumns = () => [
  { Header: "Identifiant Section", accessor: "identifiant_section" },
  {
    Header: "Dénomination Section",
    accessor: "denomination_section",
    Cell: ({ cell: { value }, row }) => (
      <MDTypography
        variant="button"
        color="info"
        onClick={() => handleSectionClick(row.original.identifiant_section)}
        style={{ cursor: "pointer" }}
      >
        {value}
      </MDTypography>
    ),
  },
  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ row }) => (
      <MDBox display="flex" alignItems="center">
        {ability.can("edit", "section") && (
        <IconButton onClick={() => handleEditSection(row.original)}>
          <EditIcon />
        </IconButton>
        )}
        {ability.can("delete", "section") && (
        <IconButton onClick={() => handleDeleteSection(row.original.identifiant_section)}>
          <DeleteIcon />
        </IconButton>
        )}
      </MDBox>
    ),
  },
];

const [openAssociateUserDialog, setOpenAssociateUserDialog] = useState(false);
const [allUsers, setAllUsers] = useState([]); // pour stocker tous les utilisateurs
const [selectedUserToAssociate, setSelectedUserToAssociate] = useState(""); // utilisateur sélectionné pour l'association

const fetchAllUsers = async () => {
  try {
    const users = await CrudServiceFAPI.getAllUsers();
    setAllUsers(users);
  } catch (error) {
    console.error("Erreur lors de la récupération des utilisateurs :", error);
  }
};

const handleAssociateUser = async () => {
  try {
    await CrudServiceFAPI.associateUserToEstablishment(selectedUserToAssociate, selectedEtabDetails.identifiant_etablissement);
    showNotification("Utilisateur associé avec succès à l'établissement");
    setOpenAssociateUserDialog(false);
    // Recharger les utilisateurs de l'établissement après l'association
    const utilisateursData = await CrudServiceFAPI.getUtilisateursByEtablissement(selectedEtabDetails.identifiant_etablissement);
    setSelectedEtabDetails((prevDetails) => ({
      ...prevDetails,
      utilisateurs: utilisateursData,
    }));
  } catch (error) {
    console.error("Erreur lors de l'association de l'utilisateur :", error);
    showNotification("Erreur lors de l'association de l'utilisateur");
  }
};

const [allDatabaseUsers, setAllDatabaseUsers] = useState([]);
// Fonction pour charger tous les utilisateurs de la BDD
const fetchAllDatabaseUsers = async () => {
  try {
    const users = await CrudServiceFAPI.getAllUsers();
    setAllDatabaseUsers(users);
  } catch (error) {
    console.error("Erreur lors de la récupération de tous les utilisateurs :", error);
  }
};

// Charger les utilisateurs de la BDD au montage du composant
useEffect(() => {
  fetchAllDatabaseUsers();
}, []);

// Colonnes pour la table de gestion des utilisateurs de la BDD
const allUsersTableColumns = () => [
  { Header: "Identifiant", accessor: "identifiant_utilisateur" },
  { Header: "Nom", accessor: "nom_utilisateur" },
  { Header: "Prénom", accessor: "prenom_utilisateur" },
  { Header: "Email", accessor: "email_utilisateur" },
  { Header: "Date de création", accessor: "date_creation" },
  {
    Header: "Actions",
    accessor: "actions",
    Cell: ({ row }) => (
      <MDBox display="flex" alignItems="center">
        <IconButton onClick={() => handleEditUser(row.original)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => handleResetPassword(row.original.email_utilisateur)}>
          <RestartAltIcon />
        </IconButton>
        <IconButton onClick={() => handleDeleteUser(row.original.identifiant_utilisateur)}>
          <DeleteIcon />
        </IconButton>
      </MDBox>
    ),
  },
];


const [openUserForm, setOpenUserForm] = useState(false);
const [editingUser, setEditingUser] = useState(null);
const [newUser, setNewUser] = useState({
  prenom_utilisateur: "",
  nom_utilisateur: "",
  email_utilisateur: "",
  
  role: ""
});



// Fonction pour gérer la soumission du formulaire
const handleUserFormSubmit = async () => {
  try {
    if (editingUser) {
      // Mettre à jour l'utilisateur existant
      await CrudServiceFAPI.updateUtilisateur(editingUser.identifiant_utilisateur, newUser);
      setNotification({ value: true, text: "Utilisateur mis à jour avec succès" });
    } else {
      // Ajouter un nouvel utilisateur
      const { prenom_utilisateur, nom_utilisateur, email_utilisateur,  role } = newUser;

      // Vérifier si l'utilisateur est ajouté à un établissement
      const isAddingToEtab = selectedEtabDetails && selectedEtabDetails.identifiant_etablissement && role;

      if (isAddingToEtab) {
        // Ajouter à un établissement avec rôle
        await CrudServiceFAPI.createUtilisateur(
          {
            prenom_utilisateur,
            nom_utilisateur,
            email_utilisateur,
            role
          },
          selectedEtabDetails.identifiant_etablissement,
          role
        );
      } else {
        // Ajouter globalement sans établissement et rôle
        await CrudServiceFAPI.createUtilisateur({
          prenom_utilisateur,
          nom_utilisateur,
          email_utilisateur,
        });
      }

      setNotification({ value: true, text: "Utilisateur ajouté avec succès" });
    }

    // Rafraîchir les listes d'utilisateurs en fonction du contexte
    if (selectedEtabDetails && selectedEtabDetails.identifiant_etablissement) {
      const utilisateursData = await CrudServiceFAPI.getUtilisateursByEtablissement(
        selectedEtabDetails.identifiant_etablissement
      );
      setSelectedEtabDetails((prevDetails) => ({
        ...prevDetails,
        utilisateurs: utilisateursData,
      }));
    } else {
      // Rafraîchir tous les utilisateurs si ajout global
      fetchAllDatabaseUsers();
    }

    // Réinitialiser le formulaire
    setOpenUserForm(false);
    setEditingUser(null);
    setNewUser({
      prenom_utilisateur: "",
      nom_utilisateur: "",
      email_utilisateur: "",
      role: "",
    });
  } catch (error) {
    console.error("Erreur lors de la soumission du formulaire d'utilisateur :", error);
    showNotification("Erreur lors de la soumission du formulaire d'utilisateur.");
  }
};



// Fonction pour gérer l'édition d'un utilisateur
const handleEditUser = (user) => {
  setEditingUser(user);
  setNewUser({
    prenom_utilisateur: user.prenom_utilisateur,
    nom_utilisateur: user.nom_utilisateur,
    email_utilisateur: user.email_utilisateur,
  });
  setOpenUserForm(true);
};

// Fonction pour gérer la suppression d'un utilisateur
const handleDeleteUser = async (userId) => {
  try {
    await CrudServiceFAPI.deleteUtilisateur(userId);
    setNotification({ value: true, text: "Utilisateur supprimé avec succès" });
    fetchAllDatabaseUsers(); // Rafraîchir la liste des utilisateurs
  } catch (error) {
    console.error("Erreur lors de la suppression de l'utilisateur :", error);
  }
};

const handleRoleChange = (event, userId) => {
  const newRoles = event.target.value; // Array of selected roles
  updateRoleUser(userId, newRoles);
};


// Fonction pour mettre à jour l'interface après modification du rôle
const updateRoleUser = async (userId, newRoles) => {
  try {
    await crudsServiceFAPI.updateRoleUser(
      userId,
      newRoles,
      selectedEtabDetails.identifiant_etablissement
    );

    // Update local state to reflect the role changes
    setSelectedEtabDetails((prevDetails) => ({
      ...prevDetails,
      utilisateurs: prevDetails.utilisateurs.map((user) =>
        user.identifiant_utilisateur === userId
          ? { ...user, roles: newRoles }
          : user
      ),
    }));
  } catch (error) {
    console.error("Error updating user roles:", error);
  }
};


console.log("chaudes : ", selectedEtabDetails)

const eleveTableColumns = () => [
  { Header: "Identifiant", accessor: "identifiant_utilisateur" },
  { Header: "Nom", accessor: "nom_utilisateur" },
  { Header: "Prénom", accessor: "prenom_utilisateur" },
  { Header: "Email", accessor: "email_utilisateur" },

  {
    Header: "Promotion",
    accessor: "identifiant_promotion", // Utiliser l'identifiant de la promotion
    Cell: ({ row }) => {
      const { identifiant_utilisateur, identifiant_promotion } = row.original;
      
      const handleChangePromotion = async (event) => {
        const newPromotionId = event.target.value;
        try {
          await CrudServiceFAPI.updateElevePromotion(identifiant_utilisateur, newPromotionId);
          showNotification("Promotion mise à jour avec succès");

          // Mettre à jour l'état local pour refléter le changement
          setSelectedEtabDetails((prevDetails) => ({
            ...prevDetails,
            utilisateurs: prevDetails.utilisateurs.map((user) =>
              user.identifiant_utilisateur === identifiant_utilisateur
                ? { 
                    ...user, 
                    identifiant_promotion: newPromotionId, 
                    denomination_promotion: tableDataPromotions.find(p => p.identifiant_promotion === newPromotionId)?.denomination_promotion || ""
                  }
                : user
            ),
          }));
        } catch (error) {
          console.error("Erreur lors de la mise à jour de la promotion:", error);
          showNotification("Erreur lors de la mise à jour de la promotion");
        }
      };

    
      

      return (
        <Select
          value={identifiant_promotion || ""}
          onChange={handleChangePromotion}
          fullWidth
        >
          {tableDataPromotions.map((promotion) => (
            <MenuItem
              key={promotion.identifiant_promotion}
              value={promotion.identifiant_promotion}
            >
              {promotion.denomination_promotion}
            </MenuItem>
          ))}
        </Select>
      );
    },
  },
];

const [openAddUtilisateurDialog, setOpenAddUtilisateurDialog] = useState(false);
const [newUtilisateur, setNewUtilisateur] = useState({
  prenom_utilisateur: "",
  nom_utilisateur: "",
  email_utilisateur: "",
  
  role: "",
});
const [rolesList, setRolesList] = useState([]);

useEffect(() => {
  const predefinedRoles = [
    { label: roleLabels["gestionnaire"], key: "gestionnaire" }, // "Coordonateur"
    { label: roleLabels["professeur"], key: "professeur" },
    { label: roleLabels["eleve"], key: "eleve" },
    // Ajoutez d'autres rôles si nécessaire
  ];

  // Filtrer les rôles en fonction des permissions de l'utilisateur
  const filteredRoles = predefinedRoles.filter(roleItem =>
    ability.can("create", roleItem.key)
  );

  setRolesList(filteredRoles);

  console.log("Filtered Roles:", filteredRoles);
}, [ability]);

const handleAddUtilisateurSubmit = async () => {
  if (
    !newUtilisateur.prenom_utilisateur ||
    !newUtilisateur.nom_utilisateur ||
    !newUtilisateur.email_utilisateur ||
    !newUtilisateur.role
  ) {
    showNotification("Veuillez remplir tous les champs du formulaire.");
    return;
  }

  try {
    await CrudServiceFAPI.createUtilisateur(
      newUtilisateur,
      selectedEtabDetails.identifiant_etablissement,
      newUtilisateur.role
    );
    showNotification("Utilisateur ajouté avec succès.");
    setOpenAddUtilisateurDialog(false);
    setNewUtilisateur({
      prenom_utilisateur: "",
      nom_utilisateur: "",
      email_utilisateur: "",
      
      role: "",
    });
    // Rafraîchir la liste des utilisateurs de l'établissement
    const utilisateursData = await CrudServiceFAPI.getUtilisateursByEtablissement(
      selectedEtabDetails.identifiant_etablissement
    );
    setSelectedEtabDetails((prevDetails) => ({
      ...prevDetails,
      utilisateurs: utilisateursData,
    }));
  } catch (error) {
    console.error("Erreur lors de l'ajout de l'utilisateur :", error);
    showNotification("Erreur lors de l'ajout de l'utilisateur.");
  }};



  return (
    <DashboardLayout>
      <DashboardNavbar />
      {notification.value && (
        <MDAlert color="info" my="20px">
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}
     {/* Nouvelle section pour afficher tous les utilisateurs de la BDD */}
{ability.can("manage", "all") &&(
     <MDBox mb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h4" fontWeight="medium" color="info">
              Gestion des utilisateurs
            </MDTypography>
            <MDButton
              variant="gradient"
              color="dark"
              size="small"
              onClick={() => {
                setOpenUserForm(true);
                setEditingUser(null);
                setNewUser({
                  prenom_utilisateur: "",
                  nom_utilisateur: "",
                  email_utilisateur: "",
                  
                });
              }}
            >
              + Ajouter un utilisateur
            </MDButton>
            <DataTable table={{ columns: allUsersTableColumns(), rows: allDatabaseUsers }} />
          </MDBox>
        </Card>
      </MDBox>
)}

      <MDBox pt={6} pb={3}>
     
        <MDBox mb={3}>
        <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Etablissement
              </MDTypography>
              {ability.can("manage", "all") &&(
              <MDButton
                variant="gradient"
                color="dark"
                size="small"
                onClick={() => {
                  setNewEtab({ denomination_etablissement: "" });
                  setEditingEtab(null);
                  setOpenEtabForm(true);
                }}
              >
                + Ajouter un établissement
              </MDButton>
              )}
            </MDBox>
            <DataTable table={{ columns: etabTableColumns(), rows: tableDataEtab }} />
          </Card>
        </MDBox>

        {showEtabDetails && selectedEtabDetails && (
  <MDBox mb={3} ref={sectionRef} >
    <Card>
      <MDBox p={3} lineHeight={1}>
        {/* Gestion des Sections */}
        <MDTypography variant="h5" fontWeight="medium" color="info">
          Sections
        </MDTypography>
        {ability.can("create", "section") && (
        <MDButton
          variant="gradient"
          color="dark"
          size="small"
          onClick={handleAddSection}
        >
          + Ajouter une section
        </MDButton>
        )}
        <DataTable
          table={{
            columns: sectionTableColumns(),
            rows: tableDataSections,
          }}
        />

        {/* Gestion des Promotions */}
        {selectedSection && (
          <>
            <MDTypography variant="h5" fontWeight="medium" color="info">
              Promotions
            </MDTypography>
            {ability.can("create", "promotion") && (
            <MDButton
              variant="gradient"
              color="dark"
              size="small"
              onClick={handleAddPromotion}
            >
              + Ajouter une promotion
            </MDButton>)}
            <DataTable
              table={{
                columns: promotionTableColumns(),
                rows: tableDataPromotions,
              }}
            />
          </>
        )}


        {/* Gestion des Disciplines */}
        {selectedPromotion && (
          <>
            <MDTypography variant="h5" fontWeight="medium" color="info">
              Disciplines
            </MDTypography>
            { ability.can("create", "matiere") && (
            <MDButton
              variant="gradient"
              color="dark"
              size="small"
              onClick={handleAddMatiere}
            >
              + Ajouter une discipline
            </MDButton>)}
            <DataTable
              table={{
                columns: [
                  { Header: "Identifiant", accessor: "identifiant_matiere" },
                  { Header: "Dénomination", accessor: "denomination_matiere" },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    Cell: ({ row }) => (
                      <MDBox display="flex" alignItems="center">
                        {ability.can("edit", "matiere") && (
                          <IconButton onClick={() => handleEditMatiere(row.original)}>
                            <EditIcon />
                          </IconButton>
                        )}
                        {ability.can("delete", "matiere") && (
                        <IconButton onClick={() => handleDeleteMatiere(row.original.identifiant_matiere)}>
                          <DeleteIcon />
                        </IconButton>
                        )}
                      </MDBox>
                    ),
                  },
                ],
                rows: tableDataMatieres,
              }}
            />
          </>
        )}
      </MDBox>
    </Card>
  </MDBox>
)}

        {showEtabDetails && selectedEtabDetails && (
  <MDBox mb={3} ref={userSectionRef}>
    <Card>
      <MDBox p={3} lineHeight={1}>
        <MDTypography variant="h4" fontWeight="medium">
          Gestion de l'établissement : {selectedEtabDetails.identifiant_etablissement}
        </MDTypography>
        {ability.can("manage", "all") && (
        <MDButton
  variant="gradient"
  color="dark"
  size="small"
  onClick={() => {
    setOpenAssociateUserDialog(true);
    fetchAllUsers(); // Charger tous les utilisateurs lors de l'ouverture de la boîte de dialogue
  }}
>
  Associer un utilisateur
</MDButton>)}
{ability.can("create", "utilisateurs_etablissement") && (
<MDButton
  variant="gradient"
  color="dark"
  size="small"
  onClick={() => {
    setOpenAddUtilisateurDialog(true);
   //</MDBox> fetchRoles(); // Charger les rôles lors de l'ouverture du dialogue
  }}
>
  + Ajouter un utilisateur
</MDButton>
)}


        {/* Nouvelle section : Utilisateurs de l'établissement */}
        <MDBox mt={6}>
  <MDTypography variant="h4" fontWeight="medium" color="info">
    Utilisateurs de l'établissement
  </MDTypography>
  <DataTable
table = {{
  columns: [
    { Header: "Identifiant", accessor: "identifiant_utilisateur" },
    { Header: "Nom", accessor: "nom_utilisateur" },
    { Header: "Prénom", accessor: "prenom_utilisateur" },
    { Header: "Email", accessor: "email_utilisateur" },
    {
      Header: "Rôles",
      accessor: "roles",
      Cell: ({ row }) => {
        const isAdminOrSuperAdmin = (row.original.roles.includes("administrateur") || row.original.roles.includes("superadministrateur")) && role !== "superadministrateur";
    
        return (
          <MDBox>
            <Select
              multiple
              value={row.original.roles}
              onChange={(e) => {
                let selectedRoles = e.target.value;
    
                if (selectedRoles.includes("indefini")) {
                  if (selectedRoles.length > 1) {
                    selectedRoles = selectedRoles.filter((role) => role !== "indefini");
                  } else {
                    selectedRoles = ["indefini"];
                  }
                } else if (selectedRoles.length === 0) {
                  selectedRoles = ["indefini"];
                }
    
                const filteredRoles = selectedRoles.filter((role) => {
                  if (role === "administrateur" && !ability.can("manage", "all")) return false;
                  if (role === "gestionnaire" && !ability.can("edit", "gestionnaire")) return false;
                  if (role === "professeur" && !ability.can("edit", "professeur")) return false;
                  if (role === "eleve" && !ability.can("edit", "eleve")) return false;
                  return true;
                });
    
                handleRoleChange(
                  { target: { value: filteredRoles } },
                  row.original.identifiant_utilisateur
                );
              }}
              variant="outlined"
              renderValue={(selected) => selected.map(role => roleLabels[role]).join(", ")}
              disabled={isAdminOrSuperAdmin}
            >
              <MenuItem value="indefini" disabled={!ability.can("manage", "all")}>
                Indéfini
              </MenuItem>
              <MenuItem value="administrateur" disabled={!ability.can("manage", "all")}>
                {roleLabels["administrateur"]}
              </MenuItem>
              <MenuItem value="superadministrateur" disabled={!ability.can("manage", "all")}>
                {roleLabels["superadministrateur"]}
              </MenuItem>
              <MenuItem value="gestionnaire" disabled={!ability.can("edit", "gestionnaire")}>
                {roleLabels["gestionnaire"]}
              </MenuItem>
              <MenuItem value="professeur" disabled={!ability.can("edit", "professeur")}>
                {roleLabels["professeur"]}
              </MenuItem>
              <MenuItem value="eleve" disabled={!ability.can("edit", "eleve")}>
                {roleLabels["eleve"]}
              </MenuItem>
            </Select>
        
          </MDBox>
        );
      },
    },
    
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => {
        // Redéfinir `isAdminOrSuperAdmin` ici pour la colonne Action
        const isAdminOrSuperAdmin = (row.original.roles.includes("administrateur") || row.original.roles.includes("superadministrateur")) && role !== "superadministrateur";

        return (
          
          <MDBox>

            {/* Bouton Edit */}
      {ability.can("edit", "utilisateurs_etablissement") && (
        <IconButton onClick={() => handleEditUser(row.original)}>
          <EditIcon />
        </IconButton>
      )}
            
            {ability.can("manage", "all") &&(
            <IconButton
              onClick={() => handleDissociateUser(row.original.identifiant_utilisateur)}
              disabled={isAdminOrSuperAdmin}
            >
              <ContentCutIcon /> {/* Assurez-vous d'importer cette icône depuis Material-UI */}
            </IconButton>
            )}
            {ability.can("delete", "utilisateurs_etablissement") &&(
            <IconButton
              onClick={() => handleDeleteUser(row.original.identifiant_utilisateur)}
              disabled={isAdminOrSuperAdmin}
            >
              <DeleteIcon />
            </IconButton>
            )}
          </MDBox>
        );
      },
    },
  ],
  rows: selectedEtabDetails.utilisateurs,
}
}








  
  />
</MDBox>


        {/* Autres sections pour gérer les administrateurs, professeurs, coordonateurs, et élèves */}
        {/* ... */}
      </MDBox>
    </Card>
  </MDBox>
)}



{showEtabDetails && selectedEtabDetails && (
  <MDBox mb={3} >
    <Card>
      <MDBox p={3} lineHeight={1}>
        <MDTypography variant="h4" fontWeight="medium">
          Gestion des utilisateurs de l'établissement :{" "}
          {selectedEtabDetails.identifiant_etablissement}
        </MDTypography>

        {/* Section des administrateurs */}
        <MDBox mt={3}>
          <MDTypography variant="h5" fontWeight="medium" color="info">
            Administrateurs
          </MDTypography>
          <DataTable
            table={{
              columns: [
                { Header: "Identifiant", accessor: "identifiant_utilisateur" },
                { Header: "Nom", accessor: "nom_utilisateur" },
                { Header: "Prénom", accessor: "prenom_utilisateur" },
                { Header: "Email", accessor: "email_utilisateur" },
              ],
              rows: selectedEtabDetails.utilisateurs.filter(
                (user) => user.roles.includes("administrateur")
              ),
            }}
          />
        </MDBox>

        {/* Section des professeurs */}
        <MDBox mt={3}>
          <MDTypography variant="h5" fontWeight="medium" color="info">
            Enseignants
          </MDTypography>
          <DataTable
            table={{
              columns: [
                { Header: "Identifiant", accessor: "identifiant_utilisateur" },
                { Header: "Nom", accessor: "nom_utilisateur" },
                { Header: "Prénom", accessor: "prenom_utilisateur" },
                { Header: "Email", accessor: "email_utilisateur" },
                {
                  Header: "Actions",
                  accessor: "",
                  Cell: ({ row }) => (
                    
                    <MDBox display="flex" alignItems="center">
                      {ability.can("reglages","professeur") && (
                      <IconButton onClick={() => handleSettingsClickProfesseur(row.original)}>
                        <SettingsIcon />
                      </IconButton>
                      )}
                    </MDBox>
                    
                  ),
                },
              ],
              rows: selectedEtabDetails.utilisateurs.filter(
                (user) => user.roles.includes("professeur")
              ),
            }}
          />
        </MDBox>

        {/* Section des coordonateurs */}
        <MDBox mt={3}>
          <MDTypography variant="h5" fontWeight="medium" color="info">
            Coordonateurs
          </MDTypography>
          <DataTable
            table={{
              columns: [
                { Header: "Identifiant", accessor: "identifiant_utilisateur" },
                { Header: "Nom", accessor: "nom_utilisateur" },
                { Header: "Prénom", accessor: "prenom_utilisateur" },
                { Header: "Email", accessor: "email_utilisateur" },
                {
                  Header: "Actions",
                  accessor: "",
                  Cell: ({ row }) => (
                    <MDBox display="flex" alignItems="center">
                       {ability.can("reglages","gestionnaire") && (
                      <IconButton onClick={() => handleSettingsClickGestionnaire(row.original)}>
                        <SettingsIcon />
                      </IconButton>
                       )}
                    </MDBox>
                  ),
                },
              ],
              rows: selectedEtabDetails.utilisateurs.filter(
                (user) => user.roles.includes("gestionnaire")
              ),
            }}
          />
        </MDBox>

        {/* Section des élèves */}
        <MDBox mt={3}>
          <MDTypography variant="h5" fontWeight="medium" color="info">
            Apprenants
          </MDTypography>
          <DataTable
            table={{
              columns: eleveTableColumns(),
              rows: selectedEtabDetails.utilisateurs.filter(
                (user) => user.roles.includes("eleve")
              ),
            }}
          />
        </MDBox>
      </MDBox>
    </Card>
  </MDBox>
)}

       


        {renderSettingsDialogProfesseur()}
        {renderSettingsDialogGestionnaire()}

      
        <Dialog open={openSectionDialog} onClose={() => { setOpenSectionDialog(false); resetSectionForm(); }}>
  <DialogTitle>Ajouter une section</DialogTitle>
  <DialogContent>
    <TextField
      label="Dénomination de la section"
      value={newSection.denomination_section}
      onChange={(e) => setNewSection({ ...newSection, denomination_section: e.target.value })}
      fullWidth
    />
    <TextField
      label="Détail de la section"
      value={newSection.detail_section}
      onChange={(e) => setNewSection({ ...newSection, detail_section: e.target.value })}
      fullWidth
    />
  </DialogContent>
  <DialogActions>
    <MDButton onClick={() => { setOpenSectionDialog(false); resetSectionForm(); }} color="secondary">
      Annuler
    </MDButton>
    <MDButton onClick={() => { handleSubmitSection(); resetSectionForm(); setOpenSectionDialog(false); }} color="primary">
      Ajouter
    </MDButton>
  </DialogActions>
</Dialog>


{/* Boîte de dialogue pour ajouter une promotion */}
{/* Boîte de dialogue pour ajouter ou modifier une promotion */}
{/* Boîte de dialogue pour ajouter ou modifier une promotion */}
<Dialog
  open={openPromotionDialog}
  onClose={() => {
    setOpenPromotionDialog(false);
    resetPromotionForm();
  }}
>
  <DialogTitle>{editingPromotion ? "Modifier la promotion" : "Ajouter une promotion"}</DialogTitle>
  <DialogContent>
    <TextField
      label="Dénomination de la promotion"
      value={newPromotion.denomination_promotion}
      onChange={(e) => setNewPromotion({ ...newPromotion, denomination_promotion: e.target.value })}
      fullWidth
    />
  </DialogContent>
  <DialogActions>
    <MDButton onClick={() => { setOpenPromotionDialog(false); resetPromotionForm(); setSelectedReferentiel("") }} color="secondary">
      Annuler
    </MDButton>
    <MDButton onClick={() => { handleSubmitPromotion(); resetPromotionForm(); }} color="primary">
      {editingPromotion ? "Mettre à jour" : "Ajouter"}
    </MDButton>
  </DialogActions>
</Dialog>


{/* Boîte de dialogue pour ajouter une discipline */}
<Dialog open={openMatiereDialog} onClose={() => { setOpenMatiereDialog(false); resetMatiereForm(); }}>
  <DialogTitle>{editingMatiere ? "Modifier la discipline" : "Ajouter une discipline"}</DialogTitle>
  <DialogContent>
    <TextField
      label="Dénomination de la discipline"
      value={newMatiere.denomination_matiere}
      onChange={(e) => setNewMatiere({ ...newMatiere, denomination_matiere: e.target.value })}
      fullWidth
    />
    <TextField
      label="Détail de la discipline"
      value={newMatiere.detail_matiere}
      onChange={(e) => setNewMatiere({ ...newMatiere, detail_matiere: e.target.value })}
      fullWidth
    />
  </DialogContent>
  <DialogActions>
    <MDButton onClick={() => { setOpenMatiereDialog(false); resetMatiereForm(); }} color="secondary">
      Annuler
    </MDButton>
    <MDButton onClick={handleSubmitMatierePromotion} color="primary">
      {editingMatiere ? "Mettre à jour" : "Ajouter"}
    </MDButton>
  </DialogActions>
</Dialog>




<Dialog open={openSectionDialog} onClose={() => { setOpenSectionDialog(false); resetSectionForm(); }}>
  <DialogTitle>{editingSection ? "Modifier la section" : "Ajouter une section"}</DialogTitle>
  <DialogContent>
    <TextField
      label="Dénomination de la section"
      value={newSection.denomination_section}
      onChange={(e) => setNewSection({ ...newSection, denomination_section: e.target.value })}
      fullWidth
    />
    <TextField
      label="Détail de la section"
      value={newSection.detail_section}
      onChange={(e) => setNewSection({ ...newSection, detail_section: e.target.value })}
      fullWidth
    />
  </DialogContent>
  <DialogActions>
    <MDButton onClick={() => { setOpenSectionDialog(false); resetSectionForm(); }} color="secondary">
      Annuler
    </MDButton>
    <MDButton onClick={() => { handleSubmitSection(); resetSectionForm(); }} color="primary">
      {editingSection ? "Mettre à jour" : "Ajouter"}
    </MDButton>
  </DialogActions>
</Dialog>
{/* Boîte de dialogue pour ajouter ou modifier un établissement */}
<Dialog
  open={openEtabForm}
  onClose={() => {
    setOpenEtabForm(false);
    setEditingEtab(null);
    setNewEtab({ identifiant_etablissement: "", denomination_etablissement: "" });
  }}
>
<DialogTitle>{editingEtab ? "Modifier l'établissement" : "Ajouter un établissement"}</DialogTitle>
<DialogContent>
  <TextField
    label="Identifiant de l'établissement"
    value={newEtab.identifiant_etablissement}
    onChange={(e) => setNewEtab({ ...newEtab, identifiant_etablissement: e.target.value })}
    fullWidth
    disabled={!!editingEtab} // Désactiver l'édition de l'identifiant lors de la modification
  />
  <TextField
    label="Dénomination de l'établissement"
    value={newEtab.denomination_etablissement}
    onChange={(e) => setNewEtab({ ...newEtab, denomination_etablissement: e.target.value })}
    fullWidth
  />
  <TextField
    label="Détail de l'établissement" // Ajout du champ "Détail de l'établissement"
    value={newEtab.detail_etablissement || ""}
    onChange={(e) => setNewEtab({ ...newEtab, detail_etablissement: e.target.value })}
    fullWidth
  />
</DialogContent>

  <DialogActions>
    <MDButton
      onClick={() => {
        setOpenEtabForm(false);
        setEditingEtab(null);
        setNewEtab({ identifiant_etablissement: "", denomination_etablissement: "" });
      }}
      color="secondary"
    >
      Annuler
    </MDButton>
    <MDButton onClick={handleSubmitEtab} color="primary">
      {editingEtab ? "Mettre à jour" : "Ajouter"}
    </MDButton>
  </DialogActions>
</Dialog>

 {/* Dialogues d'ajout pour section et promotion */}
 <Dialog open={openSectionDialog} onClose={() => setOpenSectionDialog(false)}>
        <DialogTitle>Ajouter une section</DialogTitle>
        <DialogContent>
          <TextField
            label="Dénomination de la section"
            value={newSection.denomination_section}
            onChange={(e) => setNewSection({ ...newSection, denomination_section: e.target.value })}
            fullWidth
          />
          <TextField
            label="Détail de la section"
            value={newSection.detail_section}
            onChange={(e) => setNewSection({ ...newSection, detail_section: e.target.value })}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => setOpenSectionDialog(false)} color="secondary">
            Annuler
          </MDButton>
          <MDButton onClick={handleSubmitSection} color="primary">
            Ajouter
          </MDButton>
        </DialogActions>
      </Dialog>

<Dialog
  open={openPromotionDialog}
  onClose={() => {
    setOpenPromotionDialog(false);
    resetPromotionForm();
  }}
>
  <DialogTitle>{editingPromotion ? "Modifier la promotion" : "Ajouter une promotion"}</DialogTitle>
  <DialogContent>
    {/* Champ pour la dénomination de la promotion */}
    <TextField
      label="Dénomination de la promotion"
      value={newPromotion.denomination_promotion}
      onChange={(e) => setNewPromotion({ ...newPromotion, denomination_promotion: e.target.value })}
      fullWidth
    />

    {/* Liste déroulante pour sélectionner un  */}
    <FormControl fullWidth margin="dense" style={{ marginTop: "20px" }}>
      <InputLabel id="referentiel-label"></InputLabel>
      <Select
        labelId="referentiel-label"
        value={selectedReferentiel}
        onChange={(e) => setSelectedReferentiel(e.target.value)}
      >
        {allReferentiels.map((referentiel) => (
          <MenuItem
            key={referentiel.identifiant_referentiel}
            value={referentiel.identifiant_referentiel}
          >
            {referentiel.denomination_referentiel}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </DialogContent>
  <DialogActions>
    <MDButton
      onClick={() => {
        setOpenPromotionDialog(false);
        resetPromotionForm();
        setSelectedReferentiel(""); // Réinitialiser le  sélectionné
      }}
      color="secondary"
    >
      Annuler
    </MDButton>
    <MDButton
      onClick={async () => {
        await handleSubmitPromotion(); // Créer la promotion avec le  sélectionné
        resetPromotionForm(); // Réinitialiser le formulaire
        setSelectedReferentiel(""); // Réinitialiser le  sélectionné
      }}
      color="primary"
    >
      {editingPromotion ? "Mettre à jour" : "Ajouter"}
    </MDButton>
  </DialogActions>
</Dialog>


      <Dialog open={openReferentielDialog} onClose={() => setOpenReferentielDialog(false)}>
  <DialogTitle>Ajouter un </DialogTitle>
  <DialogContent>
    {tableDataReferentiels.length > 0 ? ( // Vérifie la présence d'un  existant
      <MDTypography variant="body2" color="error">
        Un  est déjà associé à cette promotion.
      </MDTypography>
    ) : (
      <FormControl fullWidth margin="dense">
        <InputLabel id="referentiel-label">Sélectionnez un </InputLabel>
        <Select
          labelId="referentiel-label"
          value={selectedReferentiel}
          onChange={(e) => setSelectedReferentiel(e.target.value)}
        >
          {allReferentiels.map((referentiel) => (
            <MenuItem key={referentiel.identifiant_referentiel} value={referentiel.identifiant_referentiel}>
              {referentiel.denomination_referentiel}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
  </DialogContent>
  <DialogActions>
    <MDButton onClick={() => setOpenReferentielDialog(false)} color="secondary">
      Annuler
    </MDButton>
    <MDButton onClick={handleSubmitReferentiel} color="primary" disabled={tableDataReferentiels.length > 0}>
      Associer
    </MDButton>
  </DialogActions>
</Dialog>
<Dialog open={openAssociateUserDialog} onClose={() => setOpenAssociateUserDialog(false)}>
  <DialogTitle>Associer un utilisateur</DialogTitle>
  <DialogContent>
    <FormControl fullWidth margin="dense">
      <InputLabel id="user-select-label">Sélectionnez un utilisateur</InputLabel>
      <Select
        labelId="user-select-label"
        value={selectedUserToAssociate}
        onChange={(e) => setSelectedUserToAssociate(e.target.value)}
      >
        {allUsers.map((user) => (
          <MenuItem key={user.identifiant_utilisateur} value={user.identifiant_utilisateur}>
            {user.nom_utilisateur} {user.prenom_utilisateur}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </DialogContent>
  <DialogActions>
    <MDButton onClick={() => setOpenAssociateUserDialog(false)} color="secondary">
      Annuler
    </MDButton>
    <MDButton onClick={handleAssociateUser} color="primary" disabled={!selectedUserToAssociate}>
      Associer
    </MDButton>
  </DialogActions>
</Dialog>

<Dialog
  open={openAddUtilisateurDialog}
  onClose={() => {
    setOpenAddUtilisateurDialog(false);
    setNewUtilisateur({
      prenom_utilisateur: "",
      nom_utilisateur: "",
      email_utilisateur: "",
      
      role: "",
    });
  }}
>
  <DialogTitle>Ajouter un utilisateur</DialogTitle>
  <DialogContent>
    <TextField
      label="Prénom"
      name="prenom_utilisateur"
      value={newUtilisateur.prenom_utilisateur}
      onChange={(e) => setNewUtilisateur({ ...newUtilisateur, prenom_utilisateur: e.target.value })}
      fullWidth
      margin="dense"
    />
    <TextField
      label="Nom"
      name="nom_utilisateur"
      value={newUtilisateur.nom_utilisateur}
      onChange={(e) => setNewUtilisateur({ ...newUtilisateur, nom_utilisateur: e.target.value })}
      fullWidth
      margin="dense"
    />
    <TextField
      label="Email"
      name="email_utilisateur"
      type="email"
      value={newUtilisateur.email_utilisateur}
      onChange={(e) => setNewUtilisateur({ ...newUtilisateur, email_utilisateur: e.target.value })}
      fullWidth
      margin="dense"
    />
   <FormControl fullWidth margin="dense">
  <InputLabel id="role-label">Rôle</InputLabel>
  <Select
    labelId="role-label"
    name="role"
    value={newUtilisateur.role}
    onChange={(e) => setNewUtilisateur({ ...newUtilisateur, role: e.target.value })}
  >
    {roles.map((role) => (
      <MenuItem key={role.key} value={role.key}>
        {role.label}
      </MenuItem>
    ))}
  </Select>
</FormControl>

  </DialogContent>
  <DialogActions>
    <MDButton
      onClick={() => {
        setOpenAddUtilisateurDialog(false);
        setNewUtilisateur({
          prenom_utilisateur: "",
          nom_utilisateur: "",
          email_utilisateur: "",
          
          role: "",
        });
      }}
      color="secondary"
    >
      Annuler
    </MDButton>
    <MDButton onClick={handleAddUtilisateurSubmit} color="primary">
      Valider
    </MDButton>
  </DialogActions>
</Dialog>

{openUserForm && (
  <FormDialog
    open={openUserForm}
    onClose={() => {
      setOpenUserForm(false);
      setEditingUser(null);
      setNewUser({
        prenom_utilisateur: "",
        nom_utilisateur: "",
        email_utilisateur: "",
        
        role: "",
      });
    }}
    onSubmit={handleUserFormSubmit}
    formData={newUser}
    handleInputChange={(e) => {
      const { name, value } = e.target;
      setNewUser({ ...newUser, [name]: value });
    }}
    fields={[
      { label: "Prénom", name: "prenom_utilisateur" },
      { label: "Nom", name: "nom_utilisateur" },
      { label: "Email", name: "email_utilisateur", type: "email" },
      // Supprimer le champ "Rôle" ici pour l'ajout global
    ]}
    title={editingUser ? "Modifier l'utilisateur" : "Ajouter un utilisateur"}
  />
)}



      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EtabManagement;

