/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import { useContext } from "react";
import Analytics from "layouts/dashboards/analytics";
import BDD from "layouts/stages/BDD";
import Conventions from "layouts/stages/conventions";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
import NewUser from "layouts/pages/users/new-user";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
import PricingPage from "layouts/pages/pricing-page";
import Widgets from "layouts/pages/widgets";
import RTL from "layouts/pages/rtl";
import Charts from "layouts/pages/charts";
import Notifications from "layouts/pages/notifications";
// import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
import DataTables from "layouts/applications/data-tables";
import Calendar from "layouts/applications/calendar";
import NewProduct from "layouts/ecommerce/products/new-product";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";

import ParcoursPage from "layouts/parcours/parcours-espace/parcours-page";
import ParcoursList from "layouts/parcours/parcours-espace/parcours-list";
import ParcoursGrille from "layouts/parcours/parcours-espace/parcours-grille";
import ParcoursDetails from "layouts/parcours/parcours-espace/parcours-details";
import ParcoursNouveau from "layouts/parcours/parcours-gestion/parcours-nouveau";
import ParcoursEditer from "layouts/parcours/parcours-gestion/parcours-editer";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
import ResetCover from "layouts/authentication/reset-password/cover";
import ValidationsPage from "layouts/parcours/parcours-espace/activites-validations";
import StorePage from "layouts/sotre";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AllActivites from "layouts/all_activites";
import { faNode } from "@fortawesome/free-brands-svg-icons";
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import UserProfile from "cruds/user-profile";
import RoleManagement from "cruds/role-managament";
import CategoryManagement from "cruds/category-management";
import TagManagement from "cruds/tag-management";
import UserManagement from "cruds/user-management";
import ItemManagement from "cruds/item-management";
import EtabManagement from "cruds/etab-management";
import Logout from "auth/logout";
import CreateTicketPage from "layouts/Tickets";
// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SchoolIcon from '@mui/icons-material/School';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
// Images
import profilePicture from "assets/images/team-3.jpg";
    
import ActiviteNouveau from "layouts/parcours/parcours-gestion/activite-nouveau";
import SecureLS from 'secure-ls';
import AnchorIcon from '@mui/icons-material/Anchor';
import Evaluations from "layouts/espace_evaluations";
import InfoIcon from '@mui/icons-material/Info';



const routes = [
  {
    type: "collapse",
    name: "",
    key: "user-names",
    icon: <MDAvatar src={profilePicture} alt="Bruce Marss" size="sm" />,
    hidden: true,
    collapse: [

      {
        name: "Settings",
        key: "settings",
        route: "/pages/account/settings",
        component: <Settings />,
      },
      {
        name: "Logout ",
        key: "logout",
        route: "/logout",
        component: <Logout />,

      }, 
      {
        name: "Référentiels",
        key: "item-management",
        route: "/examples-api/item-management",
        component: <ItemManagement />,
       
        hidden: true

      },
      {
        name: "Etablissement",
        key: "etab-management",
        route: "/examples-api/etab-management",
        component: <EtabManagement />,
       
        protected: true,
       
        
  
      },
      
    ],
  },
 // { 
    // type: "divider", key: "divider-0", role: "view", },


    {
      type: "collapse",
      name: "Tableau de bord",
      key: "dashboard",
      route: "/pages/profile/profile-overview",
      icon: <Icon fontSize="medium">dashboard</Icon>, // Icône de validation
      component: <ProfileOverview />,
      noCollapse: true,
    },

    
    {type: "divider", key: "divider-0", role: "view", },



  // {
  //   type: "collapse",
  //   name: "Dashboards",
  //   key: "dashboards",
  //   icon: <Icon fontSize="medium">dashboard</Icon>,
  //   collapse: [
  //     {
  //       name: "Analytics",
  //       key: "analytics",
  //       route: "/dashboards/analytics",
  //       component: <Analytics />,
  //     },
  //     {
  //       name: "Sales",
  //       key: "sales",
  //       route: "/dashboards/sales",
  //       component: <Sales />,
  //     },
  //   ],
  // },
  // { type: "title", title: "React + NodeJS", key: "crud-pages" },


  {
    type: "collapse",
    name: "Référentiel",
    key: "referentiel",
    route: "/examples-api/item-management",
    icon: <SchoolIcon fontSize="medium" />, // Icône de validation
    component: <ItemManagement />,
    noCollapse: true,
    hidden: true
  },

  {
    type: "collapse",
    name: "Infos utiles",
    key: "calendar",
    route: "/applications/calendar",
    icon: <InfoIcon fontSize="medium" />, // Icône de validation
    component: <Calendar />,
    noCollapse: true,
  },

  
 



  // {
  //   type: "collapse",
  //   name: "Gestion",
  //   key: "react-nodejs",
  //   icon: <FontAwesomeIcon icon={faNode} size="sm" />,
  //   droits: "droit_gestion",
  //   collapse: [
  //     // {
  //     //   name: "User Profile",
  //     //   key: "user-profile",
  //     //   route: "/examples-api/user-profile",
  //     //   component: <UserProfile />,
  //     // },
  //     // {
  //     //   name: "User Management",
  //     //   key: "user-management",
  //     //   route: "/examples-api/user-management",
  //     //   component: <UserManagement />,

  //     // },
  //     // {
  //     //   name: "Role Management",
  //     //   key: "role-management",
  //     //   route: "/examples-api/role-management",
  //     //   component: <RoleManagement />,
       
  //     // },
  //     // {
  //     //   name: "Category Management",
  //     //   key: "category-management",
  //     //   route: "/examples-api/category-management",
  //     //   component: <CategoryManagement />,
      
  //     // },
  //     // {
  //     //   name: "Tag Management",
  //     //   key: "tag-management",
  //     //   route: "/examples-api/tag-management",
  //     //   component: <TagManagement />,
       
  //     // },
  //     {
  //       name: "Référentiels",
  //       key: "item-management",
  //       route: "/examples-api/item-management",
  //       component: <ItemManagement />,
  //       protected: true,

  //     },
  //     {
  //       name: "Etablissement",
  //       key: "etab-management",
  //       route: "/examples-api/etab-management",
  //       component: <EtabManagement />,
  //       protected: true,
       
        
  
  //     },
     
  //   ],
  // },
  // {type: "divider", key: "divider-1", role: "view", },
  // {
  //   type: "collapse",
  //   name: "Pages",
  //   key: "pages",
  //   icon: <Icon fontSize="medium">image</Icon>,
  //   collapse: [
  //     {
  //       name: "Profile",
  //       key: "profile",
  //       collapse: [
  //         {
  //           name: "Profile Overview",
  //           key: "profile-overview",
  //           route: "/pages/profile/profile-overview",
  //           component: <ProfileOverview />,
  //         },
  //         {
  //           name: "All Projects",
  //           key: "all-projects",
  //           route: "/pages/profile/all-projects",
  //           component: <AllProjects />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Users",
  //       key: "users",
  //       collapse: [
  //         {
  //           name: "New User",
  //           key: "new-user",
  //           route: "/pages/users/new-user",
  //           component: <NewUser />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Account",
  //       key: "account",
  //       collapse: [
  //         {
  //           name: "Settings",
  //           key: "settings",
  //           route: "/pages/account/settings",
  //           component: <Settings />,
  //         },
  //         {
  //           name: "Billing",
  //           key: "billing",
  //           route: "/pages/account/billing",
  //           component: <Billing />,
  //         },
  //         {
  //           name: "Invoice",
  //           key: "invoice",
  //           route: "/pages/account/invoice",
  //           component: <Invoice />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Projects",
  //       key: "projects",
  //       collapse: [
  //         {
  //           name: "Timeline",
  //           key: "timeline",
  //           route: "/pages/projects/timeline",
  //           component: <Timeline />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Pricing Page",
  //       key: "pricing-page",
  //       route: "/pages/pricing-page",
  //       component: <PricingPage />,
  //     },
  //     { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
  //     { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
  //     { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
  //     {
  //       name: "Notfications",
  //       key: "notifications",
  //       route: "/pages/notifications",
  //       component: <Notifications />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Applications",
  //   key: "applications",
  //   icon: <Icon fontSize="medium">apps</Icon>,
  //   collapse: [
  //     // {
  //     //   name: "Kanban",
  //     //   key: "kanban",
  //     //   route: "/applications/kanban",
  //     //   component: <Kanban />,
  //     // },
  //     {
  //       name: "Wizard",
  //       key: "wizard",
  //       route: "/applications/wizard",
  //       component: <Wizard />,
  //     },
  //     {
  //       name: "Data Tables",
  //       key: "data-tables",
  //       route: "/applications/data-tables",
  //       component: <DataTables />,
  //     },
  //     {
  //       name: "Calendar",
  //       key: "calendar",
  //       route: "/applications/calendar",
  //       component: <Calendar />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Ecommerce",
  //   key: "ecommerce",
  //   icon: <Icon fontSize="medium">shopping_basket</Icon>,
  //   collapse: [
  //     {
  //       name: "Products",
  //       key: "products",
  //       collapse: [
  //         {
  //           name: "New Product",
  //           key: "new-product",
  //           route: "/ecommerce/products/new-product",
  //           component: <NewProduct />,
  //         },
  //         {
  //           name: "Edit Product",
  //           key: "edit-product",
  //           route: "/ecommerce/products/edit-product",
  //           component: <EditProduct />,
  //         },
  //         {
  //           name: "Product Page",
  //           key: "product-page",
  //           route: "/ecommerce/products/product-page",
  //           component: <ProductPage />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Orders",
  //       key: "orders",
  //       collapse: [
  //         {
  //           name: "Order List",
  //           key: "order-list",
  //           route: "/ecommerce/orders/order-list",
  //           component: <OrderList />,
  //         },
  //         {
  //           name: "Order Details",
  //           key: "order-details",
  //           route: "/ecommerce/orders/order-details",
  //           component: <OrderDetails />,
  //         },
  //       ],
  //     },
  //   ],
  // },

 
  
  {
    type: "collapse",
    name: "Parcours",
    key: "parcours",
    hidden: true,
    icon: <DirectionsRunIcon fontSize="medium" />,  // Utilisation de la nouvelle icône
    collapse: [
      {
        name: "Gestion parcours",
        key: "gestion-parcours",
        hidden: true,
        collapse: [
          {
            name: "Nouveau parcours",
            key: "nouveau-parcours",
            route: "/parcours/parcours-gestion/parcours-nouveau",
            component: <ParcoursNouveau />,
            hidden: true,  // Cette clé permet de masquer l'élément dans le Sidenav
          },

          {
            name: "Nouveau activite",
            key: "nouveau-activite",
            route: "/parcours/parcours-gestion/activite-nouveau",
            component: <ActiviteNouveau />,
            hidden: true,  // Cette clé permet de masquer l'élément dans le Sidenav
          },
          // {
          //   name: "Editer parcours",
          //   key: "editer-parcours",
          //   route: "/parcours/parcours-gestion/parcours-editer",
          //   component: <ParcoursEditer />,
          // },
         
        ],
      },


      // {
      //   name: "Espace parcours",
      //   key: "espace-parcours",
      //   collapse: [
          
      //     {
      //       name: "Grille parcours",
      //       key: "parcours-grille",
      //       route: "/parcours/parcours-espace/parours-grille",
      //       component: <ParcoursGrille />,
      //     },
    
      //     {
      //       name: "Parcours Page",
      //       key: "parcours-page",
      //       route: "/parcours/parcours-espace/parcours-page/:id", // Route dynamique avec paramètre :id
      //       component: <ParcoursPage />,
      //       hidden: true,  // Cette clé permet de masquer l'élément dans le Sidenav
      //     },
      //   ],
      // },

      {
        name: "Grille parcours",
        key: "parcours-grille",
        route: "/parcours/parcours-espace/parours-grille",
        component: <ParcoursGrille />,
      },
      {
        name: "Validations",
        key: "activites-validations",
        route: "/parcours/parcours-espace/parours-grille",
        component: <OrderList />,
      },
      
      {
        name: "Parcours Page",
        key: "parcours-page",
        route: "/parcours/parcours-espace/parcours-page/:id",
        component: <ParcoursPage />,
        hidden: true,
      },

  
    ],
  },
  // "Grille parcours" devient un bouton simple
  
  // {
  //   type: "collapse",
  //   name: "Authentication",
  //   key: "authentication",
  //   icon: <Icon fontSize="medium">content_paste</Icon>,
  //   collapse: [
  //     {
  //       name: "Sign In",
  //       key: "sign-in",
  //       collapse: [
  //         {
  //           name: "Basic",
  //           key: "basic",
  //           route: "/authentication/sign-in/basic",
  //           component: <SignInBasic />,
  //         },
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/sign-in/cover",
  //           component: <SignInCover />,
  //         },
  //         {
  //           name: "Illustration",
  //           key: "illustration",
  //           route: "/authentication/sign-in/illustration",
  //           component: <SignInIllustration />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Sign Up",
  //       key: "sign-up",
  //       collapse: [
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/sign-up/cover",
  //           component: <SignUpCover />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Reset Password",
  //       key: "reset-password",
  //       collapse: [
  //         {
  //           name: "Cover",
  //           key: "cover",
  //           route: "/authentication/reset-password/cover",
  //           component: <ResetCover />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // { type: "divider", key: "divider-1" },
  // { type: "title", title: "Docs", key: "title-docs" },
  // {
  //   type: "collapse",
  //   name: "Basic",
  //   key: "basic",
  //   icon: <Icon fontSize="medium">upcoming</Icon>,
  //   collapse: [
  //     {
  //       name: "Getting Started",
  //       key: "getting-started",
  //       collapse: [
  //         {
  //           name: "Overview",
  //           key: "overview",
  //           href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/overview/material-dashboard/",
  //         },
  //         {
  //           name: "License",
  //           key: "license",
  //           href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/license/material-dashboard/",
  //         },
  //         {
  //           name: "Quick Start",
  //           key: "quick-start",
  //           href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/quick-start/material-dashboard/",
  //         },
  //         {
  //           name: "Build Tools",
  //           key: "build-tools",
  //           href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/build-tools/material-dashboard/",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Foundation",
  //       key: "foundation",
  //       collapse: [
  //         {
  //           name: "Colors",
  //           key: "colors",
  //           href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/colors/material-dashboard/",
  //         },
  //         {
  //           name: "Grid",
  //           key: "grid",
  //           href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/grid/material-dashboard/",
  //         },
  //         {
  //           name: "Typography",
  //           key: "base-typography",
  //           href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/base-typography/material-dashboard/",
  //         },
  //         {
  //           name: "Borders",
  //           key: "borders",
  //           href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/borders/material-dashboard/",
  //         },
  //         {
  //           name: "Box Shadows",
  //           key: "box-shadows",
  //           href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/box-shadows/material-dashboard/",
  //         },
  //         {
  //           name: "Functions",
  //           key: "functions",
  //           href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/functions/material-dashboard/",
  //         },
  //         {
  //           name: "Routing System",
  //           key: "routing-system",
  //           href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/routing-system/material-dashboard/",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Components",
  //   key: "components",
  //   icon: <Icon fontSize="medium">view_in_ar</Icon>,
  //   collapse: [
  //     {
  //       name: "Alerts",
  //       key: "alerts",
  //       href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/alerts/material-dashboard/",
  //     },
  //     {
  //       name: "Avatar",
  //       key: "avatar",
  //       href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/avatar/material-dashboard/",
  //     },
  //     {
  //       name: "Badge",
  //       key: "badge",
  //       href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/badge/material-dashboard/",
  //     },
  //     {
  //       name: "Badge Dot",
  //       key: "badge-dot",
  //       href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/badge-dot/material-dashboard/",
  //     },
  //     {
  //       name: "Box",
  //       key: "box",
  //       href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/box/material-dashboard/",
  //     },
  //     {
  //       name: "Buttons",
  //       key: "buttons",
  //       href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/buttons/material-dashboard/",
  //     },
  //     {
  //       name: "Date Picker",
  //       key: "date-picker",
  //       href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/datepicker/material-dashboard/",
  //     },
  //     {
  //       name: "Dropzone",
  //       key: "dropzone",
  //       href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/dropzone/material-dashboard/",
  //     },
  //     {
  //       name: "Editor",
  //       key: "editor",
  //       href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/quill/material-dashboard/",
  //     },
  //     {
  //       name: "Input",
  //       key: "input",
  //       href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/input/material-dashboard/",
  //     },
  //     {
  //       name: "Pagination",
  //       key: "pagination",
  //       href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/pagination/material-dashboard/",
  //     },
  //     {
  //       name: "Progress",
  //       key: "progress",
  //       href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/progress/material-dashboard/",
  //     },
  //     {
  //       name: "Snackbar",
  //       key: "snackbar",
  //       href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/snackbar/material-dashboard/",
  //     },
  //     {
  //       name: "Social Button",
  //       key: "social-button",
  //       href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/social-buttons/material-dashboard/",
  //     },
  //     {
  //       name: "Typography",
  //       key: "typography",
  //       href: "https://material-dashboard-react-laravel-docs.creative-tim.com/react/typography/material-dashboard/",
  //     },
  //   ],
  // },
  {
    type: "collapse",
    name: "Grille parcours",
    key: "parcours-grille",
    route: "/parcours/parcours-espace/parours-grille",
    icon: <DirectionsRunIcon fontSize="medium" />,
    component: <ParcoursGrille />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Activites",
    key: "activites",
    route: "/activites",
    icon: <CollectionsBookmarkIcon fontSize="medium" />,  // Icône de validation
    component: <AllActivites />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Evaluations",
    key: "/evaluations",
    route: "/evaluations",
    icon: <AnchorIcon fontSize="medium" />,  // Icône de validation
    component: <Evaluations />,
    noCollapse: true,
  },
  
  {
    type: "collapse",
    name: "Validations",
    key: "activites-validations",
    route: "/parcours/parcours-espace/activites-validations",
    icon: <CheckCircleIcon fontSize="medium" />,  // Icône de validation
    component: <ValidationsPage />,
    noCollapse: true,
  },

  {type: "divider", key: "divider-2", role: "view", },

  {
    type: "collapse",
    name: "Stages",
    key: "stages",
    icon: <Icon fontSize="medium">stages</Icon>,
    collapse: [
      {
        name: "Entreprises",
        key: "base_de_donnee",
        route: "/stages/bdd",
        component: <BDD />,
        
      },
      {
        name: "Documents",
        key: "conventions",
        route: "/stages/conventions",
        component: <Conventions />,
      },
    ],
  },

  {type: "divider", key: "divider-2", role: "view", },

  // {type: "divider", key: "divider-2", role: "view", },

  // {
  //   type: "collapse",
  //   name: "Store",
  //   key: "store",
  //   route: "/store",
  //   icon: <LocalGroceryStoreIcon fontSize="medium" />,  // Icône de validation
  //   component: <StorePage />,
  //   noCollapse: true,
  // },

  // {type: "divider", key: "divider-2", role: "view", },

  {
    type: "collapse",
    name: "Tickets",
    key: "tickets",
    route: "/tickets",
    icon: <ContactSupportIcon fontSize="medium" />,  // Icône de validation
    component: <CreateTicketPage />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Magasin",
    key: "store",
    route: "/store",
    icon: <LocalGroceryStoreIcon fontSize="medium" />,  // Icône de validation
    component: <StorePage />,
    noCollapse: true,
  },

 

];

export default routes;

