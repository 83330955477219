import { useState, useContext, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/account/components/FormField";
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import { AuthContext } from "context";
import useStore from "services/store";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import MDAlert from "components/MDAlert";

function BasicInfo() {
  const [notification, setNotification] = useState({ value: false, text: "" });
  console.log("dede");
  const { userUid, userIdentite, userEtab, userRights, role } = useContext(AuthContext);
  console.log("miaou SIAMES : ", userIdentite, userUid, role);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    gender: "Non spécifié",
    birthMonth: "Février",
    birthDay: "1",
    birthYear: "2021",
    email: "",
    confirmEmail: "",
    phoneNumber: "",
    discordId: "" // Nouveau champ pour l'identifiant Discord
  });

  useEffect(() => {
    if (userIdentite) {
      setFormData({
        firstName: userIdentite.prenom,
        lastName: userIdentite.nom,
        gender: "Non spécifié",
        birthMonth: "Février",
        birthDay: "1",
        birthYear: "2021",
        email: userIdentite.email,
        confirmEmail: "",
        phoneNumber: userIdentite.numero_telephone,
        discordId: userIdentite.discord_id || "" // Si disponible dans l'objet userIdentite
      });
    }
  }, [userIdentite]);

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { email, firstName, lastName, discordId } = formData;
      const userInfo = { nom: lastName, prenom: firstName, email, discordId };
      const response = await crudsServiceFAPI.updateUserInfos(userUid, userInfo);
      console.log("User info updated:", response);
    } catch (error) {
      console.error("Error updating user info:", error);
    }
  };

  const {
    selectedEtab,
    setSelectedEtab,
    selectedSection,
    setSelectedSection,
    selectedPromotion,
    setSelectedPromotion,
    selectedMatiere,
    setSelectedMatiere,
    userId,
    setUserId,
    selectedReferentiel,
    setSelectedReferentiel,
  } = useStore((state) => ({
    selectedEtab: state.selectedEtab,
    setSelectedEtab: state.setSelectedEtab,
    selectedSection: state.selectedSection,
    setSelectedSection: state.setSelectedSection,
    selectedPromotion: state.selectedPromotion,
    setSelectedPromotion: state.setSelectedPromotion,
    selectedMatiere: state.selectedMatiere,
    setSelectedMatiere: state.setSelectedMatiere,
    userId: state.userId,
    setUserId: state.setUserId,
    selectedReferentiel: state.selectedReferentiel,
    setSelectedReferentiel: state.setSelectedReferentiel,
  }));

  console.log("userId : ", userId);

  if (!userIdentite) {
    return <p>Chargement des informations...</p>;
  }

  const showNotification = (message) => {
    setNotification({ value: true, text: message });
    setTimeout(() => setNotification({ value: false, text: "" }), 3000);
  };

  const handleUpdatePhone = async () => {
    try {
      const phone = formData.phoneNumber;
      const discordId = formData.discordId;
      const response = await crudsServiceFAPI.updateUserPhone(userUid, phone, discordId);
      console.log("Phone and Discord ID updated:", response);
      showNotification("Téléphone et identifiant Discord mis à jour avec succès");
    } catch (error) {
      console.error("Error updating phone number and Discord ID:", error);
    }
  };

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Rôle : {role}</MDTypography>
      </MDBox>
      <MDBox component="form" onSubmit={handleSubmit} pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Prénom"
              placeholder="Jean"
              onChange={(e) => handleChange("firstName", e.target.value)}
              value={formData.firstName}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Nom"
              placeholder="Dupont"
              onChange={(e) => handleChange("lastName", e.target.value)}
              value={formData.lastName}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Email"
              placeholder="example@email.com"
              inputProps={{ type: "email" }}
              onChange={(e) => handleChange("email", e.target.value)}
              value={formData.email}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Numéro de téléphone"
              placeholder="06 43 61 44 91"
              inputProps={{ type: "number" }}
              onChange={(e) => handleChange("phoneNumber", e.target.value)}
              value={formData.phoneNumber}
            />
          </Grid>
          {/* Nouveau champ pour l'identifiant Discord */}
          <Grid item xs={12} sm={6}>
            <FormField
              label="Identifiant Discord"
              placeholder="Entrez votre identifiant Discord"
              onChange={(e) => handleChange("discordId", e.target.value)}
              value={formData.discordId}
            />
          </Grid>
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <MDBox ml={2} display="flex" alignItems="center">
              <MDButton
                variant="contained"
                color="primary"
                onClick={handleUpdatePhone}
                sx={{ whiteSpace: "nowrap", ml: 2 }}
              >
                Mettre à jour
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      {notification.value && (
        <MDAlert color="info" my={2}>
          <MDTypography variant="body2" color="white">
            {notification.text}
          </MDTypography>
        </MDAlert>
      )}
    </Card>
  );
}

export default BasicInfo;
