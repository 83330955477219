import { useState, useEffect, useContext } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Header from "layouts/pages/profile/components/Header";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import useStore from "services/store";
import { AuthContext } from "context";

function BDD() {
  const { role } = useContext(AuthContext);
  const allowedRoles = ["superadministrateur", "administrateur", "gestionnaire", "professeur"];

  // On suppose que selectedSection est nécessaire pour filtrer les conventions
  const { selectedSection } = useStore((state) => ({
    selectedSection: state.selectedSection,
  }));

  // État pour la recherche dans le tableau des conventions
  const [searchQuery, setSearchQuery] = useState("");

  // États pour la gestion des conventions
  const [conventions, setConventions] = useState([]);
  const [openConventionEditDialog, setOpenConventionEditDialog] = useState(false);
  const [currentConvention, setCurrentConvention] = useState(null);
  const [newConvention, setNewConvention] = useState({
    identifiant_section: selectedSection?.identifiant_section || "",
    nom_entreprise: "",
    nom_etudiant: "",
    prenom_etudiant: "",
    nom_directeur: "",
    nom_tuteur: "",
    personne_convention: "",
    adresse_entreprise: "",
    courriel: "",
    telephone: "",
    fax: "",
    status: 1,
  });
  const [openConventionForm, setOpenConventionForm] = useState(false);

  // Fonction de récupération des conventions validées par section
  const fetchValidatedConventions = async () => {
    try {
      if (selectedSection?.identifiant_section) {
        const response = await crudsServiceFAPI.getValidatedConventionsBySection(
          selectedSection.identifiant_section
        );
        console.log("donald : ", response)
        setConventions(response);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des conventions validées :", error);
    }
  };

  useEffect(() => {
    fetchValidatedConventions();
  }, [selectedSection]);

  // Fonctions d'édition et suppression des conventions
  const handleOpenConventionEditDialog = (convention) => {
    setCurrentConvention(convention);
    setOpenConventionEditDialog(true);
  };

  const handleCloseConventionEditDialog = () => {
    setOpenConventionEditDialog(false);
    setCurrentConvention(null);
  };

  const handleUpdateConvention = async () => {
    try {
      await crudsServiceFAPI.updateConvention(currentConvention.identifiant_convention, currentConvention);
      await fetchValidatedConventions();
      setOpenConventionEditDialog(false);
      // Réinitialiser le formulaire d'édition
      setCurrentConvention({ ...initialConventionValues });
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la convention :", error);
    }
  };

  const handleDeleteConvention = async (conventionId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette convention ?")) {
      try {
        await crudsServiceFAPI.deleteConvention(conventionId);
        await fetchValidatedConventions();
      } catch (error) {
        console.error("Erreur lors de la suppression de la convention :", error);
      }
    }
  };

  // Fonction pour ajouter une convention via l'endpoint conventions/section
  const handleAddConvention = async () => {
    try {
      await crudsServiceFAPI.createConventionSection(selectedSection.identifiant_section, newConvention);
      setOpenConventionForm(false);
      await fetchValidatedConventions();
    } catch (error) {
      console.error("Erreur lors de l'ajout de la convention :", error);
    }
  };

  // Définition des colonnes du tableau
  const conventionsColumns = [
    {
      Header: "Date",
      accessor: "date_convention",
      Cell: ({ value }) => {
        const date = value ? new Date(value) : null;
        return date ? date.toLocaleDateString("fr-FR") : "";
      },
    },
    { Header: "Entreprise", accessor: "nom_entreprise" },
    { Header: "Nom étudiant", accessor: "nom_etudiant" },
    { Header: "Prénom étudiant", accessor: "prenom_etudiant" },
    { Header: "Directeur", accessor: "nom_directeur" },
    { Header: "Tuteur", accessor: "nom_tuteur" },
    { Header: "Personne", accessor: "personne_convention" },
    { Header: "Adresse", accessor: "adresse_entreprise" },
    { Header: "Email", accessor: "courriel" },
    { Header: "Téléphone", accessor: "telephone" },
    { Header: "Tâches", accessor: "taches" },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => (
        <MDBox display="flex" gap={1}>
          {allowedRoles.includes(role) && (
            <>
              <IconButton onClick={() => handleOpenConventionEditDialog(row.original)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteConvention(row.original.identifiant_convention)}>
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </MDBox>
      ),
    },
  ];

  // Définition d'un objet initial pour réinitialiser le formulaire de convention
const initialConventionValues = {
  nom_entreprise: "",
  nom_etudiant: "",
  prenom_etudiant: "",
  nom_directeur: "",
  nom_tuteur: "",
  personne_convention: "",
  adresse_entreprise: "",
  courriel: "",
  telephone: "",
  fax: "",
  status: 1,
};


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header>
        <MDBox mb={2}>
          <TextField
            label="Rechercher une entreprise"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </MDBox>
        <MDTypography variant="h4" mb={2}>
          Tableau des entreprises
        </MDTypography>
        {allowedRoles.includes(role) && (
          <MDBox mb={2}>
            <MDButton variant="contained" color="primary" onClick={() => setOpenConventionForm(true)}>
              Ajouter une entreprise
            </MDButton>
          </MDBox>
        )}
        <DataTable
          table={{
            columns: conventionsColumns,
            rows: conventions.filter((conv) =>
              conv.nom_entreprise.toLowerCase().includes(searchQuery.toLowerCase())
            ),
          }}
        />

        {/* Popup pour éditer une convention */}
        <Dialog open={openConventionEditDialog} onClose={handleCloseConventionEditDialog} fullWidth maxWidth="sm">
          <DialogTitle>Modifier la Convention</DialogTitle>
          <DialogContent>
            <MDBox component="form" mt={2}>
              <TextField
                label="Entreprise"
                name="nom_entreprise"
                value={currentConvention?.nom_entreprise || ""}
                onChange={(e) =>
                  setCurrentConvention({ ...currentConvention, nom_entreprise: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Nom étudiant"
                name="nom_etudiant"
                value={currentConvention?.nom_etudiant || ""}
                onChange={(e) =>
                  setCurrentConvention({ ...currentConvention, nom_etudiant: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Prénom étudiant"
                name="prenom_etudiant"
                value={currentConvention?.prenom_etudiant || ""}
                onChange={(e) =>
                  setCurrentConvention({ ...currentConvention, prenom_etudiant: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Directeur"
                name="nom_directeur"
                value={currentConvention?.nom_directeur || ""}
                onChange={(e) =>
                  setCurrentConvention({ ...currentConvention, nom_directeur: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Tuteur"
                name="nom_tuteur"
                value={currentConvention?.nom_tuteur || ""}
                onChange={(e) =>
                  setCurrentConvention({ ...currentConvention, nom_tuteur: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Personne"
                name="personne_convention"
                value={currentConvention?.personne_convention || ""}
                onChange={(e) =>
                  setCurrentConvention({ ...currentConvention, personne_convention: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Adresse"
                name="adresse_entreprise"
                value={currentConvention?.adresse_entreprise || ""}
                onChange={(e) =>
                  setCurrentConvention({ ...currentConvention, adresse_entreprise: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Email"
                name="courriel"
                value={currentConvention?.courriel || ""}
                onChange={(e) =>
                  setCurrentConvention({ ...currentConvention, courriel: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Téléphone"
                name="telephone"
                value={currentConvention?.telephone || ""}
                onChange={(e) =>
                  setCurrentConvention({ ...currentConvention, telephone: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Fax"
                name="fax"
                value={currentConvention?.fax || ""}
                onChange={(e) =>
                  setCurrentConvention({ ...currentConvention, fax: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleCloseConventionEditDialog} color="secondary">
              Annuler
            </MDButton>
            <MDButton onClick={handleUpdateConvention} variant="contained" color="primary">
              Enregistrer
            </MDButton>
          </DialogActions>
        </Dialog>

        {/* Popup pour ajouter une convention */}
        <Dialog
          open={openConventionForm}
          onClose={() => setOpenConventionForm(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Ajouter une entreprise</DialogTitle>
          <DialogContent>
            <MDBox component="form" mt={2}>
              <TextField
                label="Entreprise"
                name="nom_entreprise"
                value={newConvention.nom_entreprise}
                onChange={(e) => setNewConvention({ ...newConvention, nom_entreprise: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Nom étudiant"
                name="nom_etudiant"
                value={newConvention.nom_etudiant}
                onChange={(e) => setNewConvention({ ...newConvention, nom_etudiant: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Prénom étudiant"
                name="prenom_etudiant"
                value={newConvention.prenom_etudiant}
                onChange={(e) => setNewConvention({ ...newConvention, prenom_etudiant: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Directeur"
                name="nom_directeur"
                value={newConvention.nom_directeur}
                onChange={(e) => setNewConvention({ ...newConvention, nom_directeur: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Tuteur"
                name="nom_tuteur"
                value={newConvention.nom_tuteur}
                onChange={(e) => setNewConvention({ ...newConvention, nom_tuteur: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Personne"
                name="personne_convention"
                value={newConvention.personne_convention}
                onChange={(e) => setNewConvention({ ...newConvention, personne_convention: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Adresse"
                name="adresse_entreprise"
                value={newConvention.adresse_entreprise}
                onChange={(e) => setNewConvention({ ...newConvention, adresse_entreprise: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Email"
                name="courriel"
                value={newConvention.courriel}
                onChange={(e) => setNewConvention({ ...newConvention, courriel: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Téléphone"
                name="telephone"
                value={newConvention.telephone}
                onChange={(e) => setNewConvention({ ...newConvention, telephone: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Fax"
                name="fax"
                value={newConvention.fax}
                onChange={(e) => setNewConvention({ ...newConvention, fax: e.target.value })}
                fullWidth
                margin="normal"
              />
              
            </MDBox>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={() => setOpenConventionForm(false)} color="secondary">
              Annuler
            </MDButton>
            <MDButton
  onClick={async () => {
    await crudsServiceFAPI.createConventionSection(
      selectedSection.identifiant_section,
      newConvention
    );
    setOpenConventionForm(false);
    await fetchValidatedConventions();
    // Réinitialiser le formulaire
    setNewConvention({ ...initialConventionValues, identifiant_section: selectedSection.identifiant_section });
  }}
  variant="contained"
  color="primary"
>
  Ajouter
</MDButton>
          </DialogActions>
        </Dialog>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default BDD;
