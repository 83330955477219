import PropTypes from "prop-types"; // Importer PropTypes pour valider les props
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card"; // Importer le composant Card
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts"; // Assurez-vous d'installer et d'importer react-apexcharts
import { AbilityContext } from "Can"; // Importer le contexte Ability

// Composant du graphique ApexChart
const ApexChart = () => {
  const [state, setState] = React.useState({
    series: [76, 67, 61, 90],
    options: {
      chart: {
        height: 390,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: '30%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            }
          },
          barLabels: {
            enabled: true,
            useSeriesColors: true,
            offsetX: -8,
            fontSize: '16px',
            formatter: function(seriesName, opts) {
              return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
            },
          },
        }
      },
      colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
      labels: ['Vimeo', 'Messenger', 'Facebook', 'LinkedIn'],
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            show: false
          }
        }
      }]
    }
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart options={state.options} series={state.series} type="radialBar" height={390} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

function ParcoursInfo({ parcoursDetails, identifiantParcours }) {
  const ability = useContext(AbilityContext); // Récupérer l'objet `ability` depuis le contexte
  console.log("gugule parcours infos : ", parcoursDetails, identifiantParcours);
  console.log("virgule1 : ", ability.can('read', 'Article')); // Affiche true si la permission est présente

  return (
    <MDBox>
      <br />
      <MDBox mb={1}></MDBox>

      <MDBox mt={2} mb={1} ml={0.5}> {/* Réduction de l'espacement */}
        <MDTypography variant="button" fontWeight="regular" color="text">
          Détails du parcours
        </MDTypography>
      </MDBox>

      {/* Insertion du graphique juste en dessous du titre */}
      

      <Card
        sx={{
          p: 2,
          mb: 2,
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
          border: "1px solid #1976d2",
          borderRadius: "8px",
        }}
      >
        <MDTypography
          variant="body2"
          color="text"
          fontWeight="regular"
          component="div"
          dangerouslySetInnerHTML={{ __html: parcoursDetails?.detail_parcours }} // Contenu HTML rendu directement
        />
      </Card>

      {/* <ApexChart /> */}
    </MDBox>
  );
}

// Validation des props avec PropTypes
ParcoursInfo.propTypes = {
  parcoursDetails: PropTypes.object,
  identifiantParcours: PropTypes.string,
};

export default ParcoursInfo;
