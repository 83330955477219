/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim
* Coded by www.creative-tim.com
=========================================================
*/
import { useState, useEffect, useMemo, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import EventCalendar from "examples/Calendar";
import NextEvents from "layouts/applications/calendar/components/NextEvents";
import ProductivityChart from "layouts/applications/calendar/components/ProductivityChart";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import calendarEventsData from "layouts/applications/calendar/data/calendarEventsData";
import useStore from "services/store";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { AbilityContext } from "Can";
import { AuthContext } from "context";

function Calendar() {
  const ability = useContext(AbilityContext);
  const { role } = useContext(AuthContext);
  console.log("amour bis : ", role)
  const { selectedPromotion } = useStore((state) => ({
    selectedPromotion: state.selectedPromotion,
  }));

  const { selectedMatiere, setSelectedMatiere } = useStore((state) => ({
    selectedMatiere: state.selectedMatiere,
    setSelectedMatiere: state.setSelectedMatiere,
  }));

  // État pour gérer l'accès aux fonctionnalités de suppression
  const [gestion, setGestion] = useState(false);

  useEffect(() => {
    const canManage = ability.can("create", "activite");
    setGestion(canManage);
    console.log("Gestion activée :", canManage);
  }, [ability]);

  // --- Section Documents utiles ---
  const [documentsUtilesAPI, setDocumentsUtilesAPI] = useState([]);

  const fetchDocsUtiles = async () => {
    try {
      if (selectedPromotion?.identifiant_promotion) {
        const response = await crudsServiceFAPI.getDocumentsUtilesByPromotion(
          selectedPromotion.identifiant_promotion
        );
        console.log("Docs utiles récupérés :", response);
        setDocumentsUtilesAPI(response);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des documents utiles :", error);
    }
  };

  useEffect(() => {
    fetchDocsUtiles();
  }, [selectedPromotion]);

  const [url_agenda, setUrlAgenda] = useState([])
  const fetchURLAgenda = async () => {
    try {
      if (selectedPromotion?.identifiant_promotion) {
        const response = await crudsServiceFAPI.getURLAgendaByPromotionId(
          selectedPromotion.identifiant_promotion
        );
        console.log("url agenda récupéré :", response[0].url_agenda);
        setUrlAgenda(response[0].url_agenda);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des documents utiles :", error);
    }
  };

  useEffect(() => {
    fetchURLAgenda();
  }, [selectedPromotion]);

  const handleAddSection = async () => {
    const newSectionName = prompt("Entrez le nom de la nouvelle section :");
    if (newSectionName) {
      try {
        await crudsServiceFAPI.addDocumentSection(
          selectedPromotion.identifiant_promotion,
          newSectionName
        );
        fetchDocsUtiles();
      } catch (error) {
        console.error("Erreur lors de l'ajout de la section :", error);
      }
    }
  };

  const handleAddDocumentToSection = async (sectionId) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "*/*";
    input.onchange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        try {
          await crudsServiceFAPI.addDocument(
            file,
            selectedPromotion.identifiant_promotion,
            sectionId
          );
          fetchDocsUtiles();
        } catch (error) {
          console.error("Erreur lors de l'ajout du document :", error);
        }
      }
    };
    input.click();
  };

  const handleConsultDocument = async (doc) => {
    try {
      const fileUrl = await crudsServiceFAPI.downloadDocumentUtil(doc.document_id);
      console.log("URL du document :", fileUrl);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erreur lors de la consultation du document :", error);
    }
  };

  const handleDeleteDocument = async (doc) => {
    try {
      await crudsServiceFAPI.deleteDocumentUtil(doc.document_id);
      fetchDocsUtiles();
    } catch (error) {
      console.error("Erreur lors de la suppression du document :", error);
    }
  };

  const handleDeleteSection = async (sectionId) => {
    try {
      await crudsServiceFAPI.deleteDocumentSection(sectionId);
      fetchDocsUtiles();
    } catch (error) {
      console.error("Erreur lors de la suppression de la section :", error);
    }
  };

  // --- Section Documents matière ---
  const [documentsMatiereAPI, setDocumentsMatiereAPI] = useState([]);

  const fetchDocsMatiere = async () => {
    try {
      if (selectedMatiere?.identifiant_matiere) {
        const response = await crudsServiceFAPI.getDocumentsMatiereByMatiere(
          selectedMatiere.identifiant_matiere
        );
        console.log("Docs matière récupérés :", response);
        setDocumentsMatiereAPI(response || []);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des documents matière :", error);
    }
  };

  useEffect(() => {
    fetchDocsMatiere();
  }, [selectedMatiere]);

  const handleAddSectionMatiere = async () => {
    const newSectionName = prompt("Entrez le nom de la nouvelle section (Documents matière) :");
    if (newSectionName) {
      try {
        await crudsServiceFAPI.addDocumentSectionMatiere(
          selectedMatiere.identifiant_matiere,
          newSectionName
        );
        fetchDocsMatiere();
      } catch (error) {
        console.error("Erreur lors de l'ajout de la section Documents matière :", error);
      }
    }
  };

  const handleAddDocumentToSectionMatiere = async (sectionId) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "*/*";
    input.onchange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        try {
          await crudsServiceFAPI.addDocumentMatiere(
            file,
            selectedMatiere.identifiant_matiere,
            sectionId
          );
          fetchDocsMatiere();
        } catch (error) {
          console.error("Erreur lors de l'ajout du document (Documents matière) :", error);
        }
      }
    };
    input.click();
  };

  const handleConsultDocumentMatiere = async (doc) => {
    try {
      const fileUrl = await crudsServiceFAPI.downloadDocumentMatiere(doc.document_id);
      console.log("URL du document matière :", fileUrl);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erreur lors de la consultation du document matière :", error);
    }
  };

  const handleDeleteDocumentMatiere = async (doc) => {
    try {
      await crudsServiceFAPI.deleteDocumentMatiere(doc.document_id);
      fetchDocsMatiere();
    } catch (error) {
      console.error("Erreur lors de la suppression du document matière :", error);
    }
  };

  const handleDeleteSectionMatiere = async (sectionId) => {
    try {
      await crudsServiceFAPI.deleteDocumentMatiereSection(sectionId);
      fetchDocsMatiere();
    } catch (error) {
      console.error("Erreur lors de la suppression de la section Documents matière :", error);
    }
  };


// Définir les fonctions pour gérer l'URL agenda

const handleAddCalendar = async () => {
  const newURL = prompt("Entrez l'URL du calendrier:");
  if (newURL) {
    try {
      await crudsServiceFAPI.addURLAgenda(selectedPromotion.identifiant_promotion, newURL);
      setUrlAgenda(newURL);
    } catch (error) {
      console.error("Erreur lors de l'ajout du calendrier:", error);
    }
  }
};

const handleUpdateCalendar = async () => {
  const newURL = prompt("Entrez la nouvelle URL du calendrier:", url_agenda);
  if (newURL) {
    try {
      await crudsServiceFAPI.updateURLAgenda(selectedPromotion.identifiant_promotion, newURL);
      setUrlAgenda(newURL);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du calendrier:", error);
    }
  }
};

const handleDeleteCalendar = async () => {
  if (window.confirm("Êtes-vous sûr de vouloir supprimer le calendrier ?")) {
    try {
      await crudsServiceFAPI.deleteURLAgenda(selectedPromotion.identifiant_promotion);
      setUrlAgenda("");
    } catch (error) {
      console.error("Erreur lors de la suppression du calendrier:", error);
    }
  }
};



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox display="flex" justifyContent="flex-end" mt={1} mb={4} mx={2}>
          {/* <Header /> */}
        </MDBox>

        {/* Section Documents utiles */}
        <Card sx={{ marginX: 2, marginBottom: 4, borderRadius: "12px" }}>
          <MDBox p={2}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="h4" fontWeight="bold">
                Documents promotion
              </MDTypography>
              {gestion && (
                <MDButton variant="outlined" color="info" onClick={handleAddSection}>
                  Ajouter une section
                </MDButton>
              )}
            </MDBox>
            <MDBox mt={2}>
              {documentsUtilesAPI.map((section) => (
                <MDBox key={section.section_id} mb={3}>
                  <MDBox display="flex" alignItems="center">
                    {gestion && (
                      <MDButton
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={() => handleDeleteSection(section.section_id)}
                        sx={{ mr: 1 }}
                      >
                        <DeleteIcon fontSize="small" />
                      </MDButton>
                    )}
                    <MDTypography variant="subtitle1" fontWeight="medium">
                      {section.nom_section}
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" gap={2} mt={1} flexWrap="wrap">
  {section.documents && section.documents.length > 0 ? (
    section.documents.map((doc) => (
      <Card
        key={doc.document_id}
        sx={{ width: 200, p: 2, borderRadius: "8px", border: "1px solid #ccc" }}
      >
        <MDTypography variant="body2" fontWeight="bold" align="center">
          {doc.titre_document}
        </MDTypography>
        <MDBox display="flex" justifyContent="center" mt={2}>
          <MDButton
            variant="outlined"
            color="info"
            size="small"
            onClick={() => handleConsultDocument(doc)}
            sx={{ mr: 1 }}
          >
            Consulter
          </MDButton>
          {gestion && (
            <MDButton
              variant="outlined"
              color="error"
              size="small"
              onClick={() => handleDeleteDocument(doc)}
            >
              <DeleteIcon fontSize="small" />
            </MDButton>
          )}
        </MDBox>
      </Card>
    ))
  ) : (
    <MDTypography variant="body2" color="textSecondary">
      Aucun document dans cette section.
    </MDTypography>
  )}
</MDBox>
{gestion && (
  <MDButton
    variant="outlined"
    color="primary"
    size="small"
    onClick={() => handleAddDocumentToSection(section.section_id)}
    sx={{ mt: 1 }}
  >
    Ajouter un document
  </MDButton>
)}

                </MDBox>
              ))}
            </MDBox>
          </MDBox>
        </Card>

        {/* Section Documents matière */}
        <Card sx={{ marginX: 2, marginBottom: 4, borderRadius: "12px" }}>
          <MDBox p={2}>
            <MDBox display="flex" justifyContent="space-between" alignItems="center">
              <MDTypography variant="h4" fontWeight="bold">
                Documents matière
              </MDTypography>
              {gestion && (
                <MDButton variant="outlined" color="info" onClick={handleAddSectionMatiere}>
                  Ajouter une section
                </MDButton>
              )}
            </MDBox>
            <MDBox mt={2}>
              {documentsMatiereAPI.map((section) => (
                <MDBox key={section.section_id} mb={3}>
                  <MDBox display="flex" alignItems="center">
                    {gestion && (
                      <MDButton
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={() => handleDeleteSectionMatiere(section.section_id)}
                        sx={{ mr: 1 }}
                      >
                        <DeleteIcon fontSize="small" />
                      </MDButton>
                    )}
                    <MDTypography variant="subtitle1" fontWeight="medium">
                      {section.nom_section}
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex" gap={2} mt={1} flexWrap="wrap">
  {section.documents && section.documents.length > 0 ? (
    section.documents.map((doc) => (
      <Card
        key={doc.document_id}
        sx={{ width: 200, p: 2, borderRadius: "8px", border: "1px solid #ccc" }}
      >
        <MDTypography variant="body2" fontWeight="bold" align="center">
          {doc.titre_document}
        </MDTypography>
        <MDBox display="flex" justifyContent="center" mt={2}>
          <MDButton
            variant="outlined"
            color="info"
            size="small"
            onClick={() => handleConsultDocumentMatiere(doc)}
            sx={{ mr: 1 }}
          >
            Consulter
          </MDButton>
          {gestion && (
            <MDButton
              variant="outlined"
              color="error"
              size="small"
              onClick={() => handleDeleteDocumentMatiere(doc)}
            >
              <DeleteIcon fontSize="small" />
            </MDButton>
          )}
        </MDBox>
      </Card>
    ))
  ) : (
    <MDTypography variant="body2" color="textSecondary">
      Aucun document dans cette section.
    </MDTypography>
  )}
</MDBox>
{gestion && (
  <MDButton
    variant="outlined"
    color="primary"
    size="small"
    onClick={() => handleAddDocumentToSectionMatiere(section.section_id)}
    sx={{ mt: 1 }}
  >
    Ajouter un document
  </MDButton>
)}

                </MDBox>
              ))}
            </MDBox>
          </MDBox>
        </Card>

        {/* Intégration de l'iframe Google Calendar */}
        {/* Intégration de l'iframe Google Calendar sur une ligne complète */}
        
{/* Intégration de l'iframe Google Calendar ou affichage du bouton "Ajouter un calendrier" */}
<MDBox width="100%" my={4}>
  {url_agenda ? (
    <>
      <iframe
        src={url_agenda}
        style={{ border: "solid 1px #777", width: "100%" }}
        height="600"
        frameBorder="0"
        scrolling="no"
      />
      {(role === "superadministrateur" || role === "administrateur" || role === "gestionnaire") && (
        <MDBox mt={2} display="flex" gap={2}>
          <MDButton variant="outlined" color="info" onClick={handleUpdateCalendar}>
            Modifier
          </MDButton>
          <MDButton variant="outlined" color="error" onClick={handleDeleteCalendar}>
            Supprimer
          </MDButton>
        </MDBox>
      )}
    </>
  ) : (
    (role === "superadministrateur" || role === "administrateur" || role === "gestionnaire") && (
      <MDButton variant="contained" color="primary" onClick={handleAddCalendar}>
        Ajouter un calendrier
      </MDButton>
    )
  )}
</MDBox>



        {/* <Grid container spacing={3}>
          <Grid item xs={12} xl={9} sx={{ height: "max-content" }}>
            {useMemo(
              () => (
                <EventCalendar
                  initialView="dayGridMonth"
                  initialDate="2021-08-10"
                  events={calendarEventsData}
                  selectable
                  editable
                />
              ),
              [calendarEventsData]
            )}
          </Grid>
          <Grid item xs={12} xl={3}>
            <MDBox mb={3}>
              <NextEvents />
            </MDBox>
          </Grid>
        </Grid> */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Calendar;
