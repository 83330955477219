import { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useNavigate } from "react-router-dom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";  // Import MDButton for the button
import ParcoursCard from "./components/ParcoursCard";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import useStore from "services/store"; // Import Zustand store
import { AbilityContext } from "Can";
import { set } from "date-fns";
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "./components/PlatformSettings";
import Divider from "@mui/material/Divider";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import profilesListData from "./data/profilesListData";
import ProfilesList from "examples/Lists/ProfilesList";
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
//import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import Chart from "react-apexcharts";
import ApexChart from "./components/Apexchart";
import ApexChartBar from "./components/Apexchart_bar";
import ApexChartBarTarget from "./components/Apexchart_bar-target";

import ApexChartBarTargetMultiple from "./components/Apexchart_bar-target-multiple";
import { AuthContext } from "context";

function ParcoursGrille() {
  const userUid = useStore((state) => state.userId);
  const { selectedPromotion } = useStore((state) => ({ selectedPromotion: state.selectedPromotion, }));

  const { loading } = useContext(AuthContext);
  const [cardData, setCardData] = useState([]);
  const navigate = useNavigate();
  const ability = useContext(AbilityContext);
  const [matiere, setMatiere] = useState("");
  const [matiereid, setMatiereId] = useState("");

  const response = ability.can("create", "parcours")
  console.log("trouille", response)

  // Use Zustand store to retrieve the selected subject (matière)
  const selectedMatiere = useStore((state) => state.selectedMatiere);
  console.log("infecté : ", selectedMatiere);
  // Update matiere state when selectedMatiere changes
  useEffect(() => {
    if (selectedMatiere) {
      setMatiere(selectedMatiere);
      setMatiereId(selectedMatiere.identifiant_matiere);
    }
  }, [selectedMatiere]);

  useEffect(() => {
    if (matiere) {
      const canCreateParcours = ability.can("create", "parcours", matiereid.toString());
      console.log("matiereCondition then", canCreateParcours, matiereid.toString(), loading);
      // Fetch parcours data for the selected matiere

      const fetchParcoursData = async (matiereId) => {
        try {
          const data = await crudsServiceFAPI.getParcoursByMatiereId(matiereId);

          if (data.length === 0) {
            // Si aucun parcours n'est trouvé, définir cardData à un tableau vide
            setCardData([]);
          } else {
            // Sinon, formater les données et les ajouter à cardData
            const formattedData = data.map((item) => ({
              image: `${process.env.REACT_APP_FAPI_URL}/files/parcours/${item.identifiant_parcours}.jpg`,
              title: item.titre_parcours,
              description: item.description_parcours,
              identifiant_parcours: item.identifiant_parcours,
              idMatiere: matiereId.toString(),
            }));
            console.log("Formatted parcours data:", formattedData);
            setCardData(formattedData);
          }
        } catch (error) {
          console.error("Error fetching parcours data:", error);
          setCardData([]);  // Réinitialiser cardData en cas d'erreur également
        }
      };

      fetchParcoursData(matiereid); // Pass the ID of the selected subject
    }
  }, [matiere, matiereid, ability]); // Ajout de matiereid et ability comme dépendances

  const [seriesData, setSeriesData2] = useState([]);

  const seriesDataMultiple = [
    {
      name: 'Entraînement',
      data: [
        { x: 'Série A', y: 30, goals: [{ name: 'Cible', value: 35, strokeWidth: 5, strokeColor: '#FF4560' }] },
        { x: 'Série B', y: 40, goals: [{ name: 'Cible', value: 45, strokeWidth: 5, strokeColor: '#FF4560' }] },
        { x: 'Série C', y: 35, goals: [{ name: 'Cible', value: 40, strokeWidth: 5, strokeColor: '#FF4560' }] },
        // Ajoutez autant de points que nécessaire
      ],
    },
    {
      name: 'Évaluation',
      data: [
        { x: 'Série A', y: 20, goals: [{ name: 'Cible', value: 25, strokeWidth: 5, strokeColor: '#FEB019' }] },
        { x: 'Série B', y: 29, goals: [{ name: 'Cible', value: 30, strokeWidth: 5, strokeColor: '#FEB019' }] },
        { x: 'Série C', y: 37, goals: [{ name: 'Cible', value: 35, strokeWidth: 5, strokeColor: '#FEB019' }] },
        // Ajoutez autant de points que nécessaire
      ],
    },
  ];

  useEffect(() => {
    const fetchRatios = async () => {
      if (userUid && matiereid) {  // S'assurer que les deux sont définis
        try {
          console.log("handicap : ", userUid, matiereid);
          const data = await crudsServiceFAPI.getRatiosByEleveParcours(userUid, matiereid); // Utiliser matiereid ici
          
          console.log("Fetching ratios for user:", data);
          setSeriesData2(data);  // Mettre à jour avec les nouvelles données
        } catch (error) {
          console.error("Error fetching ratios:", error);
          setSeriesData2([]);  // Réinitialiser en cas d'échec
        }
      } else if (selectedPromotion && matiereid) {
        console.log("handicapeleve : ", selectedPromotion, matiereid);
        try {
          const data = await crudsServiceFAPI.getRatiosByPromotionParcours(selectedPromotion.identifiant_promotion, matiereid); // Utiliser matiereid ici
          console.log("Fetching ratios for promotion:", data);
          setSeriesData2(data);  // Mettre à jour avec les nouvelles données
        } catch (error) {
          console.error("Error fetching ratios:", error);
          setSeriesData2([]);  // Réinitialiser en cas d'échec
        }
      }
    };

    fetchRatios();
  }, [userUid, matiereid, selectedPromotion]); // Ajout de selectedPromotion comme dépendance

  console.log("cheveux : ", seriesData)
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
          <Grid item xs={12}>
  <MDBox display="flex" alignItems="center">
    {ability.can("create", "parcours") && matiere && (
      <MDButton
        variant="gradient"
        color="dark"
        onClick={() => {
          console.log("Navigating to:", `/parcours/parcours-gestion/parcours-nouveau?matiereId=${matiereid}`);
          navigate(`/parcours/parcours-gestion/parcours-nouveau?matiereId=${matiereid}`);
        }}
      >
        Ajouter un parcours
      </MDButton>
    )}
    
  </MDBox>
</Grid>

           
            {/* Deuxième graphique (ApexChartBarTarget) prenant toute la largeur */} 

            {/* <Grid item xs={12} > {/* Prendre 100% de la largeur 
              {seriesData && Array.isArray(seriesData) && seriesData.length > 0 ? (
                <ApexChartBarTarget seriesData={seriesData} height={"auto"} />
              ) : (
                <MDTypography variant="body1">Aucune donnée disponible.</MDTypography>
              )}
            </Grid> 
            */}

            <Grid item xs={12} > {/* Prendre 100% de la largeur */} 
              {seriesData && Array.isArray(seriesData) && seriesData.length > 0 ? (
                <ApexChartBarTargetMultiple seriesData={seriesData} height={"auto"} />
              ) : (
                <MDTypography variant="body1">Aucune donnée disponible.</MDTypography>
              )}
            </Grid>

            
          </Grid>
        </MDBox>
      </Header>

      <Grid container spacing={3} alignItems="stretch">
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <MDBox display="flex" height="100%" alignItems="stretch">
              <ParcoursCard
                title={card.title}
                description={card.description}
                identifiant_parcours={card.identifiant_parcours}
                idMatiere={card.idMatiere}
                sx={{
                  flex: 1, // Permet à la carte de prendre tout l'espace disponible de manière égale
                  display: "flex",
                  flexDirection: "column", // Gérer la disposition interne en colonne
                  justifyContent: "space-between", // S'assurer que le contenu est bien espacé
                  maxWidth: "100%", // Empêche la carte de devenir trop large
                  height: "auto", // Permet à la hauteur de s'adapter au contenu
                  boxSizing: "border-box", // Inclut les bordures et les paddings dans la taille totale
                }}
              />
            </MDBox>
          </Grid>
        ))}
      </Grid>

      <Footer />
    </DashboardLayout>
  );
}

export default ParcoursGrille;
