import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ParcoursImages from "layouts/parcours/parcours-espace/parcours-page/components/ParcoursImages";
import ValidationImage from "./components/ValidationImage.js";
import ValidationsInfo from "./components/ParcoursInfo";
import ActiviteValidationCard from "./components/ActiviteValidationCard";
import ActiviteValidationCardProf from "./components/ActiviteValidationCardProf";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import { AbilityContext } from "Can";
import { AuthContext } from "context";
import useStore from "services/store";


function ValidationsPage() {
  const { userUid, userIdentite, userEtab, userRights, role } = useContext(AuthContext);
  const { id } = useParams();
  const { userId } = useStore((state) => state);
 
  console.log("BronzageuserUid : ",userId)
  const ability = useContext(AbilityContext);
 
  const [cardData, setCardData] = useState([]);
  const [expandedCardId, setExpandedCardId] = useState(null);
  const [loading, setLoading] = useState(false); // State for loading

  
  const matiereid = useStore((state) => state.selectedMatiere) || {};



  //console.log("nuit : ",userUid, role, matiereid)

  useEffect(() => {
    if (id) {
  
      const timestamp = new Date().getTime(); // Cache busting en utilisant un timestamp unique
      const imagePath = `${process.env.REACT_APP_FAPI_URL}/files/activites/${identifiant_activite}.jpg?t=${timestamp}`;

      // Vérifier si l'image existe
      fetch(imagePath)
        .then((response) => {
          if (response.ok) {
            setImageUrl(imagePath);
          } else {
            // Si l'image n'existe pas, utiliser une image par défaut
            setImageUrl(`${process.env.REACT_APP_FAPI_URL}/files/activites/defaut.jpg?t=${timestamp}`);
          }
          setIsReady(true);
        })
        .catch(() => {
          // En cas d'erreur, utiliser une image par défaut
          setImageUrl(`${process.env.REACT_APP_FAPI_URL}/files/activites/defaut.jpg?t=${timestamp}`);
          setIsReady(true);
        });
    }
  }, [id]);

  

  // Function to fetch activities/validations data
  useEffect(() => {
    const fetchActivites = async () => {
      if (!userUid || !matiereid || !matiereid.identifiant_matiere || !role) {
        console.log("Certaines informations ne sont pas encore disponibles, annulation de la requête...");
        return;
      }
  
      setLoading(true);
      setCardData([]);
  
      try {
        let activities;
        console.log("Fetching activities for user with role:", role, userUid, userId);
  
        if (role === "professeur" || role === "administrateur" || role === "gestionnaire" || role === "superadministrateur" ) {
          if(userId){
            console.log("abc admin visiteur : ", userUid, matiereid);
            activities = await crudsServiceFAPI.getValidationsByEleveMatiere(userId, matiereid.identifiant_matiere);
            console.log("abc admin visiteur recup :", activities);
          } else {
            console.log("artichaud")
            console.log("abc admin sans visiteurs : ", userUid, matiereid);
            activities = await crudsServiceFAPI.getValidationsByProfMatiere(userUid, matiereid.identifiant_matiere);
            console.log("abc admin visiteur recup :", activities);
          }
        } else {
          console.log("abc20 : ", userUid, matiereid);
          activities = await crudsServiceFAPI.getValidationsByEleveMatiere(userId, matiereid.identifiant_matiere);
          console.log("abc22 :", activities);
        }
  
        console.log("abc3 :", activities);
  
        const timestamp = Date.now(); // Utiliser un timestamp pour éviter le cache
  
        const checkImageExistence = async (url) => {
          try {
            const response = await fetch(url, { method: 'HEAD' });
            return response.ok; // Retourne true si l'image existe (code 200)
          } catch (error) {
            return false; // En cas d'erreur (ex. code 404), retourne false
          }
        };
  
        const formattedActivities = await Promise.all(
          activities.map(async (activity) => {
            console.log("feuille : ", activity, `${process.env.REACT_APP_FAPI_URL}/files/activites/${activity.identifiant_activite}.jpg`)
            const imageUrl = `${process.env.REACT_APP_FAPI_URL}/files/activites/${activity.identifiant_activite}.jpg`;
            const defaultImageUrl = `${process.env.REACT_APP_FAPI_URL}/files/activites/defaut.jpg?t=${timestamp}`;
            const imageExists = await checkImageExistence(imageUrl);
  
            return {
              // image: imageExists ? imageUrl : defaultImageUrl,
              image: imageUrl,
              identifiant_parcours: activity.identifiant_parcours,
              titre: activity.titre_activite|| "Titre non fourni",
              description: activity.description_activite || "Description non fournie",
              details: activity.detail_activite || "Détails non fournis",
              evaluation: activity.evaluation_activite || "Évaluation non fournie",
              livrables: activity.livrables_activite || "Livrables non fournis",
              todo: activity.todo_activite || "Tâches non fournies",
              status: activity.status_validation,
              competences: activity.precedente_validation?.competences || activity.en_cours_de_verification?.competences || activity.competences || [],
              anciennes_validations: activity.precedentes_validations || [],
              encours_validation: activity.en_cours_de_verification?.competences || [],
              competences_actuelles: activity.competences || [],
              lien_validation: activity.lien_validation || "Lien non disponible",
              date_validation: activity.date_validation || "Date non fournie",
              message_validation: activity.message_validation || "Pas de messages",
              identifiant_validation: parseInt(activity.identifiant_validation) || 0,
              nom_eleve: activity.nom_eleve || "Pas de nom",
              prenom_eleve: activity.prenom_eleve || "Pas de prénom",
              email: activity.email || "Pas d'email",
              identifiant_activite: activity.identifiant_activite,
              role: role,
            };
          })
        );
        
        setCardData(formattedActivities);
        console.log("brisbar : ", formattedActivities)
      } catch (error) {
        console.error("Erreur lors de la récupération des activités :", error);
      } finally {
        setLoading(false);
      }
    };
  
    if (userUid && matiereid.identifiant_matiere && role) {
      fetchActivites();
    } else {
      console.log("Attente de la disponibilité de toutes les données...");
    }
  }, [userUid, userId, matiereid.identifiant_matiere, role]);
  
  const handleExpandCard = (identifiant_parcours) => {
    setExpandedCardId(expandedCardId === identifiant_parcours ? null : identifiant_parcours);
  };

  // Filter activities by status
  const enCoursDeVerification = cardData.filter((card) => card.status === "En cours de vérification");
  const verifiees = cardData.filter((card) => card.status === "Vérifiée");

  // Choose the correct component based on user role
  //const CardComponent = role === "admin" ? ActiviteValidationCardProf : ActiviteValidationCard;
  const CardComponent = ActiviteValidationCardProf;


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card sx={{ overflow: "visible" }}>
          <MDBox p={3}>
            <MDBox mb={3} display="flex" justifyContent="space-between" alignItems="center">
              {/* <MDTypography variant="h5" fontWeight="medium">
                Espace validations
              </MDTypography> */}
            </MDBox>

            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} xl={5}>
                <ValidationImage />
              </Grid>
              <Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
                <ValidationsInfo />
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>

      {/* Display based on loading status */}
      {loading ? (
        <MDTypography variant="h6" align="center">
          Chargement des activités...
        </MDTypography>
      ) : (
        <>
          {/* Section for ongoing verifications */}
          <MDBox flex="1" mt={2}>
            <MDTypography variant="h6" fontWeight="bold" mb={2}>
              En cours de vérification
            </MDTypography>
            <Grid container spacing={3}>
              {enCoursDeVerification.map((card, index) => {
                const isExpanded = expandedCardId === card.identifiant_parcours;

                return (
                  <Grid item xs={12} sm={6} md={3} key={index}>

                    <MDBox mt={3}>
                      <CardComponent
                        image={card.image}
                        description={card.description}
                        details={card.details}
                        titre={card.titre}
                        evaluation={card.evaluation}
                        livrables={card.livrables}
                        todo={card.todo}
                        onExpand={() => handleExpandCard(card.identifiant_parcours)}
                        expanded={isExpanded}
                        status={card.status}
                        competencesTab={card.competences}
                        encours_validation={card.encours_validation}
                        anciennes_validations={card.anciennes_validations}
                        competences_actuelles={card.competences_actuelles}
                        lien_validation={card.lien_validation}
                        date_validation={card.date_validation}
                        message_validation={card.message_validation}
                        identifiant_validation={card.identifiant_validation}
                        nom_eleve={card.nom_eleve}
                        prenom_eleve={card.prenom_eleve}
                        email={card.email}
                        role={card.role}
                        message_validation_old={card.message_validation_old}
                        identifiant_activite={card.identifiant_activite}
                       
                      />
                    </MDBox>
                  </Grid>
                );
              })}
            </Grid>
          </MDBox>

          {/* Section for verified activities */}
          <MDBox flex="1" mt={5}>
            <MDTypography variant="h6" fontWeight="bold" mb={2}>
              Vérifiées
            </MDTypography>
            <Grid container spacing={3}>
              {verifiees.map((card, index) => {
                const isExpanded = expandedCardId === card.identifiant_parcours;

                return (
                  <Grid item xs={12} sm={6} md={3} key={index}>

                    <MDBox mt={3}>
                      <CardComponent
                        image={card.image}
                        description={card.description}
                        details={card.details}
                        titre={card.titre}
                        evaluation={card.evaluation}
                        livrables={card.livrables}
                        todo={card.todo}
                        onExpand={() => handleExpandCard(card.identifiant_parcours)}
                        expanded={isExpanded}
                        status={card.status}
                        competencesTab={card.competences}
                        encours_validation={card.encours_validation}
                        anciennes_validations={card.anciennes_validations}
                        competences_actuelles={card.competences_actuelles}
                        lien_validation={card.lien_validation}
                        date_validation={card.date_validation}
                        message_validation={card.message_validation}
                        id_validation={card.id_validation}
                        nom_eleve={card.nom_eleve}
                        prenom_eleve={card.prenom_eleve}
                        email={card.email}
                      />
                    </MDBox>
                  </Grid>
                );
              })}
            </Grid>
          </MDBox>
        </>
      )}

      <Footer />
    </DashboardLayout>
  );
}

export default ValidationsPage;
