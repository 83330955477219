import React, { useState, useEffect, useContext, useCallback } from "react";
import ReactApexChart from "react-apexcharts";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import useStore from "services/store";
import { Typography, Box, CircularProgress } from "@mui/material";
import { AuthContext } from "context";

const ApexChartCruxParcours = ({ identifiantParcours }) => {
  const { role } = useContext(AuthContext);
  const { userId, selectedPromotion } = useStore((state) => ({
    userId: state.userId,
    selectedPromotion: state.selectedPromotion,
  }));

  console.log("Role de l'utilisateur : ", role);
  console.log("userId : ", userId);
  console.log("selectedPromotion : ", selectedPromotion);

  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "heatmap",
      toolbar: { show: true },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.3,
        radius: 10,
        colorScale: {
          ranges: [
            { from: 101, to: Infinity, name: "Excellence", color: "#128FD9" },
            { from: 80, to: 100, name: "Bonne maîtrise", color: "#00A100" },
            { from: 50, to: 79.99, name: "Maîtrise partielle", color: "#FFB200" },
            { from: 0, to: 49.99, name: "Non maîtrisé", color: "#FF0000" },
          ],
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: { colors: ["#fff"], fontSize: "12px", fontWeight: "bold" },
    },
    stroke: { width: 3, colors: ["#ffffff"] },
    xaxis: { type: "category", categories: categories },
    yaxis: {
      title: { text: "Ratio (%)" },
      labels: { style: { fontSize: "12px" } },
    },
    tooltip: {
      y: {
        formatter: (val) => `${val.toFixed(2)}%`,
      },
    },
  });

  // Fonction pour mapper la réponse API aux séries et catégories d'ApexCharts.
  // On y calcule le ratio pour chaque point (points_remportes / points_vises * 100)
  const mapSeries = (response) => {
    if (response.series && Array.isArray(response.series)) {
      const isIndividual =
        response.series.length > 0 && response.series[0].label;

      const newCategories = isIndividual
        ? response.series
            .flatMap((item) => item.data.map((point) => point.x))
            .filter((v, i, a) => a.indexOf(v) === i)
        : response.series.map((item) => item.x);

        const newSeries = isIndividual
  ? response.series.map((item) => {
      const newData = item.data.map((point) => {
        console.log("Point avant calcul du ratio :", point);
        const ratio =
          point.points_vises > 0
            ? Math.ceil((point.y / point.points_vises) * 100)
            : 0;
        console.log("Ratio calculé :", ratio);
        return { ...point, y: ratio };
      });
      return { name: item.label, data: newData };
    })
      
        : [
            {
              name: "Total",
              data: response.series.map((item) => {
                const ratio =
                  item.points_vises > 0
                    ? (item.points_remportes / item.points_vises) * 100
                    : 0;
                return { x: item.x, y: ratio };
              }),
            },
          ];

      return { newSeries, newCategories };
    } else {
      console.error("Structure de réponse incorrecte");
      return { newSeries: [], newCategories: [] };
    }
  };

  // Fonction pour récupérer les données d'activités
  const fetchPoints = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      if (!selectedPromotion?.identifiant_promotion) {
        throw new Error("Aucun identifiant de promotion disponible.");
      }
      if (!identifiantParcours) {
        throw new Error("Aucun identifiant de parcours disponible.");
      }
      console.log(
        "Fetching promotion points for promotion:",
        selectedPromotion.identifiant_promotion,
        "et parcours:",
        identifiantParcours
      );
      const response = await crudsServiceFAPI.getPromotionPointsActivites(
        selectedPromotion.identifiant_promotion,
        identifiantParcours
      );
      console.log("Fetched points:", response);

      // Mapper les séries et catégories en calculant le ratio dans chaque point
      const { newSeries, newCategories } = mapSeries(response);

      setSeries((prevSeries) => {
        if (JSON.stringify(prevSeries) !== JSON.stringify(newSeries)) {
          return newSeries;
        }
        return prevSeries;
      });

      setCategories((prevCategories) => {
        if (JSON.stringify(prevCategories) !== JSON.stringify(newCategories)) {
          return newCategories;
        }
        return prevCategories;
      });
    } catch (error) {
      console.error("Erreur lors de la récupération des points :", error);
      setError("Erreur lors de la récupération des points.");
    } finally {
      setLoading(false);
    }
  }, [selectedPromotion, userId, identifiantParcours]);

  // Déclencher fetchPoints lorsque les dépendances changent
  useEffect(() => {
    if (selectedPromotion?.identifiant_promotion && identifiantParcours) {
      fetchPoints();
    }
  }, [fetchPoints, selectedPromotion, identifiantParcours]);

  // Mettre à jour les options du graphique lorsque les catégories changent
  useEffect(() => {
    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: { ...prevOptions.xaxis, categories: categories },
    }));
  }, [categories]);

  return (
    <div>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Activités
        </Typography>
      </Box>

      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="350px"
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      ) : (
        <ReactApexChart options={options} series={series} type="heatmap" height={700} />
      )}
    </div>
  );
};

export default ApexChartCruxParcours;
