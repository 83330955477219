import React, { useState, useMemo, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Divider from "@mui/material/Divider";
import crudsServiceFAPI from "services/cruds-serviceFAPI";
import MDEditor from "components/MDEditor";
import { AuthContext } from "context";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import useStore from "services/store";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";



function AllActivitesCard({
  image,
  description,
  details,
  evaluation,
  livrables,
  todo,
  competencesTab,
  expanded,
  onExpand,
  identifiant_activite,
  titre,
}) {
  const { userUid, userIdentite, userEtab, userRights, role } = useContext(AuthContext);
  console.log("competencesTab", competencesTab);
 
  // État pour stocker les sélections des listes déroulantes pour chaque compétence
  const [competenceSelections, setCompetenceSelections] = useState({});
  const [message, setMessage] = useState(""); // État pour stocker le texte de MDEditor
  const [loading, setLoading] = useState(false); // État pour gérer l'attente lors de la validation
  const [competences, setCompetences] = useState([]);
  const [resourceList, setResourceList] = useState([]); // Liste des ressources associées

  // Fonction pour gérer la sélection dans la liste déroulante pour chaque compétence
  const handleSelectChange = (competenceId, value) => {
    setCompetenceSelections((prevSelections) => ({
      ...prevSelections,
      [competenceId]: value,
    }));
  };

  // Mémoriser la vérification des compétences sélectionnées pour améliorer les performances
  const allCompetencesSelected = useMemo(
    () => competencesTab.every((competence) => competenceSelections[competence.identifiant_competence]),
    [competencesTab, competenceSelections]
  );

  // Définition de la fonction checkImageExistence
  const checkImageExistence = async (url) => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok;
    } catch (error) {
      console.error(`Erreur lors de la vérification de l'image à l'URL ${url} :`, error);
      return false;
    }
  };

  useEffect(() => {
    const fetchCompetencesAndValidationLevels = async () => {
      if (identifiant_activite) {
        try {
          // Appel à l'API pour récupérer les compétences associées à l'activité
          const competencesResponse = await crudsServiceFAPI.getCompetencesByActivite(identifiant_activite);
          const competencesData = await Promise.all(
            competencesResponse.map(async (comp) => {
              // Récupérer les détails de chaque compétence par son identifiant
              const competenceDetails = await crudsServiceFAPI.getCompetenceById(comp.identifiant_competence);
              console.log("vemines : competenceDetails ", competenceDetails, "comp", comp);
              return {
                numero_competence: competenceDetails.numero_competence,
                identifiant_competence: competenceDetails.identifiant_competence,
                denomination_competence: competenceDetails.denomination_competence,
                niveau_competence_visee_referentiel: competenceDetails.niveau_competence_visee_referentiel,
                niveau_vise_referentiel: competenceDetails.niveau_competence_visee_referentiel, // Si nécessaire
                niveau_vise_activite: comp.niveau_vise_activite,
                points_competence_vises: comp.points_competence_vises,
                numero_bloc: competenceDetails.numero_bloc,
                denomination_bloc: competenceDetails.denomination_bloc,
                denomination_unite: competenceDetails.denomination_unite
              };
            })
          );
          console.log("kiwi : ", competencesData);
          setCompetences(competencesData);
        } catch (error) {
          console.error("Erreur lors de la récupération des données :", error);
        }
      }
    };

    fetchCompetencesAndValidationLevels();
  }, [identifiant_activite]);

  useEffect(() => {
    const fetchResources = async () => {
      if (expanded) {
        try {
          const response = await crudsServiceFAPI.getResourcesByActivite(identifiant_activite);
          setResourceList(response);
        } catch (error) {
          console.error("Erreur lors de la récupération des ressources :", error);
        }
      }
    };

    fetchResources();
  }, [expanded, identifiant_activite]);

  // Définir si l'utilisateur peut éditer l'activité (exemple)
  const canEditActivity = role === "admin"; // Ajustez selon votre logique

  const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false);
const [selectedNewParcours, setSelectedNewParcours] = useState("");
const [originalActiviteId, setOriginalActiviteId] = useState(null);

 // Modifiez la fonction handleAddToCart pour ouvrir la popup de duplication
 const handleAddToCart = (identifiantActivite) => {
  console.log("handleAddToCart appelé pour l'activité :", identifiantActivite);
  setOriginalActiviteId(identifiantActivite);
  setOpenDuplicateDialog(true);
};

// Fonction pour confirmer la duplication avec le parcours sélectionné
const handleConfirmDuplicate = async () => {
  if (!selectedNewParcours) return; // Vérifiez qu'un parcours est sélectionné
  const duplicateData = {
    original_activite_id: originalActiviteId,
    new_identifiant_parcours: selectedNewParcours,
  };
  try {
    const response = await crudsServiceFAPI.duplicateActivite(duplicateData);
    console.log("Activité dupliquée avec succès :", response);
    setOpenDuplicateDialog(false);
    setSelectedNewParcours("");
  } catch (error) {
    console.error("Erreur lors de la duplication de l'activité :", error);
  }
};

  // Placeholder pour les fonctions d'édition et de suppression des compétences
  const handleEditCompetence = (numero_competence, competences, setCompetences, identifiant_activite) => {
    // Implémentez votre logique d'édition ici
    console.log(`Éditer compétence ${numero_competence} pour l'activité ${identifiant_activite}`);
  };

  const handleDeleteCompetence = (numero_competence, identifiant_activite, niveau_vise_activite, competences, setCompetences) => {
    // Implémentez votre logique de suppression ici
    console.log(`Supprimer compétence ${numero_competence} pour l'activité ${identifiant_activite}`);
  };

   const selectedMatiere = useStore((state) => state.selectedMatiere);
  
    const [parcours, setParcours] = useState([])

    useEffect(() => {
      const fetchParcours = async () => {
        try {
          const response = await crudsServiceFAPI.getParcoursByMatiereId(selectedMatiere.identifiant_matiere);
          console.log("Parcours récupérés : ", response);
          setParcours(response);
        } catch (error) {
          console.error("Erreur lors de la récupération des référentiels :", error);
        }
      };
  
      fetchParcours();
    }, [selectedMatiere]);
  
    console.log("hallucine : ", parcours)
  
   
    const [newParcoursId, setNewParcoursId] = useState(15); // Exemple : l'identifiant du nouveau parcours
  
    const handleDuplicateActivite = async () => {
      const duplicateData = {
        original_activite_id: originalActiviteId,
        new_identifiant_parcours: newParcoursId,
      };
  
      try {
        const response = await crudsServiceFAPI.duplicateActivite(duplicateData);
        console.log("Activité dupliquée avec succès :", response);
        // Vous pouvez ici mettre à jour votre état ou afficher un message de succès
      } catch (error) {
        console.error("Erreur lors de la duplication de l'activité :", error);
      }
    };

  return (
    <Card
      sx={{
        transition: "all 0.3s ease",
        maxWidth: "auto", // Augmenter la largeur de la carte
        height: "auto", // La carte s'ajuste automatiquement
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: expanded
          ? "0px 4px 20px rgba(0, 0, 0, 0.12)"
          : "0px 2px 10px rgba(0, 0, 0, 0.1)",
        marginBottom: expanded ? "20px" : "10px",
      }}
    >
      {/* Section image */}
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={2}
        mx={2}
        className="card-header"
        sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}
      >
        <MDBox
          component="img"
          src={image}
          alt="Activité"
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="auto"
        />
      </MDBox>

      <MDBox textAlign="center" pt={3} px={3} flexGrow={1}>
        <MDTypography variant="h6" component="h2">
          {titre}
        </MDTypography>
        <MDTypography 
          variant="body2"
          color="text"
          component="div"
          dangerouslySetInnerHTML={{ __html: description }} 
        />
        {/* Ajoutez d'autres sections si nécessaire */}
      </MDBox>

      <Divider />

      {/* Bouton pour afficher les détails et ajouter au panier */}
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} pb={3} px={3}>
        <MDButton variant="text" color="info" onClick={onExpand}>
          {expanded ? "Réduire" : "Voir détails"}
        </MDButton>
        <IconButton
  color="primary"
  aria-label="Ajouter au panier"
  onClick={() => handleAddToCart(identifiant_activite)}
>
  <AddShoppingCartIcon />
</IconButton>
      </MDBox>

      {/* Détails de l'activité si carte expandée */}
      {expanded && (
        <MDBox p={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                <MDTypography variant="h6" fontWeight="bold">
                  Compétences Associées
                </MDTypography>
                {competences.length > 0 ? (
                  <MDBox>
                    {competences.map((competence, index) => (
                      <Card key={competence.identifiant_competence} sx={{ p: 2, mb: 2, boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}>
                        <MDBox
                          sx={{ marginBottom: "8px", display: "flex", alignItems: "center", justifyContent: "space-between" }}
                        >
                          <MDTypography variant="body2" sx={{ fontSize: "0.875rem" }}>
                            La compétence <span style={{ fontWeight: "bold", color: "blue" }}>{competence.numero_competence}</span> - 
                            <span style={{ fontWeight: "bold", color: "blue" }}> {competence.denomination_competence} </span>  
                            du bloc <span style={{ fontWeight: "bold", color: "blue" }}>{competence.numero_bloc}</span> -
                            <span style={{ fontWeight: "bold", color: "blue" }}> {competence.denomination_bloc}</span> est attendue dans le référentiel au niveau 
                            <span style={{ fontWeight: "bold", color: "blue" }}> {competence.niveau_vise_referentiel}</span>. 
                            Dans cette activité, vous la travaillerez au niveau <span style={{ fontWeight: "bold", color: "blue" }}>{competence.niveau_vise_activite} </span> 
                            qui vous permettra de remporter <span style={{ fontWeight: "bold", color: "blue" }}>{competence.points_competence_vises}</span> points dans ce niveau.
                          </MDTypography>
                          {canEditActivity && (
                            <MDBox>
                              {/* Icône Modifier */}
                              <IconButton
                                onClick={() => handleEditCompetence(competence.numero_competence, competences, setCompetences, identifiant_activite)}
                              >
                                <EditIcon />
                              </IconButton>
                              {/* Icône Supprimer */}
                              <IconButton
                                onClick={() => handleDeleteCompetence(competence.numero_competence, identifiant_activite, competence.niveau_vise_activite, competences, setCompetences)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </MDBox>
                          )}
                        </MDBox>
                      </Card>
                    ))}
                  </MDBox>
                ) : (
                  <MDTypography variant="body2" color="text">
                    Aucune compétence associée trouvée pour cette activité.
                  </MDTypography>
                )}
              </Card>
            </Grid>
            <Grid item xs={12}>
              {/* Bloc 1 - Détails */}
              <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                <MDTypography variant="h6" fontWeight="bold">
                  Détails de l'activité
                </MDTypography>
                <MDTypography
                  variant="body2"
                  color="text"
                  component="div"
                  dangerouslySetInnerHTML={{ __html: details }}
                />
              </Card>
            </Grid>

            {/* Bloc 2 - Évaluation */}
            <Grid item xs={12}>
              <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                <MDTypography variant="h6" fontWeight="bold">
                  Évaluation
                </MDTypography>
                <MDTypography
                  variant="body2"
                  color="text"
                  component="div"
                  dangerouslySetInnerHTML={{ __html: evaluation }}
                />
              </Card>
            </Grid>

            {/* Bloc 3 - Livrables */}
            <Grid item xs={12}>
              <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                <MDTypography variant="h6" fontWeight="bold">
                  Livrables
                </MDTypography>
                <MDTypography
                  variant="body2"
                  color="text"
                  component="div"
                  dangerouslySetInnerHTML={{ __html: livrables }}
                />
              </Card>
            </Grid>

            {/* Bloc 4 - To-do */}
            <Grid item xs={12}>
              <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                <MDTypography variant="h6" fontWeight="bold">
                  À faire (To-do)
                </MDTypography>
                <MDTypography
                  variant="body2"
                  color="text"
                  component="div"
                  dangerouslySetInnerHTML={{ __html: todo }}
                />
              </Card>
            </Grid>

            {/* Bloc 5 - Ressources */}
            <Grid item xs={12}>
              <Card sx={{ p: 3, mb: 3, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)" }}>
                <MDTypography variant="h6" fontWeight="bold">
                  Ressources
                </MDTypography>
                {resourceList.length > 0 ? (
                  <MDBox>
                    {resourceList.map((resource, index) => (
                      <MDBox key={index} sx={{ marginBottom: "8px" }}>
                        <MDTypography
                          variant="body2"
                          sx={{ fontSize: "0.875rem" }} // Taille du texte réduite
                        >
                          <a
                            href={`${process.env.REACT_APP_FAPI_URL}/files/ressources/${resource.nom_ressource}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {resource.nom_ressource}
                          </a>
                        </MDTypography>
                      </MDBox>
                    ))}
                  </MDBox>
                ) : (
                  <MDTypography variant="body2" color="text">
                    Aucune ressource associée.
                  </MDTypography>
                )}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      )}
      <Dialog
  open={openDuplicateDialog}
  onClose={() => setOpenDuplicateDialog(false)}
  fullWidth
  maxWidth="sm"
>
  <DialogTitle>Ajouter au panier</DialogTitle>
  <DialogContent>
    <FormControl fullWidth>
      <InputLabel id="select-parcours-label">Sélectionnez un parcours</InputLabel>
      <Select
        labelId="select-parcours-label"
        value={selectedNewParcours}
        onChange={(e) => setSelectedNewParcours(e.target.value)}
        label="Sélectionnez un parcours"
      >
        {parcours.map((p) => (
          <MenuItem key={p.identifiant_parcours} value={p.identifiant_parcours}>
            {p.titre_parcours}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </DialogContent>
  <DialogActions>
    <MDButton onClick={() => setOpenDuplicateDialog(false)} color="secondary">
      Annuler
    </MDButton>
    <MDButton onClick={handleConfirmDuplicate} variant="contained" color="primary">
      Confirmer
    </MDButton>
  </DialogActions>
</Dialog>

    </Card>
    
  );
  
}

AllActivitesCard.propTypes = {
  titre: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  details: PropTypes.string,
  evaluation: PropTypes.string,
  livrables: PropTypes.string,
  todo: PropTypes.string,
  competencesTab: PropTypes.arrayOf(
    PropTypes.shape({
      identifiant_competence: PropTypes.number,
      numero_competence: PropTypes.string,
      denomination_competence: PropTypes.string,
      niveau_vise_activite: PropTypes.number,
      points_competence_vises: PropTypes.number,
    })
  ),
  expanded: PropTypes.bool,
  onExpand: PropTypes.func.isRequired,
  identifiant_activite: PropTypes.number.isRequired, // Assurez-vous qu'il est requis si nécessaire
  // identifiant_validation: PropTypes.number.isRequired, // Supprimez si non utilisé
};

export default AllActivitesCard;
