import React, { useState, useMemo, useContext } from "react"; // Importer useState et useMemo pour optimiser les calculs
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Divider from "@mui/material/Divider";
import crudsServiceFAPI from "services/cruds-serviceFAPI"; // Importer le service CRUD
import MDEditor from "components/MDEditor";
import { AuthContext } from "context";


function ActiviteValidationCardProf({
  titre,
  nom_eleve,
  prenom_eleve,
  email,
  image,
  description,
  details,
  evaluation,
  livrables,
  todo,
  competencesTab,
  status,
  lien_validation,
  date_validation,
  message_validation,
  expanded,
  onExpand,
  identifiant_validation,
  encours_validation, 
  anciennes_validations,
  competences_actuelles,  
  identifiant_activite,
  
}) {
  const { userUid, userIdentite, userEtab, userRights, role } = useContext(AuthContext);
  console.log("exclus : ", userIdentite)
  console.log("brice : ", anciennes_validations, titre, image)
  // État pour stocker les sélections des listes déroulantes pour chaque compétence
  const [competenceSelections, setCompetenceSelections] = useState({});
  const [message, setMessage] = useState(""); // État pour stocker le texte de MDEditor
  const [loading, setLoading] = useState(false); // État pour gérer l'attente lors de la validation

  // Fonction pour gérer la sélection dans la liste déroulante pour chaque compétence
  const handleSelectChange = (competenceId, value) => {
    setCompetenceSelections((prevSelections) => ({
      ...prevSelections,
      [competenceId]: value,
    }));
  };

  const d = new Date(date_validation);
const formattedDate = d.toLocaleDateString("fr-FR", {
  day: "numeric",
  month: "long",
  year: "numeric",
});
const formattedTime = d
  .toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" })
  .replace(":", "h");

  // Mémoriser la vérification des compétences sélectionnées pour améliorer les performances
  const allCompetencesSelected = useMemo(
    () => competencesTab.every((competence) => competenceSelections[competence.identifiant_competence]),
    [competencesTab, competenceSelections]
  );

  // Fonction pour envoyer les données sélectionnées au serveur
  const handleConfirmValidation = async () => {
    if (!allCompetencesSelected || !message) {
      alert("Veuillez sélectionner un niveau pour toutes les compétences et fournir un message de validation.");
      return;
    }
  
    setLoading(true); // Activer le chargement
    try {
      // Première partie : validation des compétences
      for (const [competenceId, niveauValidation] of Object.entries(competenceSelections)) {
        const competenceData = {
          identifiant_competence: parseInt(competenceId),
          identifiant_validation: identifiant_validation,
          niveau_validation_competence: niveauValidation,
          message_validation: message,
          identifiant_activite: identifiant_activite
        };
        console.log("Flute", competenceData);
        // Appel au service CRUD pour ajouter la validation de compétence
        await crudsServiceFAPI.addCompetenceValidation(competenceData);
      }
      
      // Seconde partie : envoi du DM de vérification
      try {
        const discordEleve = await crudsServiceFAPI.getDiscordId(userIdentite.identifiant);
        console.log("psycide : ", userIdentite.identifiant, discordEleve, titre, date_validation, message_validation);
        const response = await crudsServiceFAPI.sendVerificationDM(discordEleve.discord_id, titre, date_validation, message);
        console.log("Message de vérification envoyé :", response);
      } catch (error) {
        console.error("Erreur lors de l'envoi du DM de vérification :", error);
        // Vous pouvez choisir d'alerter ou non l'utilisateur sur l'erreur du DM ici
      }
      
      alert("Compétences validées avec succès !");
    } catch (error) {
      console.error("Erreur lors de la validation des compétences :", error);
      alert("Une erreur est survenue lors de la validation.");
    } finally {
      setLoading(false); // Désactiver le chargement
      window.location.reload(); // Recharger la page, quelle que soit l'issue
    }
  };
  

  // Fonction pour afficher la section des compétences
// Fonction pour afficher la section des compétences
const renderCompetencesSection = () => (
  <MDBox mt={2}>
    {/* Section des anciennes validations */}
    <MDTypography variant="h6" fontWeight="bold" gutterBottom>
      Anciennes validations
    </MDTypography>
    {anciennes_validations.length > 0 ? (
        anciennes_validations.map((validation) => (
          <MDBox key={validation.identifiant_validation} mt={2} p={2} borderRadius="md" border="1px solid #e0e0e0">
            {/* Section des détails de la validation */}
            <MDBox mb={1}>
              <MDTypography variant="h6" color="primary">
                Validation du {new Date(validation.date_validation).toLocaleDateString()}
              </MDTypography>
              <MDTypography
  variant="body2"
  color="text"
  dangerouslySetInnerHTML={{
    __html: `<strong>Message du professeur :</strong> ${validation.message_validation || "Pas de message"}`
  }}
/>
              <MDTypography variant="body2" color="text">
                <strong>Points Remportés :</strong> {validation.points_remportes}/{validation.points_competence_vises}
              </MDTypography>
            </MDBox>

            {/* Section des compétences associées */}
            <MDBox>
              <MDTypography variant="subtitle1" color="text">
                <strong>Compétences :</strong>
              </MDTypography>
              {(validation?.competences?.length || 0) > 0 ? (
                validation.competences.map((competence) => (
                  <MDBox key={competence.identifiant_competence} mt={1} ml={2}>
                    <MDTypography variant="body2" color="text">
                      <strong>Compétence :</strong> {competence.denomination_competence} <br />
                      <strong>Niveau Validé :</strong> {competence.niveau_validation_competence} <br />
                      <strong>Points Remportés :</strong> {competence.points_remportes}/{competence.points_competence_vises} <br />
                    </MDTypography>
                  </MDBox>
                ))
              ) : (
                <MDTypography variant="body2" color="text" ml={2}>
                  Aucune compétence associée à cette validation.
                </MDTypography>
              )}
            </MDBox>

            {/* Séparateur entre les validations */}
            <Divider sx={{ my: 2 }} />
          </MDBox>
        ))
      ) : (
        <MDTypography variant="body2" color="text">
          Aucune ancienne validation pour cette activité.
        </MDTypography>
      )}


    {/* Section des compétences en cours de validation */}
    
    {encours_validation.length > 0 && (
  <>
    <MDTypography variant="h6" fontWeight="bold" gutterBottom>
      Compétences en cours de validation
    </MDTypography>
    {encours_validation.map((competence) => (
      <MDBox key={competence.identifiant_competence} mt={2}>
        <MDTypography variant="body2" color="text">
          <strong>Compétence :</strong> {competence.denomination_competence} <br />
          Niveau visé : {competence.niveau_vise_activite} <br />
          Points visés : {competence.points_competence_vises} <br />
          {/* Date de soumission : {competence.timestamp} <br /> */}
        </MDTypography>
        
        {/* Affichage du select uniquement pour les compétences en cours de vérification */}
        {status === "En cours de vérification" && (role === "professeur" || role === "administrateur" || role === "gestionnaire" || role === "superadministrateur") && (
          <>
            <select
              defaultValue=""
              onChange={(e) => handleSelectChange(competence.identifiant_competence, e.target.value)}
              style={{ marginLeft: "10px", padding: "5px" }}
            >
              <option value="" disabled>
                Choisir un niveau
              </option>
              <option value="non conforme">non conforme</option>
              <option value="conforme">conforme</option>
              <option value="partiellement conforme">partiellement conforme</option>
              <option value="excellence">excellence</option>
            </select>
          </>
        )}
        <Divider sx={{ my: 2 }} />
      </MDBox>
    ))}
  </>
)}


  </MDBox>
);

  // Fonction pour afficher le message de validation
  const renderValidationMessageSection = () => {
    if (status === "En cours de vérification" && (role === "professeur" || role === "administrateur" || role === "gestionnaire" || role === "superadministrateur")) {
      return (
        <MDBox mt={3} px={3}>
          <MDTypography variant="h6" fontWeight="bold">
            Message de validation
          </MDTypography>
          <MDEditor height={300} value={message} onChange={setMessage} />
        </MDBox>
      );
    }
    return null;
  };

  
  return (
    <Card
      sx={{
        transition: "all 0.3s ease",
        maxWidth: "auto", // Augmenter la largeur de la carte
        height: "auto", // La carte s'ajuste automatiquement
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: expanded
          ? "0px 4px 20px rgba(0, 0, 0, 0.12)"
          : "0px 2px 10px rgba(0, 0, 0, 0.1)",
        marginBottom: expanded ? "20px" : "10px",
      }}
    >
      {/* Section image */}
      <MDBox position="relative" borderRadius="lg" mt={2} mx={2} className="card-header" sx={{ display: "flex", alignItems: "flex-start", flexDirection: "column" }}>
        <MDBox component="img" src={image} alt="Activité" borderRadius="lg" shadow="md" width="100%" height="auto" />
      </MDBox>

      <MDBox textAlign="center" pt={3} px={3} flexGrow={1}>
        <MDTypography variant="h5" fontWeight="regular" sx={{ mt: 0.5 }}>
         {titre} 
        </MDTypography>
        <MDTypography variant="h10" fontWeight="regular" sx={{ mt: 0.9 }}>
          {nom_eleve} {prenom_eleve}
        </MDTypography>
        <MDTypography variant="body2" color="text" sx={{ mt: 1, mb: 1 }} dangerouslySetInnerHTML={{ __html: description }} />
        <Divider sx={{ my: 2 }} />

        {/* Appel de la section des compétences */}
        {renderCompetencesSection()}

        {/* Appel de la section du message de validation */}
        {renderValidationMessageSection()}

        <Divider sx={{ my: 2 }} />

        {/* Bouton de validation désactivé si une compétence n'est pas sélectionnée ou le message n'est pas rempli */}
        {status === "En cours de vérification" && (role === "professeur" || role === "administrateur" || role === "gestionnaire" || role === "superadministrateur") && (
          <MDBox mt={2} display="flex" justifyContent="center">
            <MDButton
              variant="contained"
              color="primary"
              onClick={handleConfirmValidation}
              disabled={!allCompetencesSelected || !message || loading} // Désactiver si pas rempli ou chargement
            >
              {loading ? "Validation en cours..." : "Valider"}
            </MDButton>
          </MDBox>
        )}

        {/* Statut de validation */}
        <MDTypography variant="body2" color="text">
          <strong>Status :</strong> {status}
        </MDTypography>

        {/* Date de la demande de validation */}
        <MDTypography variant="body2" color="text">
    <strong>Date de la demande de validation :</strong> {`${formattedDate} à ${formattedTime}`}
  </MDTypography>

        {/* Date ancienne validation si statut est "En cours de vérification" */}
        {status === "En cours de vérification" && (
          <MDTypography variant="body2" color="text">
           
          </MDTypography>
        )}

        {/* Message de validation */}
        {message_validation && (
          <MDBox display="flex" alignItems="center" justifyContent="center" textAlign="center">
            <MDTypography variant="body2" color="text">
              <strong>Message du prof :</strong>
            </MDTypography>
            <MDTypography variant="body2" color="text" sx={{ marginLeft: 1 }} dangerouslySetInnerHTML={{ __html: message_validation }} />
          </MDBox>
        )}

        {/* Lien de validation */}
        {lien_validation && (
          <MDTypography variant="body2" color="text">
            <strong>Lien de validation :</strong>{" "}
            <a href={lien_validation} target="_blank" rel="noopener noreferrer">
              {lien_validation}
            </a>
          </MDTypography>
        )}
      </MDBox>

      <Divider />

      {/* Bouton pour afficher les détails */}
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={1} pb={3} px={3}>
        <MDButton variant="text" color="info" onClick={onExpand}>
          {expanded ? "Réduire" : "Voir détails"}
        </MDButton>
      </MDBox>

      {/* Détails de l'activité si carte expandée */}
      {expanded && (
        <MDBox p={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="h6" fontWeight="bold">
                Détails de l'activité
              </MDTypography>
              <MDTypography variant="body2" color="text" dangerouslySetInnerHTML={{ __html: details }} />
            </Grid>

            <Grid item xs={12}>
              <MDTypography variant="h6" fontWeight="bold">
                Évaluation
              </MDTypography>
              <MDTypography variant="body2" color="text" dangerouslySetInnerHTML={{ __html: evaluation }} />
            </Grid>

            <Grid item xs={12}>
              <MDTypography variant="h6" fontWeight="bold">
                Livrables
              </MDTypography>
              <MDTypography variant="body2" color="text">
                {livrables}
              </MDTypography>
            </Grid>

            <Grid item xs={12}>
              <MDTypography variant="h6" fontWeight="bold">
                À faire (To-do)
              </MDTypography>
              <MDTypography variant="body2" color="text" dangerouslySetInnerHTML={{ __html: todo }} />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </Card>
  );
}

ActiviteValidationCardProf.propTypes = {
  nom_eleve: PropTypes.string,
  status: PropTypes.string,
  prenom_eleve: PropTypes.string,
  email: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
  details: PropTypes.string,
  evaluation: PropTypes.string,
  livrables: PropTypes.string,
  todo: PropTypes.string,
  competencesTab: PropTypes.arrayOf(
    PropTypes.shape({
      identifiant_competence: PropTypes.number,
      numero_competence: PropTypes.string,
      denomination_competence: PropTypes.string,
      niveau_vise_activite: PropTypes.number,
      points_remportes: PropTypes.number,
      points_competence_vises: PropTypes.number,
    })
  ),
  lien_validation: PropTypes.string,
  date_validation: PropTypes.string,
  message_validation: PropTypes.string,
  expanded: PropTypes.bool,
  onExpand: PropTypes.node,  // Correction ici
  identifiant_validation: PropTypes.number,
  encours_validation: PropTypes.array,
  anciennes_validations : PropTypes.array,
  competences_actuelles: PropTypes.array,
  identifiant_activite: PropTypes.number
};

export default ActiviteValidationCardProf;
